import { CUBEJS_API } from "../../config/Api";
import cubejs from "@cubejs-client/core";
import { toast } from "react-toastify";

function cubeFunction() {
  const cubeJsToken = window.localStorage.getItem("cmn_cubeJs_token");
  const cubejsApi = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsApi;
}
/* const startTime = Date.now()
   const result = await cubejsApi.load(qAttandanceData);
   const endTime = Date.now()
   const timeTaken = endTime - startTime
   console.log("Time taken for fetchAttendanceData", timeTaken) */

//Reference: RA-PERF-01 - Cube API to fetch Performance rows
export async function getPerformanceRowsAPI(filter) {
  console.log("filter", filter);
  var cubejsApi = cubeFunction();
  const q = {
    "order": {
      "AssessmentResponse.avgVelocity": "desc"
    },
    "filters": filter,
    "dimensions": [
      "AssessmentResponse.studyCircleId",
      "AssessmentResponse.institutionId",
      "AssessmentResponse.raPerfGroupKey",
      "Assessment.subjectId",
      "StudyCircle.name",
      "Subject.name",
      "Assessment.assessmentModeId",
      "Assessment.assessmentTypeId",
      "AssessmentType.name",
      "AssessmentOrganizer.name",
      "GroupPerformanceRank.studyCircleGroupRank",
      "Assessment.organizerTypeId",
      "AssessmentResponse.assessmentDate"
    ],
    "measures": [
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.avgScore",
      "AssessmentResponse.questionsCount"
    ]
  }
  try {
    // const result = await cubejsApi.load(q);
    // console.log("result for RA-PERF-01", result);
    const startTime = Date.now()
    const result = await cubejsApi.load(q);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getPerformanceRowsAPI", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-01-02 - Cube API to fetch Performance rows (read velocity values)
export async function getPerformanceRowsAPIRV(filter) {
  console.log("filter", filter);
  var cubejsApi = cubeFunction();
  const q = {
    measures: [
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.rowNumSessionTrend",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
        granularity: "month",
      },
    ],
    filters: filter,
    dimensions: ["AssessmentResponse.raPerfGroupKey"],
  };
  try {
    // const result = await cubejsApi.load(q);
    // console.log("result for RA-PERF-01-02", result);
    const startTime = Date.now()
    const result = await cubejsApi.load(q);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getPerformanceRowsAPIRV", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-02 - Cube API to fetch session headers
export async function sessionHeaderFetch(filter) {
  var cubejsApi = cubeFunction();
  const qSessionHeader = {
    dimensions: [
      // "GroupSession.title",
      "Topic.name",
      "Assessment.groupSessionId",
      "GroupSession.code",
      "AssessmentResponse.assessmentId",
    ],
    order: {
      "AssessmentResponse.assessmentId": "asc",
    },
    filters: filter,
  };
  try {
    // const result = await cubejsApi.load(qSessionHeader);
    // console.log("result RA-PERF-02 - Cube API to fetch session headers", result)
    const startTime = Date.now()
    const result = await cubejsApi.load(qSessionHeader);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for sessionHeaderFetch", timeTaken)
    return result?.loadResponses[0].data;
  } catch (error) {
    console.log("error", error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-03-01 - Cube API to fetch read velocity performance
export async function fetchPerformanceReadVelocity(studyCircleId, selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qreadVel = {
    order: {},
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyCircleId],
      },
    ],
  };

  try {
    const result = await cubejsApi.load(qreadVel);
    return result.loadResponses[0]?.data[0]['AssessmentResponse.avgVelocity'];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-03-02 - Cube API to fetch group average read velocity
export async function fetchPerformanceGrpReadVelocity(selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qGrpVel = {
    order: {},
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
    ],
  };
  try {
    const result = await cubejsApi.load(qGrpVel);
    return result?.loadResponses[0]?.data[0]['AssessmentResponse.avgVelocity'];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

// RA-PERF-03-03: Get study circle rank for group rank percentile
export async function fetchStudyCircleRankForGroup(studyCircleId) {
  const cubejsApi = cubeFunction();
  const qFetchSCRankForGroup = {
    dimensions: [
      "GroupPerformanceRank.studyCircleGroupRank",
      "AssessmentResponse.studyCircleId",
    ],
    filters: [
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [`${studyCircleId}`],
      },
    ],
  };
  try {
    // const result = await cubejsApi.load(qFetchSCRankForGroup);
    const startTime = Date.now()
    const result = await cubejsApi.load(qFetchSCRankForGroup);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for fetchStudyCircleRankForGroup", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

// RA-PERF-03-03-01: Get total Study Circle Count
export async function fetchTotalStudyCircleCount() {
  const cubejsApi = cubeFunction();
  const qFetchTotalStudyCircleCount = {
    measures: ["GroupPerformanceRank.count"],
  };
  try {
    // const result = await cubejsApi.load(qFetchTotalStudyCircleCount);
    const startTime = Date.now()
    const result = await cubejsApi.load(qFetchTotalStudyCircleCount);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for fetchTotalStudyCircleCount", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-04 - Cube API to fetch summary of performance details page
export async function fetchDifficultySummary(studyCircleId, selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qSummaryPerf = {
    measures: [
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNotAnswereds",
      "AssessmentResponse.count",
      "AssessmentResponse.questionsCount",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyCircleId],
      },
      {
        member: "AssessmentResponse.result",
        operator: "notEquals",
        values: ["absent"],
      },
    ],
    dimensions: ["Question.difficulty"],
    order: [["Question.difficulty", "asc"]],
  };
  try {
    // const result = await cubejsApi.load(qSummaryPerf);
    const startTime = Date.now()
    const result = await cubejsApi.load(qSummaryPerf);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for fetchDifficultySummary", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-05-01 - Cube API to fetch accuracy of performance details page
export async function fetchPerformanceAccuracy(studyCircleId, selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qAccuracy = {
    measures: ["AssessmentResponse.avgAccuracy"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyCircleId],
      },
    ],
    order: {},
  };
  try {
    const result = await cubejsApi.load(qAccuracy);
    return result?.loadResponses[0]?.data[0]["AssessmentResponse.avgAccuracy"];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-05-02 - Cube API to fetch group accuracy of performance details page
export async function fetchPerformanceGrpAccuracy(selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qGrpAccuracy = {
    measures: ["AssessmentResponse.avgAccuracy"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
    ],
    order: {},
  };

  try {
    const result = await cubejsApi.load(qGrpAccuracy);
    return result?.loadResponses[0]?.data[0]["AssessmentResponse.avgAccuracy"];;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-06-01 - Cube API to fetch score of performance details page
export async function fetchPerformanceScore(studyCircleId, selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qScore = {
    measures: ["AssessmentResponse.marksScored"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyCircleId],
      },
    ],
  };

  try {
    const result = await cubejsApi.load(qScore);
    console.log("result", result)
    return result?.loadResponses[0]?.data[0]["AssessmentResponse.marksScored"];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-06-01 - Cube API to fetch group score of performance details page
export async function fetchPerformanceGrpScore(selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qGrpScore = {
    measures: ["AssessmentResponse.avgMarksScored"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
    ],
  };

  try {
    const result = await cubejsApi.load(qGrpScore);
    return result?.loadResponses[0]?.data[0][
      "AssessmentResponse.avgMarksScored"
    ];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-06-03 - Cube API to fetch score grap of performance details page
export async function fetchPerformanceScoreGraph(studyCircleId, selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qScoreGraph = {
    measures: [
      "AssessmentResponse.questionsCount",
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNotAnswereds",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyCircleId],
      },
    ],
  };
  try {
    const result = await cubejsApi.load(qScoreGraph);
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-07-01 - Cube API to fetch speed of performance details page
export async function fetchPerformanceSpeed(studyCircleId, selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qSpeed = {
    measures: [
      "AssessmentResponse.avgTimeTaken",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyCircleId],
      },
      {
        member: "AssessmentResponse.result",
        operator: "notEquals",
        values: ["absent"],
      },
    ],
  };

  try {
    const result = await cubejsApi.load(qSpeed);
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-07-02 - Cube API to fetch group speed of performance details page
export async function fetchPerformanceGrpSpeed(selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qGrpSpeed = {
    measures: ["AssessmentResponse.avgTimeTaken"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupSessionId",
        operator: "equals",
        values: [selGrpId],
      },
    ],
  };

  try {
    const result = await cubejsApi.load(qGrpSpeed);
    return result?.loadResponses[0]?.data[0]["AssessmentResponse.avgTimeTaken"];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}
