import { CUBEJS_API } from "../../config/Api";
import cubejs from "@cubejs-client/core";
import { toast } from "react-toastify";

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem("cmn_cubeJs_token");
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(error) {
  console.log(error);
  if (error.response.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

//RA-ASSESS-08-02 - Studets page - table data
export async function fetchStudentData(filters) {
  // console.log("filters", filters);
  const cubejsApi = cubeFunction();
  const qStudentData = {
    order: {
      "StudyCircleStudent.rollNumber": "asc",
    },
    measures: [
      "AssessmentResponse.attendanceTotal",
      "AssessmentResponse.attendancePresent",
      "AssessmentResponse.avgScore",
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.studyCircleRankVelocity",
      // "AssessmentResponse.trend",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [...filters, { member: "Student.name", operator: "set" }],
    dimensions: [
      "AssessmentResponse.studentId",
      "StudyCircleStudent.rollNumber",
      "Student.name",
      "StudyCircle.name",
      "AssessmentResponse.studyCircleId",
      "Student.profilePicUrl",
      "GroupRank.studentGroupRank",
      "StudentHandRaise.handRaise",
      "AssessmentResponse.raStudentGroupKey",
    ],
  };
  try {
    // const result = await cubejsApi.load(qStudentData);
    let startTime = Date.now()
    const result = await cubejsApi.load(qStudentData);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Total Time taken for fetchStudentData", timeTaken)
    console.log("RA-ASSESS-08-02", result);
    return result;
  } catch (error) {
    console.log("error RA-ASSESS-08-02", error);
    handleError(error);
  }
}

//RA-ASSESS-08-02-01 - Studets page - table data (RV support query)
export async function fetchStudentDataRV(filters) {
  // console.log("filters", filters);
  const cubejsApi = cubeFunction();
  const qStudentData = {
    measures: [
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.rowNumStudentTrend",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
        granularity: "month",
      },
    ],
    dimensions: ["AssessmentResponse.raStudentGroupKey"],
    filters: [...filters, { member: "Student.name", operator: "set" }],
  };
  try {
    // const result = await cubejsApi.load(qStudentData);
    let startTime = Date.now()
    const result = await cubejsApi.load(qStudentData);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Total Time taken for fetchStudentDataRV", timeTaken)
    console.log("RA-ASSESS-08-02-01", result);
    return result;
  } catch (error) {
    console.log("error RA-ASSESS-08-02-01", error);
    handleError(error);
  }
}

//RA-ASSESS-09-01 - student details page - subjects read velocity
export async function fetchAllSubjectReadVelocity(filters, studyCircleId) {
  const cubejsApi = cubeFunction();
  const qAllSubjectReadVelocity = {
    order: {},
    filters: [
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyCircleId],
      },
      ...filters,
    ],
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
  };
  try {
    let startTime = Date.now()
    const result = await cubejsApi.load(qAllSubjectReadVelocity);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for RA_ASSESS_09-01 fetchAllSubjectReadVelocity", timeTaken)
    // console.log("RA-ASSESS-09-01", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-09-01", error);
    handleError(error);
  }
}

//RA-ASSESS-09-02 - student details page - each subject read velocity
export async function fetchEachSubjectReadVelocity(filters, studyCircleId) {
  // console.log("filters", filters)
  const cubejsApi = cubeFunction();
  const qEachSubjectReadVelocity = {
    filters: [
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyCircleId],
      },
      ...filters,
    ],
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    dimensions: ["Subject.name", "Assessment.subjectId"],
    order: [["Subject.name", "asc"]],
  };

  try {
    let startTime = Date.now()
    const result = await cubejsApi.load(qEachSubjectReadVelocity);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchEachSubjectReadVelocity", timeTaken)
    // console.log("RA-ASSESS-09-02", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-09-02", error);
    handleError(error);
  }
}

// RA-ASSESS-09-03: Individual subject graph plot
export async function fetchIndividualSubjectsGraph(
  studentId,
  studyCircleId,
  subjectId
) {
  const cubejsApi = cubeFunction();
  const qFetchAllSubjectsGraph = {
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    // order: {
    //   "AssessmentResponse.assessmentDate": "desc",
    // },
    filters: [
      {
        member: "AssessmentResponse.studentId",
        operator: "equals",
        values: [`${studentId}`],
      },
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [`${studyCircleId}`],
      },
      {
        member: "Assessment.subjectId",
        operator: "equals",
        values: [`${subjectId}`],
      },
    ],
    dimensions: ["AssessmentResponse.assessmentDate", "Assessment.subjectId"],
    limit: 4,
  };
  try {
    let startTime = Date.now()
    const result = await cubejsApi.load(qFetchAllSubjectsGraph);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchIndividualSubjectsGraph", timeTaken)
    // console.log("RA-ASSESS-09-03", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-09-03", error);
    handleError(error);
  }
}

// RA-ASSESS-09-04: All subject graph plot
export async function fetchAllSubjectsGraph(filters, studyCircleId) {
  // console.log("filters inside RA-ASSESS-09-04", filters)
  const cubejsApi = cubeFunction();
  const qFetchAllSubjectsGraph = {
    filters: [
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyCircleId],
      },
      ...filters,
    ],
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: {
      "AssessmentResponse.assessmentDate": "desc",
    },
    dimensions: ["AssessmentResponse.assessmentDate"],
    limit: 4,
  };
  try {
    let startTime = Date.now()
    const result = await cubejsApi.load(qFetchAllSubjectsGraph);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchAllSubjectsGraph", timeTaken)
    // console.log("RA-ASSESS-09-04", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-09-04", error);
    handleError(error);
  }
}

//RA-ASSESS-10-01 - Student details - Read velocity of the student
export async function fetchReadVelocityStu(filters) {
  const cubejsApi = cubeFunction();
  const qReadVelocityStu = {
    filters: filters,
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
        // granularity: "day",
      },
    ],
    order: {
      "AssessmentResponse.assessmentDate": "asc",
    },
  };
  try {
    const result = await cubejsApi.load(qReadVelocityStu);
    // console.log("RA-ASSESS-10-01", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-10-01", error);
    handleError(error);
  }
}

//RA-ASSESS-10-02 Group avrage for student ID
export async function fetchStudyAvg(filters, studyCircleId) {
  // console.log("filters, studeCircleId", filters, studyCircleId)
  const cubejsApi = cubeFunction();
  const studyId = String(studyCircleId);
  // console.log(studyId);
  const qStudyAvg = {
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    // order: {
    //   "AssessmentResponse.avgVelocity": "desc",
    // },
    // dimensions: ["StudyCircle.id"],
    filters: [
      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyId],
      },
      ...filters,
    ],
  };
  try {
    let startTime = Date.now()
    const result = await cubejsApi.load(qStudyAvg);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchStudyAvg", timeTaken)
    // console.log("RA-ASSESS-10-02", result);
    return result?.loadResponses[0]?.data[0];
  } catch (error) {
    console.log("RA-ASSESS-10-02", error);
    handleError(error);
  }
}

//RA-ASSESS-10-03 Group avrage for institution id
export async function fetchGrpVelocity(filters, groupInstitutionId) {
  const cubejsApi = cubeFunction();
  const grpString = groupInstitutionId.toString();
  console.log(grpString);
  const qGrpVelocity = {
    measures: ["AssessmentResponse.avgVelocity"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupInstitutionId",
        operator: "equals",
        values: [grpString],
      },
      ...filters,
    ],
  };

  try {
    const result = await cubejsApi.load(qGrpVelocity);
    // console.log("RA-ASSESS-10-03", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-10-03", error);
    handleError(error);
  }
}

// RA-ASSESS-10-04: Get student's study circle rank
export async function fetchStudentStudyCircleRank(filters) {
  const cubeJsApi = cubeFunction();
  const qStudentStudyCircleRank = {
    dimensions: ["StudyCircleRank.studyCircleRank"],
    order: {
      "StudyCircleRank.studyCircleRank": "asc",
    },
    filters: filters,
  };
  try {
    let startTime = Date.now()
    const result = await cubeJsApi.load(qStudentStudyCircleRank);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchStudentStudyCircleRank", timeTaken)
    // console.log("RA-ASSESS-10-04", result);
    return result?.loadResponses?.[0]?.data?.[0][
      "StudyCircleRank.studyCircleRank"
    ];
  } catch (error) {
    console.log("RA-ASSESS-10-04", error);
    handleError(error);
  }
}

//RA-ASSESS-10-04-01: Get total number of students
export async function fetchTotalStudentsInStudyCircle(filters) {
  const cubeJsApi = cubeFunction();
  const qFetchTotalStudentsInStudyCircle = {
    measures: ["StudyCircleRank.count"],
    filters: filters,
  };
  try {
    // const result = await cubeJsApi.load(qFetchTotalStudentsInStudyCircle);
    // console.log("RA-ASSESS-10-04-01", result);
    let startTime = Date.now()
    const result = await cubeJsApi.load(qFetchTotalStudentsInStudyCircle);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchTotalStudentsInStudyCircle", timeTaken)
    return result?.loadResponses?.[0]?.data?.[0]["StudyCircleRank.count"];
  } catch (error) {
    console.log("RA-ASSESS-10-04-01", error);
    handleError(error);
  }
}

// RA-ASSESS-10-05: Get Student Group Rank
export async function fetchStudentGroupRank(filters) {
  const cubeJsApi = cubeFunction();
  const qStudentGroupRank = {
    dimensions: ["GroupRank.studentGroupRank"],
    order: {
      "GroupRank.studentGroupRank": "asc",
    },
    filters: filters,
  };
  try {
    // const result = await cubeJsApi.load(qStudentGroupRank);
    // console.log("RA-ASSESS-10-05", result);
    let startTime = Date.now()
    const result = await cubeJsApi.load(qStudentGroupRank);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchStudentGroupRank", timeTaken)
    return result?.loadResponses?.[0]?.data?.[0]["GroupRank.studentGroupRank"];
  } catch (error) {
    console.log("RA-ASSESS-10-05", error);
    handleError(error);
  }
}

// RA-ASSESS-10-05-01: Get total number of students
export async function fetchTotalStudentsInGroup(filters) {
  const cubeJsApi = cubeFunction();
  const qTotalStudentsInGroup = {
    measures: ["GroupRank.count"],
    timeDimensions: [],
    order: {},
  };
  try {
    let startTime = Date.now()
    const result = await cubeJsApi.load(qTotalStudentsInGroup);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchTotalStudentsInGroup", timeTaken)
    // console.log("RA-ASSESS-10-05-01", result);
    return result?.loadResponses?.[0]?.data?.[0]["GroupRank.count"];
  } catch (error) {
    console.log("RA-ASSESS-10-05-01", error);
    handleError(error);
  }
}

//RA-ASSESS-11-01 Difficulty Analytics Summary
export async function fetchDifficultySum(filters) {
  const cubeJsApi = cubeFunction();
  const qDifficultySum = {
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: {
      "AssessmentResponse.totalCorrects": "desc",
    },
    dimensions: ["Question.difficulty"],
    filters: [
      ...filters,
      {
        member: "AssessmentResponse.result",
        operator: "notEquals",
        values: ["absent"],
      },
    ],
    measures: [
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNotAnswereds",
      "AssessmentResponse.count",
    ],
  };
  try {
    let startTime = Date.now()
    const result = await cubeJsApi.load(qDifficultySum);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchDifficultySum", timeTaken)
    // const result = await cubeJsApi.load(qDifficultySum);
    // console.log("RA-ASSESS-11-01", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-11-01", error);
    handleError(error);
  }
}

//RA-ASSESS-11-02  student details Score
export async function fetchScore(filters) {
  const cubeJsApi = cubeFunction();
  const qScore = {
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: {},
    filters: filters,
    measures: ["AssessmentResponse.avgScore"],
  };

  try {
    const result = await cubeJsApi.load(qScore);
    // console.log("RA-ASSESS-11-02", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-11-02", error);
    handleError(error);
  }
}

//RA-ASSESS-11-02-02  student details group Score
export async function fetchGrpScore(filters, groupInstitutionId) {
  const cubeJsApi = cubeFunction();
  const grpScoreStr = groupInstitutionId.toString();
  const qGrpScore = {
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: {},
    filters: [
      {
        member: "Assessment.groupInstitutionId",
        operator: "equals",
        values: [grpScoreStr],
      },
      ...filters,
    ],
    measures: ["AssessmentResponse.avgScore"],
  };

  try {
    const result = await cubeJsApi.load(qGrpScore);
    // console.log("RA-ASSESS-11-02-02", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-11-02-02", error);
    handleError(error);
  }
}

//RA-ASSESS-11-03  student details Score graph
export async function fetchScoreGraph(filters) {
  const cubeJsApi = cubeFunction();
  const qScoregraph = {
    measures: [
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNotAnswereds",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: {},
    filters: filters,
  };
  try {
    // const result = await cubeJsApi.load(qScoregraph);
    // console.log("RA-ASSESS-11-03", result);
    let startTime = Date.now()
    const result = await cubeJsApi.load(qScoregraph);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchScoreGraph", timeTaken)
    return result;
  } catch (error) {
    console.log("RA-ASSESS-11-03", error);
    handleError(error);
  }
}

//RA-ASSESS-11-04  student details Accuracy
export async function fetchAccuracy(filters) {
  const cubeJsApi = cubeFunction();
  const qAccuracy = {
    measures: ["AssessmentResponse.avgAccuracy"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: filters,
  };

  try {
    const result = await cubeJsApi.load(qAccuracy);
    // console.log("RA-ASSESS-11-04", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-11-04", error);
    handleError(error);
  }
}

//RA-ASSESS-11-04-02  student details Accuracy
export async function fetchGrpAccuracy(filters, groupInstitutionId) {
  const cubeJsApi = cubeFunction();
  const grpAccStr = groupInstitutionId.toString();
  const qGrpAccuracy = {
    measures: ["AssessmentResponse.avgAccuracy"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      {
        member: "Assessment.groupInstitutionId",
        operator: "equals",
        values: [grpAccStr],
      },
      ...filters,
    ],
  };

  try {
    const result = await cubeJsApi.load(qGrpAccuracy);
    // console.log("RA-ASSESS-11-04-02", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-11-04-02", error);
    handleError(error);
  }
}

//RA-ASSESS-11-05  student details Time
export async function fetchTime(filters) {
  const cubeJsApi = cubeFunction();
  const qTime = {
    measures: ["AssessmentResponse.avgSpeed"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      ...filters,
      {
        member: "AssessmentResponse.result",
        operator: "notEquals",
        values: ["absent"],
      },
    ],
  };

  try {
    const result = await cubeJsApi.load(qTime);
    // console.log("RA-ASSESS-11-05", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-11-05", error);
    handleError(error);
  }
}

//RA-ASSESS-11-05-02  student details Group Time
export async function fetchGrpTime(filters, groupInstitutionId) {
  console.log("groupInstitutionId filters", groupInstitutionId, filters)
  const cubeJsApi = cubeFunction();
  const grpTimeStr = groupInstitutionId.toString();
  const qGrpTime = {
    measures: ["AssessmentResponse.avgSpeed"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: [
      ...filters,
      {
        member: "AssessmentResponse.result",
        operator: "notEquals",
        values: ["absent"],
      },
      {
        member: "Assessment.groupInstitutionId",
        operator: "equals",
        values: [grpTimeStr],
      },
    ],
  };

  try {
    // const result = await cubeJsApi.load(qGrpTime);
    // console.log("RA-ASSESS-11-05-02", result);
    let startTime = Date.now()
    const result = await cubeJsApi.load(qGrpTime);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchGrpTime", timeTaken)
    return result;
  } catch (error) {
    console.log("RA-ASSESS-11-05-02", error);
    handleError(error);
  }
}

//RA-ASSESS-12 student details attendance
export async function fetchAttendance(filters) {
  const cubeJsApi = cubeFunction();
  console.log(filters);
  const qAttendance = {
    measures: [
      "AssessmentResponse.attendancePresent",
      "AssessmentResponse.attendanceTotal",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    filters: filters,
  };

  try {
    const result = await cubeJsApi.load(qAttendance);
    // console.log("RA-ASSESS-12", result);
    return result;
  } catch (error) {
    console.log("RA-ASSESS-12", error);
    handleError(error);
  }
}
