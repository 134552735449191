import React from "react"
import _ from "lodash";
import Carousel, { consts } from "react-elastic-carousel";
import darkDisArrow from "../../assets/images/dark-prev-arrow.png";
import darkRightArrow from "../../assets/images/dark-next-arrow.png";

const PerformanceDetailsCarousel = (props) => {
    const { styles, sessionData, count, setSelectedGroupId, setCount, studyCircleId } = props
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 150, itemsToShow: 3 },
        { width: 250, itemsToShow: 4 },
        { width: 350, itemsToShow: 5 },
    ];

    const activeSerial = () => {
        return _.map(sessionData, (elm, index) => {
            return (
                <div
                    key={index}
                    // className={index === count ? styles.boxes_active : styles.boxes}
                    className={styles.boxes}
                    onClick={(e) =>
                        handleRollNoChange(
                            index,
                            elm["Assessment.groupSessionId"],
                            elm["AssessmentResponse.assessmentId"],
                            elm["GroupSession.code"]
                        )
                    }
                >
                    <div>{elm["GroupSession.code"]}</div>
                </div>
            );
        });
    };

    const handleRollNoChange = (
        activeIndex,
        grpId,
        assessmentId,
        sessionCode
    ) => {
        if (activeIndex !== count) {
            setCount(activeIndex);
            setSelectedGroupId(grpId);
        }
        localStorage.setItem("groupSessionCode", sessionCode);
        window.localStorage.setItem("studyCircleId", studyCircleId);
        return (window.location.href = `/admin/assessments/${assessmentId}`);
    };

    const handleViewSessionClick = () => {
        return (window.location.href = "/admin/assessments");
    };
    return (
        <div className={styles.readVelocity_right_div1}>
            <div
                className={styles.right_div1}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <Carousel
                    breakPoints={breakPoints}
                    pagination={false}
                    renderArrow={({ type, onClick }) => {
                        const pointer =
                            type === consts.PREV ? (
                                <img
                                    src={darkDisArrow}
                                    alt="dark disable"
                                    height="25px"
                                    width="25px"
                                    style={{ marginLeft: "30px" }}
                                />
                            ) : (
                                <img
                                    src={darkRightArrow}
                                    alt="rightArrow"
                                    height="25px"
                                    width="25px"
                                />
                            );
                        return <span onClick={onClick}>{pointer}</span>;
                    }}
                >
                    {activeSerial()}
                </Carousel>
                {sessionData.length > 0 && (
                    <div
                        className={styles.session_performance_link}
                        onClick={handleViewSessionClick}
                    >
                        {"View Session Performance"}
                    </div>
                )}
            </div>
        </div>
    )
}

export default PerformanceDetailsCarousel