import React, { useState, forwardRef, useEffect, useRef } from "react";
import Modal from "react-modal";
import "./ReportPopup.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import calanderIcon from "../../../assets/images/calender-icon.png";
import ReportDropdown from "../ReportDropdown/ReportDropdown";
import { fetchDropdownData } from "../../../api/masterReadAnalytics/reportList";
import { toast } from "react-toastify";
import LoadingBars from "../../../components/LoadingBars/LoadingBars";

const customStyles = {
  content: {
    position: "relative",
    top: "22%",
    left: "52%",
    display: "inline-block",
    width: "340px",
    height: "auto",
    maxHeight: "420px",
    background: "#FFFFFF",
    borderRadius: "16px",
    transform: "translate(-50%, -5%)",
    overflow: "none",
    padding: "5px",
    WebkitOverflowScrolling: "touch",
  },
  overlay: {
    zIndex: "2",
    background: "rgba(128, 128, 128, 0.5)"
  },
};
Modal.setAppElement("#root");

const ReportPopup = (props) => {
  const {
    handleModalOpenClose,
    openModal,
    modal_format,
    // stylesForSpinners,
    createReport,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [variableValues, setVariableValues] = useState({});
  const newValuesAdded = useRef(null);

  useEffect(() => {
    let vars = variableValues;
    modal_format.reduce(async (result, obj) => {
      if (obj.type === "date") {
        vars[obj.variable] = new Date();
        vars[`${obj.variable}_df`] = moment(vars[obj.variable]).format(
          "DD/MM/yyyy"
        );
      } else if (obj.type === "get") {
        vars[obj.variable] = [];
      }
    }, {});
    setVariableValues({ ...variableValues, ...vars });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, [changed]);

  // Generate request and fetch actual dropdown data
  const getDropdownData = async (obj) => {
    let dropDownList = [];
    setLoading(true);
    let request = obj?.request;
    let paramsElements = obj?.params?.map((ele) => {
      let variable = Object.keys(ele)[0]; //session_date
      let value = ele[variable]; //1
      let source = variable; // session_date
      if (value !== 1) source = value;
      let type = modal_format.reduce((a, inv) => {
        if (inv.variable === variable) return inv.type;
        return a;
      }, 0);
      if (type === "date")
        return `${variable}=${moment(variableValues[source]).format(
          "yyyy-MM-DD"
        )}`;
      return `${variable}=${variableValues[source]}`;
    });
    let paramsString =
      paramsElements?.length > 0 ? paramsElements.join("&") : "";
    let actualRequest = `${request}?${paramsString}`;
    console.log("actualRequest", actualRequest);
    setLoading(false);

    try {
      setLoading(true);
      dropDownList = await fetchDropdownData(actualRequest);
      // console.log("dropDownList", dropDownList)
      setLoading(false);
    } catch (err) {
      console.log("err inside getData", err);
    }
    return dropDownList;
  };

  // Data initialization with variableValues
  const getData = async () => {
    let vars = variableValues;
    await modal_format.reduce(async (result, obj) => {
      if (obj.type === "get") {
        const values = await getDropdownData(obj);
        vars[obj.variable] = values;
      }
    }, {});
    setVariableValues({ ...variableValues, ...vars });
  };

  const CalenderInput = forwardRef(({ value, onClick }, ref) => (
    <div className="calender">
      <img src={calanderIcon} alt="calender-icon" />
      <button className="calender-input" onClick={onClick} ref={ref}>
        {value}
      </button>
    </div>
  ));

  const modalOpenClose = async () => {
    setLoading(true);
    setModalIsOpen(!modalIsOpen);
    handleModalOpenClose();
    setLoading(false);
  };

  const handleCancel = () => {
    modalOpenClose();
  };

  // console.log("variableValues outside", variableValues)
  // console.log("modal_format", modal_format)

  // Form validation for report popup
  const allVariablesExist = (variableValues) => {
    const validateInputs = modal_format?.reduce((booleanVal, compareVal) => {
      if (booleanVal === false) {
        return false;
      }
      if (compareVal.type === "date") {
        if (variableValues[compareVal.variable] === undefined) {
          return false;
        }
      }
      if (compareVal.type === "get") {
        let outPutVariables = compareVal?.output_variables;
        const compareKeys = outPutVariables.map((obj) => {
          return Object.keys(obj)[0];
        });
        console.log("compareKeys", compareKeys); //['session_id', 'session_code', 'session_title', 'subject_name']

        for (let i = 0; i <= compareKeys.length - 1; i++) {
          const key = compareKeys[i];
          if (variableValues[key] === undefined) {
            return false;
          }
        }
      }
      return true;
    }, true);
    return validateInputs;
  };

  // Onclick of generate report button
  const handleGenerateReport = async () => {
    if (!allVariablesExist(variableValues)) {
      toast.error("Select all the values to generate report");
      return;
    }
    modalOpenClose();
    setLoading(true);
    await createReport(variableValues);
    setLoading(false);
  };

  const handleDateChange = (date, i) => {
    setLoading(true);
    let varName = modal_format[i]?.variable;
    setVariableValues({
      ...variableValues,
      [varName]: date,
      [`${varName}_df`]: moment(date).format("DD/MM/yyyy"),
    });
    setChanged(!changed);
    setLoading(false);
  };

  // dropdown onChange handler
  const handleDropdownChange = (e, name, ele) => {
    let nameOfSelectedOpt = document.getElementById(name);
    let element = nameOfSelectedOpt.selectedIndex;
    let obj = variableValues[name][element - 1];

    if (element === 0) {
      let removableProperties = Object.keys(newValuesAdded.current);

      removableProperties.forEach((remVal) => {
        for (const keys in variableValues) {
          if (keys === remVal) {
            delete variableValues[remVal];
          }
        }
      });
      setVariableValues({ ...variableValues });
      return;
    }

    let newValues = ele.output_variables.reduce((acc, element) => {
      let key = Object.keys(element)[0];
      let value = element[key];
      if (value === 1) {
        value = key;
      }
      acc = { ...acc, [key]: obj[value] };
      return acc;
    }, {});

    newValuesAdded.current = newValues;
    setVariableValues({ ...variableValues, ...newValues });
    setChanged(!changed);
  };

  return (
    <>
      {loading ? (
        <LoadingBars />
      ) : null}
      <Modal
        isOpen={openModal}
        onRequestClose={modalOpenClose}
        style={customStyles}
        contentLabel="Report Popup"
      >
        <div className="report-modal">
          <div className="report-modal-heading">
            <div className="report-modal__header">Select Report Details</div>
            <hr style={{ width: "100%" }}></hr>
          </div>
          <div className="report-modal__elements">
            {modal_format?.map((ele, index) => {
              if (ele.type === "date") {
                return (
                  <div className="modal-element-holder" key={index}>
                    <div className="modal-element-holder__name">{ele.name}</div>
                    <DatePicker
                      showIcon={true}
                      closeOnScroll={true}
                      dateFormat="dd/MM/yyyy"
                      selected={variableValues[ele.variable]}
                      onChange={(date) => handleDateChange(date, index)}
                      showYearDropdown
                      scrollableMonthYearDropdown
                      customInput={<CalenderInput />}
                      wrapperClassName="datePicker"
                    />
                  </div>
                );
              } else if (ele.type === "get") {
                return (
                  <div className="modal-element-holder" key={index}>
                    <div className="modal-element-holder__name">{ele.name}</div>
                    <ReportDropdown
                      name={ele.variable}
                      display={ele.display}
                      ele={ele}
                      dropdownData={variableValues[ele.variable]}
                      handleDropdownChange={handleDropdownChange}
                    />
                  </div>
                );
              } else return null;
            })}
          </div>
          <div className="report-modal__footer">
            <hr style={{ width: "99%" }}></hr>
            <div className="report-modal__btns">
              <button
                className="report-modal__btns--cancel"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="report-modal__btns--save"
                onClick={handleGenerateReport}
              >
                Generate Report
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReportPopup;
