/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from "react";
import { GridContainer } from "../../components/grid/index";
// import PerformanceCalander from "./Calander/PerformanceCalander";
import PerformanceTable from "./Performance_Table/PerformanceTable";
// import PerformanceSubjectDetail from './PerformanceSubjectDetails/PerformanceSubjectDetail'
import "./index.css";
import styles from "./PerformanceStyle.module.css";
import Datepicker from "react-datepicker";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import {
  getModes,
  getOrganiser,
  getStudyCircle,
  getSubjectStudy,
  getAssessmentType,
} from "../../api/masterReadAnalytics/dropdownAPI";
import {
  getPerformanceRowsAPI,
  getPerformanceRowsAPIRV,
  fetchStudyCircleRankForGroup,
  fetchTotalStudyCircleCount,
} from "../../api/cube/performanceAPI";

import { filterBuildPerformance } from "./filterBuildPerformance";
import { whichTimeFrame } from "../../components/whichTImeFrame/whichTimeFrame";
import promiseAllCall from "../../utils/promise";
import PerformanceTableDropdown from "../../components/PerformanceTableDropdown/PerformanceTableDropdown";

var prevDate;
var nextDate;
var totaldaysInMonth;

const Performance = (props) => {
  const calendarDate =
    localStorage.getItem("oncalendardate") === null
      ? new Date()
      : new Date(localStorage.getItem("oncalendardate"));
  const [states, setStates] = useState({
    isMonth: true,
    isDay: false,
    isYear: false,
    t_body: [],
    modeOptions: [],
    organiserOptions: [],
    typeOptions: [],
    teacherOptions: [],
    studyCircleOptions: [],
    subjectOptions: [],
  });
  const [selectedValues, setSelectedValues] = useState({
    modeId: -1,
    organiserId: -1,
    typeId: -1,
    studyCircleId: -1,
    teacherId: -1,
    subjectId: -1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(0);
  const [startdate, setStartDate] = useState(calendarDate);
  let month = calendarDate.getMonth();
  let today = calendarDate.getDate();
  let year = calendarDate.getFullYear();
  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  totaldaysInMonth = getDaysInMonth(month + 1, year);
  let timeFrame = window.localStorage.getItem("timeFrame");

  const institutionId = window.localStorage.getItem("cmn_school_id");
  const userId = window.localStorage.getItem("cmn_user_id");
  const stylesss = {
    monthAct: {
      backgroundColor: "#FD8500",
      color: "#fff",
      fontWeight: 500,
    },
    dayAct: {
      backgroundColor: "#FD8500",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      color: "#fff",
      fontWeight: 500,
    },
    yearAct: {
      backgroundColor: "#FD8500",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
      color: "#fff",
      fontWeight: 500,
    },
  };
  let filter1, filter2;
  console.log("isLoading", isLoading);
  useEffect(() => {
    const fetchDropdowndata = async () => {
      try {
        const callAll = await promiseAllCall([
          getModes(),
          getOrganiser(),
          getAssessmentType(),
          getStudyCircle(institutionId),
        ]);
        console.log("callAll", callAll);
        setStates((prevState) => {
          return {
            ...prevState,
            modeOptions: callAll[0]?.data?.data,
            organiserOptions: callAll[1]?.data?.data,
            typeOptions: callAll[2]?.data?.data,
            studyCircleOptions: callAll[3]?.data?.data,
          };
        });
        // setIsLoading(false);
      } catch (err) {
        console.log("err in fetchDropdowndata", err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
        // setIsLoading(false);
      }
    };
    // setIsLoading(!isLoading);
    fetchDropdowndata();
  }, []);

  useEffect(() => {
    const fetchDropdownDependencies = async () => {
      try {
        const subjects = await getSubjectStudy(
          institutionId,
          selectedValues.studyCircleId
        );
        setStates((prevState) => {
          return { ...prevState, subjectOptions: subjects?.data?.data };
        });
        // setIsLoading(false);
      } catch (err) {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
        // setIsLoading(false);
      }
    };
    // setIsLoading(!isLoading);
    fetchDropdownDependencies();
  }, [selectedValues.studyCircleId]);

  useEffect(() => {
    const fetchCubeData = async () => {
      filter1 = filterBuildPerformance(
        institutionId,
        selectedValues.modeId,
        selectedValues.typeId,
        selectedValues.organiserId,
        selectedValues.studyCircleId,
        // selectedTeacher,
        // selectedSubject,
        prevDate,
        nextDate
      );
      filter2 = filterBuildPerformance(
        institutionId,
        selectedValues.modeId,
        selectedValues.typeId,
        selectedValues.organiserId,
        selectedValues.studyCircleId,
        // selectedTeacher,
        // selectedSubject,
        null,
        null
      );
      try {
        const callAll = await promiseAllCall([
          fetchTotalStudyCircleCount(),
          getPerformanceRowsAPI(filter1),
          getPerformanceRowsAPIRV(filter2),
        ]);
        // const studyCircleCount = await fetchTotalStudyCircleCount();
        const studyCircleCount = await callAll[0];
        const numStudyCircles =
          studyCircleCount?.loadResponses?.[0]?.data?.[0][
            "GroupPerformanceRank.count"
          ];
        // let result = await getPerformanceRowsAPI(filter1);
        let result = await callAll[1];
        result = [...result.loadResponses[0].data];
        console.log("result i performance", result);
        // let resultRV = await getPerformanceRowsAPIRV(filter2);
        let resultRV = await callAll[2];
        resultRV = [...resultRV.loadResponses[0].data];
        console.log("result i performance rv", resultRV);
        result = await calculateTrend(result, resultRV);
        console.log("result i performance (with row calc)", result);

        if (result) {
          console.log("result.loadResponses[0].data", result);
          const updatedData = result.map((d) => {
            let rank = d["GroupPerformanceRank.studyCircleGroupRank"] || 0;
            const partial = (rank * 1.0) / numStudyCircles;
            let percentile = +((1 - partial) * 100).toFixed(2);
            d.percentile = percentile;
            return d;
          });
          setStates((prevState) => {
            return { ...prevState, t_body: [...updatedData] };
          });
        }
        setIsLoading(false);
      } catch (err) {
        console.log("err in fetchCubeData", err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchCubeData();
  }, [
    date,
    selectedValues.modeId,
    selectedValues.organiserId,
    selectedValues.studyCircleId,
    selectedValues.subjectId,
    selectedValues.typeId,
  ]);

  if (timeFrame) {
    if (timeFrame === "month") {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
    }
    if (timeFrame === "day") {
      prevDate = year + "-" + (month + 1) + "-" + today;
      nextDate = year + "-" + (month + 1) + "-" + today;
    }
    if (timeFrame === "year") {
      prevDate = year + "-" + 1 + "-" + 1;
      nextDate = year + "-" + 12 + "-" + 31;
    }
  } else {
    prevDate = `${year}-${month + 1}-01`;
    nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
  }

  function dateData() {
    if (timeFrame) {
      setStates((prevState) => {
        return {
          ...prevState,
          isMonth: timeFrame === "month" ? true : false,
          isDay: timeFrame === "day" ? true : false,
          isYear: timeFrame === "year" ? true : false,
        };
      });
    }
  }

  const handleChange = async (date, arrowdate) => {
    setIsLoading(true);
    if (arrowdate === "prev") {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() - 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth
        ? currentdate.getMonth() === 0
          ? 11
          : currentdate.getMonth() - 1
        : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() - 1
        : currentdate.getMonth() === 0
        ? currentdate.getFullYear() - 1
        : previousday.getFullYear();
      setStartDate(new Date(year, month, today));
      whichTimeFrame(year, month, today, states);

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? `${year}-${month + 1}-01`
        : states.isYear
        ? `${year}-01-01`
        : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? year + "-" + (month + 1) + "-" + totaldaysInMonth
        : states.isYear
        ? `${year}-12-31`
        : null;
    } else if (arrowdate === "next") {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() + 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth
        ? currentdate.getMonth() === 11
          ? 0
          : currentdate.getMonth() + 1
        : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() + 1
        : currentdate.getMonth() === 11
        ? currentdate.getFullYear() + 1
        : previousday.getFullYear();
      setStartDate(new Date(year, month, today));
      whichTimeFrame(year, month, today, states);

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? `${year}-${month + 1}-01`
        : states.isYear
        ? `${year}-01-01`
        : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? year + "-" + (month + 1) + "-" + totaldaysInMonth
        : states.isYear
        ? `${year}-12-31`
        : null;
    } else if (arrowdate === "calander") {
      setStartDate(date);
      let date_val = new Date(date);
      localStorage.setItem("oncalendardate", date_val);
      month = date_val.getMonth();
      today = date_val.getDate();
      year = date_val.getFullYear();

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? `${year}-${month + 1}-01`
        : states.isYear
        ? `${year}-01-01`
        : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? year + "-" + (month + 1) + "-" + totaldaysInMonth
        : states.isYear
        ? `${year}-12-31`
        : null;
    }
    console.log(96);
    setDate(date + 1);
    // changeMode("e", "date");
  };

  const handleClick = async (values) => {
    setIsLoading(true);
    if (values === "d") {
      prevDate = `${year}-${month + 1}-${today}`;
      nextDate = year + "-" + (month + 1) + "-" + today;
    } else if (values === "m") {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
    } else if (values === "y") {
      prevDate = `${year}-01-01`;
      nextDate = `${year}-12-31`;
    }
    setStates({
      ...states,
      isDay: values === "d" ? true : false,
      isMonth: values === "m" ? true : false,
      isYear: values === "y" ? true : false,
    });
    whichTimeFrame(year, month, today, {
      isDay: values === "d" ? true : false,
      isMonth: values === "m" ? true : false,
      isYear: values === "y" ? true : false,
    });
    setDate(date + 1);
    // changeMode("e", "date");
  };

  const calandarSection = () => {
    return (
      <div className="calanderContainer">
        <div className="calander">
          <div className="calCont">
            <div className="dateDate">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={require("../../assets/images/prev-arrow.png")}
                  alt="prev"
                  onClick={(e) => handleChange(startdate, "prev")}
                  style={{ marginLeft: "40px" }}
                />
                <Datepicker
                  id={"fromdate"}
                  selected={startdate}
                  onChange={(e) => handleChange(e, "calander")}
                  dateFormat={
                    states.isDay
                      ? "d MMM yyyy"
                      : states.isMonth
                      ? "MMM yyyy"
                      : states.isYear
                      ? "yyyy"
                      : null
                  }
                  className={styles.input}
                  readOnly={false}
                  showMonthYearPicker={states.isMonth ? true : null}
                  showYearDropdown={states.isYear ? true : null}
                  // show={()=>test()}
                />
                <img
                  src={require("../../assets/images/next-arrow.png")}
                  alt="next"
                  onClick={(e) => handleChange(startdate, "next")}
                />
                <label htmlFor="fromdate">
                  <img
                    src={require("../../assets/images/calender-icon.png")}
                    alt="cal"
                    // onChange={(e) => handleChange(e, "calander")}
                    style={{ margin: "0px 30px", cursor: "pointer" }}
                  />
                </label>
              </div>
            </div>
            <div className="d_w_m_y">
              <div
                className="ddd"
                style={states.isDay ? stylesss.dayAct : null}
                onClick={() => handleClick("d")}
              >
                <p>D</p>
              </div>
              <div
                className="mmm"
                style={states.isMonth ? stylesss.monthAct : null}
                onClick={() => handleClick("m")}
              >
                <p>M</p>
              </div>
              <div
                className="yyy"
                style={states.isYear ? stylesss.yearAct : null}
                onClick={() => handleClick("y")}
              >
                <p>Y</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function calculateTrend(rows, rowsRV) {
    // from getPerformanceRowsAPI
    // console.log("calculateTrend inputs", rows, rowsRV);
    if (!rowsRV || !rowsRV.length) {
      return rows.map((v) => {
        v.trendData = [];
        v.trend = 0;
        v.one = v.two = v.three = null;
        return v;
      });
    }
    let result = rows.map((v) => {
      let key = v["AssessmentResponse.raPerfGroupKey"];
      let rvs = rowsRV.filter(
        (d) => d["AssessmentResponse.raPerfGroupKey"] === key
      );
      v.trendData = rvs;

      let one = v.trendData.filter(
        (d) => d["AssessmentResponse.rowNumSessionTrend"] == 1
      )[0]?.["AssessmentResponse.avgVelocity"];
      let two = v.trendData.filter(
        (d) => d["AssessmentResponse.rowNumSessionTrend"] == 2
      )[0]?.["AssessmentResponse.avgVelocity"];
      let three = v.trendData.filter(
        (d) => d["AssessmentResponse.rowNumSessionTrend"] == 3
      )[0]?.["AssessmentResponse.avgVelocity"];

      let sum = 0,
        count = 0;
      v.trend = 0;
      if (two) {
        count++;
        sum += two;
      }
      if (three) {
        count++;
        sum += three;
      }
      if (count > 0) {
        let prev = sum / count;
        if (one > prev + 0.05) v.trend = 1;
        else if (one < prev - 0.05) v.trend = -1;
        else v.trend = 0;
      }
      v.one = one;
      v.two = two;
      v.three = three;
      return v;
    });
    console.log("calculateTrend", result);
    return result;
  }

  const tableClick = (selectedRow) => {
    console.log("selectedRow", selectedRow);
    window.localStorage.setItem(
      "performanceData",
      JSON.stringify({
        studyCircleId: selectedRow["StudyCircle.id"],
        studyCircleName: selectedRow["StudyCircle.name"],
        // assessmentModeId: selectedMode,
        assessmentModeId: selectedRow["AssessmentMode.id"],
        // assessmentTypeId: selectedValues.typeId,
        assessmentTypeId: selectedRow["AssessmentType.id"],
        // subjectId: selectedSubject,
        subjectId: selectedRow["Subject.id"],
        prevDate: prevDate,
        nextDate: nextDate,
        calendarSel: states.isDay
          ? "d"
          : states.isMonth
          ? "m"
          : states.isYear
          ? "y"
          : "m",
      })
    );
    return (window.location.href =
      "/admin/performance/" + selectedRow["AssessmentResponse.studyCircleId"]);
  };

  const changeMode = async (e, name) => {
    if (name === "mode") {
      setSelectedValues((prevState) => {
        return { ...prevState, modeId: e.target.value };
      });
    } else if (name === "organise") {
      setSelectedValues((prevState) => {
        return { ...prevState, organiserId: e.target.value };
      });
    } else if (name === "type") {
      setSelectedValues((prevState) => {
        return { ...prevState, typeId: e.target.value };
      });
    } else if (name === "studyCircle") {
      setSelectedValues((prevState) => {
        return { ...prevState, studyCircleId: e.target.value };
      });
    } else if (name === "subject") {
      setSelectedValues((prevState) => {
        return { ...prevState, subjectId: e.target.value };
      });
    } else return null;
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.loading_animation}>
          <ReactLoading
            type="bars"
            color="#FC8802"
            height="10%"
            width="10%"
            className={styles.reactLoadingBars}
            // className="startLoadSpin"
          />
        </div>
      ) : null}
      <GridContainer>
        <div className={styles.top_div}>
          <div className={styles.top_box}>
            <div className={styles.calDiv}>
              <div className={styles.left}>
                <div className={styles.options}>
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.modeOptions}
                    value={selectedValues.modeId}
                    changeMode={changeMode}
                    name={"mode"}
                    id_name={"name"}
                    modeslabel={"Mode"}
                    defaultOption={"All Mode"}
                    selected_id={"assessment_mode_id"}
                  />
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.organiserOptions}
                    value={selectedValues.organiserId}
                    changeMode={changeMode}
                    name={"organise"}
                    id_name={"name"}
                    modeslabel={"Organiser"}
                    defaultOption={"Organiser"}
                    selected_id={"organiser_type_id"}
                  />
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.typeOptions}
                    value={selectedValues.typeId}
                    changeMode={changeMode}
                    name={"type"}
                    id_name={"name"}
                    modeslabel={"Type"}
                    defaultOption={"All Type"}
                    selected_id={"assessment_type_id"}
                  />
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.studyCircleOptions}
                    value={selectedValues.studyCircleId}
                    changeMode={changeMode}
                    name={"studyCircle"}
                    id_name={"study_circle_name"}
                    modeslabel={"Study circle"}
                    defaultOption={"All Study Circle"}
                    selected_id={"study_circle_id"}
                  />
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.subjectOptions}
                    value={selectedValues.subjectId}
                    changeMode={changeMode}
                    name={"subject"}
                    id_name={"subject"}
                    modeslabel={"Subject"}
                    defaultOption={"All Subjects"}
                    selected_id={"subject_id"}
                  />
                </div>
              </div>
              <div style={{ marginTop: "8px" }}>{calandarSection()}</div>
            </div>
          </div>
        </div>
        <PerformanceTable
          PerformanceData={states.t_body}
          tableClick={tableClick}
        />
      </GridContainer>
      {/* <div style={{paddingTop:'10px'}}><PerformanceSubjectDetail/></div> */}
    </>
  );
};

export default Performance;
