import React, { forwardRef } from "react"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TrendChart from "../trendChart/TrendChart";
import moment from "moment";
import { trend } from "../../pages/Performance/Images/Images"
import calanderIcon from "../../assets/images/calender-icon.png"
import arrowLeft from "../../assets/images/arrow-left.png";
import arrowRight from "../../assets/images/arrow-right.png";

const PerformanceTrendSection = (props) => {
    const { styles, trendStartDate, trendEndDate, handleDateChange, handleEndDateChange, handleStartDateChange, cubeData } = props

    const CalenderInput = forwardRef(({ value, onClick }, ref) => (
        <div className="calender">
            <img src={calanderIcon} alt="calender-icon" />
            <button className="calender-input" onClick={onClick} ref={ref}>
                {value}
            </button>
        </div>
    ));

    return (
        <div className={styles.last_div_left}>
            <div className={styles.trend}>
                <div className={styles.trendImg}>
                    <img src={trend} alt="trend" />
                </div>
                <div className={styles.trendTxt}>TREND</div>
            </div>
            <div className={styles.leftChart}>
                <div style={{ padding: "10px" }}>
                    <div
                        style={{
                            display: "block",
                            padding: "5px",
                            float: "right",
                        }}
                    >
                        <div style={{ display: "inline-block" }}>
                            <div style={{ fontWeight: "bold" }}>Start Date</div>
                            <DatePicker
                                showIcon={true}
                                closeOnScroll={true}
                                dateFormat="dd/MM/yyyy"
                                selected={trendStartDate}
                                onChange={(date) => handleDateChange(date)}
                                showYearDropdown
                                scrollableMonthYearDropdown
                                customInput={<CalenderInput />}
                                wrapperClassName="datePicker"
                            />
                        </div>
                        <div
                            style={{ display: "inline-block", marginLeft: "10px" }}
                        >
                            <div style={{ fontWeight: "bold" }}>End Date</div>
                            <DatePicker
                                showIcon={true}
                                closeOnScroll={true}
                                dateFormat="dd/MM/yyyy"
                                selected={trendEndDate}
                                onChange={(date) => handleEndDateChange(date)}
                                showYearDropdown
                                scrollableMonthYearDropdown
                                customInput={<CalenderInput />}
                                wrapperClassName="datePicker"
                            />
                        </div>
                        <div
                            style={{
                                float: "right",
                                marginLeft: "10px",
                                marginRight: "10px",
                                display: "inline-block",
                            }}
                        >
                            <img
                                style={{
                                    borderRadius: "50%",
                                    backgroundColor: "orange",
                                    width: "32px",
                                    marginTop: "20px",
                                    border: "2px solid grey",
                                }}
                                src={arrowLeft}
                                onClick={() => handleStartDateChange(-7)}
                            />
                            &nbsp;
                            <img
                                style={{
                                    borderRadius: "50%",
                                    backgroundColor: "orange",
                                    width: "32px",
                                    marginTop: "20px",
                                    border: "2px solid grey",
                                }}
                                src={arrowRight}
                                onClick={() => handleStartDateChange(7)}
                            />
                        </div>
                    </div>

                    <TrendChart
                        graph="performance"
                        trendData={cubeData.trendData}
                        startDate={moment(trendStartDate).format("YYYY-MM-DD")}
                        endDate={trendEndDate}
                    />
                </div>
            </div>
        </div>
    )
}

export default PerformanceTrendSection