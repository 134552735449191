import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "../../pages/Dashboard/index";
import Attendance from "../../pages/Attendance/index";
import Performance from "../../pages/Performance/index";
import Session from "../../pages/Session/index";
import Students from "../../pages/Students/index";
import Report from "../../pages/Reports/index";
import PerformanceDetails from "../../pages/Performance/PerformanceSubjectDetails/PerformanceSubjectDetail";
import StudentDetails from "../../pages/Students/StudentsDetailscreen/StudentDetails";
import SessionDetails from "../../pages/Session/session_render/SessionScreen";
import DataBoard from "../../pages/DataBoard/DataBoard";
import TestPage from "../../pages/TestPage/TestPage";

export default function RouteParent(props) {
  const { updateDisplayText } = props;

  return (
    <Switch>
      <Route exact path="/admin/dashboard" component={Dashboard} />
      <Route exact path="/admin/attendance" component={Attendance} />
      <Route exact path="/admin/performance" component={Performance} />
      <Route exact path="/admin/assessments" component={Session} />
      <Route
        exact
        path="/admin/students"
        render={() => {
          return <Students updateDisplayText={updateDisplayText} />;
        }}
      />
      <Route exact path="/admin/reports" component={Report} />
      <Route
        exact
        path="/admin/students/:id"
        render={() => {
          return <StudentDetails updateDisplayText={updateDisplayText} />;
        }}
      />
      <Route
        exact
        path="/admin/performance/:id"
        component={PerformanceDetails}
      />
      <Route exact path="/admin/assessments/:id" component={SessionDetails} />
      <Route exact path="/admin/test-page" component={TestPage} />
      <Route exact path="/admin/data" component={DataBoard} />
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );
}
