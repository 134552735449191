import { CUBEJS_API } from "../../config/Api";
import cubejs from "@cubejs-client/core";
import { toast } from "react-toastify";

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem("cmn_cubeJs_token");
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(error) {
  console.error(error);
  if (error?.response?.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

//Reference - RA-PERF-08 Performance Trend graph
export async function fetchPerformanceTrendChart(startDate, endDate) {
  console.log("startDate, endDate in fetchPerformanceTrendChart", startDate, endDate)
  const cubeJsApi = cubeFunction();
  const qScore = {
    measures: [
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.avgScore",
      "AssessmentResponse.avgAccuracy",
      "AssessmentResponse.groupPerformanceRank",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: {
      "AssessmentResponse.avgVelocity": "desc",
    },
    filters: [
      {
        member: "AssessmentResponse.assessmentDate",
        operator: "inDateRange",
        values: [startDate, endDate],
      },
    ],
    dimensions: ["AssessmentResponse.assessmentDate"],
  };

  try {
    // const result = await cubeJsApi.load(qScore);
    // console.log("result inside cube query fetchStudentTrendChart", result);
    const startTime = Date.now()
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for fetchPerformanceTrendChart", timeTaken)
    return result;
  } catch (error) {
    handleError(error);
  }
}
