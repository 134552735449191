import { fetchDifficultySummary } from "../../../api/cube/performanceAPI";

function roundTwoDecimal(value) {
    const result = Math.round(value * 100) / 100;
    return result;
}

export async function difficultySummary(studyCircleId, selectedGroupId) {
    const summary = await fetchDifficultySummary(studyCircleId, selectedGroupId);
    if (
        summary.loadResponses[0].data.length !== 0 &&
        summary.loadResponses[0].data[0] !== null
    ) {
        const data = summary.loadResponses[0].data;
        const dataSoretd = data.sort((a, b) => {
            return a["Question.difficulty"] > b["Question.difficulty"] ? 1 : -1;
        });
        const difficulty = [];
        const correct = [];
        const nearCorrect = [];
        const incorrect = [];
        const notAnswered = [];
        const totalValue = [];
        dataSoretd.forEach(function (ary) {
            difficulty.push(ary["Question.difficulty"]);
            correct.push(
                roundTwoDecimal(
                    (ary["AssessmentResponse.totalCorrects"] * 100) /
                    ary["AssessmentResponse.count"]
                )
            );
            nearCorrect.push(
                roundTwoDecimal(
                    (ary["AssessmentResponse.totalNearCorrects"] * 100) /
                    ary["AssessmentResponse.count"]
                )
            );
            incorrect.push(
                roundTwoDecimal(
                    (ary["AssessmentResponse.totalIncorrects"] * 100) /
                    ary["AssessmentResponse.count"]
                )
            );
            notAnswered.push(
                roundTwoDecimal(
                    (ary["AssessmentResponse.totalNotAnswereds"] * 100) /
                    ary["AssessmentResponse.count"]
                )
            );
            totalValue.push(ary["AssessmentResponse.questionsCount"]);
        });
        // console.log({
        //   difficulty: difficulty,
        //   correct: correct,
        //   nearCorrect: nearCorrect,
        //   incorrect: incorrect,
        //   notAnswered: notAnswered,
        //   totalValue: totalValue,
        // });
        return {
            difficulty: difficulty,
            correct: correct,
            nearCorrect: nearCorrect,
            incorrect: incorrect,
            notAnswered: notAnswered,
            totalValue: totalValue,
        };
    } else {
        return {
            difficulty: [0],
            correct: [0],
            nearCorrect: [0],
            incorrect: [0],
            notAnswered: [0],
            totalValue: [0],
        };
    }
}
