import React, { useState, useEffect, useRef } from "react";
import "./StudentDetails.css";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Slider from "@material-ui/core/Slider";
import styles from "./StudentDetails.module.css";
import { GridContainer } from "../../../components/grid/index";
import StudentImg from "../../../assets/images/user2.png";
import calanderIcon from "../../../assets/images/calender-icon.png";
import prevDark from "../../../assets/images/dark-prev-arrow.png";
import nextDark from "../../../assets/images/dark-next-arrow.png";
import arrowLeft from "../../../assets/images/arrow-left.png";
import arrowRight from "../../../assets/images/arrow-right.png";
import attendanceNormal from "../../../assets/images/attendance-normal.png";
import readVelocityBlack from "../../../assets/images/ra-black.png";
import {
  trend,
  gause,
  Sequrered,
  greenArrow,
  handRaise,
  // redArrow,
  orangeArrow,
  Sequreblue,
  Sequregreen,
  Sequregrey,
} from "../Images/Images";

import {
  fetchStudentStudyCircleRank,
  fetchStudentGroupRank,
  fetchTotalStudentsInStudyCircle,
  fetchTotalStudentsInGroup,
} from "../../../api/cube/studentAPI";
import {
  getAssessmentType,
  getModes,
  getOrganiser,
  getSubjectStudy,
  getSubSubject,
  getTopic,
  getSubTopic,
  getObjective,
} from "../../../api/masterReadAnalytics/dropdownAPI";
import { getGroupInstitutionId } from "../../../api/masterReadAnalytics/sessionAPI";
import {
  fetchAllStudentDetails,
  fetchAllGroupDetails,
} from "../../../api/cube/combineAPI";
import { fetchStudyAvg } from "../../../api/cube/studentAPI";
import { fetchStudentTrendChart } from "../../../api/cube/studentTrendChart";
import { getSubjectReadVelocity } from "./helperFunctions/getSubjectReadVelocity";
import { createStudentFilter } from "./helperFunctions/createStudentFilter";
import { getDifficultyChartData } from "./helperFunctions/getDifficultyChartData";
import { getScoreGraph } from "./helperFunctions/getScoreGraph";
import { getGrpTime } from "./helperFunctions/getGrpTime";

import HandRisePopup from "../../../components/HandRisePopup/HandRisePopup";
import StudentBasicInfo from "../../../components/StudentBasicInfo/StudentBasicInfo";
import StudentDetailDropdown from "../../../components/StudentDetailDropdown/StudentDetailDropdown";
import StudentReadVelocityHeader from "../../../components/StudentReadVelocityHeader/StudentReadVelocityHeader";
import LoadingBars from "../../../components/LoadingBars/LoadingBars";
import StudentReadVelocity from "../../../components/StundentReadVelocity/StudentReadVelocity";
import StudentDifficultyChart from "../../../components/StudentDifficultyChart/StudentDifficultyChart";
import StudentAccuracySpeedometer from "../../../components/StudentAccuracySpeedometer/StudentAccuracySpeedometer";
import StudentScoreBar from "../../../components/StudentScoreBar/StudentScoreBar";
import StudentHandRaiseSection from "../../../components/StudentHandRaiseSection/StudentHandRaiseSection";
import StudentTimeBar from "../../../components/StudentTimeBar/StudentTimeBar";
import StudentTrendSection from "../../../components/StudentTrendSection/StudentTrendSection";

import {
  // HandRaiseChart,
  DifficultyChart,
  AccuracyChart,
} from "./Charts/index";
import promiseAllCall from "../../../utils/promise";

const StudentDetails = (props) => {
  const { updateDisplayText } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({
    modeOptions: [],
    assessmentOptions: [],
    organiserOptions: [],
    subjectOptions: [],
    subSubjectOptions: [],
    topicOptions: [],
    subTopicOptions: [],
    objectivesOptions: [],
  });
  const [selectedValue, setSelectedValue] = useState({
    assessmentModeId: -1,
    assessmentTypeId: -1,
    organiserId: -1,
    subjectId: -1,
    subSubjectId: -1,
    topicId: -1,
    subTopicId: -1,
    objectiveId: -1,
  });
  const [cubeData, setCubeData] = useState({
    subjectReadVel: [], // 8 calls
    studyAvgReadVelocity: 3.5,
    studyCirclePercentile: 86,
    groupPercentile: 76,
    difficultySum: {
      difficulty: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      correct: [30, 40, 80, 60, 80, 50, 40, 20, 30, 60],
      nearCorrect: [30, 10, 7, 0, 12, 50, 45, 40, 10, 15],
      incorrect: [10, 30, 7, 40, 8, 0, 10, 0, 40, 20],
      notAnswered: [30, 20, 6, 0, 0, 0, 5, 40, 20, 5],
      totalValue: [10, 7, 6, 5, 10, 9, 5, 4, 2, 8],
    },
    scoreGraph: {
      correct: 25,
      incorrect: 25,
      nearCorrect: 25,
      notAnswered: 25,
      total: 100,
      correctPer: "25%",
      incorrectPer: "25%",
      nearCorrectPer: "25%",
      notAnsweredPer: "25%",
    },
    timeMark: 1.25,
  });
  const [studentDetails, setStudentDetails] = useState({
    studentReadVelocity: 4.5,
    score: 0,
    accuracy: 0,
    time: 0,
    attendance: { present: 12, total: 15 },
    handRaise: 5,
    trendData: [],
  });
  const [groupDetails, setGroupDetails] = useState({
    groupAvgVelocity: 5,
    groupAccuracy: 0,
    groupScore: 0,
    groupTime: 0,
  });
  console.log("cubeData", cubeData);
  const [groupInstitutionId, setGroupInstitutionId] = useState("");
  const initialRender = useRef(false);

  const studentData = JSON.parse(window.localStorage.getItem("studentData"));
  const studentName = studentData.studentName;
  const studentProfilePic = studentData.studentProfilePic;
  const rollNumber = studentData.rollNumber;
  const studyCircleId = studentData.studyCircleId.toString();
  const studyCircleName = studentData.studyCircleName.slice(0, 2);
  const studentId = window.location.pathname.slice(16);
  const institutionId = window.localStorage.getItem("cmn_school_id");
  const [trendStartDate, setTrendStartDate] = useState(
    new Date(moment().subtract(7, "days").format("YYYY-MM-DD"))
  );
  const [trendEndDate, setTrendEndDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [dateDiff, setDateDiff] = useState(7);

  const studentFilter = [
    {
      member: "AssessmentResponse.studentId",
      operator: "equals",
      values: [studentId.toString()],
    },
  ];
  const groupFilter = [
    {
      member: "Assessment.groupInstitutionId",
      operator: "equals",
      values: [`${groupInstitutionId}`],
    },
  ];
  const myStudyCircleRankFilter = [
    {
      member: "AssessmentResponse.studyCircleId",
      operator: "equals",
      values: [`${studyCircleId}`],
    },
    {
      member: "AssessmentResponse.studentId",
      operator: "equals",
      values: [`${studentId}`],
    },
  ];
  const totalStudentsInStudyCircleFilter = [
    {
      member: "AssessmentResponse.studyCircleId",
      operator: "equals",
      values: [`${studyCircleId}`],
    },
  ];
  const myGroupRankFilter = [
    {
      member: "AssessmentResponse.studentId",
      operator: "equals",
      values: [`${studentId}`],
    },
  ];
  let individualStudentDetail;
  let allSubReadVelFilter = createStudentFilter(
    studentId,
    selectedValue.assessmentModeId,
    selectedValue.assessmentTypeId,
    selectedValue.organiserId,
    selectedValue.subjectId,
    selectedValue.topicId,
    selectedValue.objectiveId,
    (individualStudentDetail = true)
  );

  useEffect(() => {
    const initializeValues = async () => {
      updateDisplayText(studentName);
      const grpInstId = await getGroupInstitutionId(studyCircleId);
      setGroupInstitutionId(grpInstId["group_institution_id"]);
      await fetchInitialDropdown();
      // await fetchInitialCubeData();
      // setIsLoading(false);
    };
    // setIsLoading(true);
    initializeValues();
  }, []);

  useEffect(() => {
    const callDropdownDependents = async () => {
      const subSubjects = await getSubSubject(
        institutionId,
        selectedValue.subjectId
      );
      const topics = await getTopic(
        institutionId,
        selectedValue.subjectId,
        selectedValue.subSubjectId
      );
      const subTopics = await getSubTopic(
        institutionId,
        selectedValue.subjectId,
        selectedValue.subSubjectId,
        selectedValue.topicId
      );
      setOptions((prevState) => {
        return {
          ...prevState,
          subSubjectOptions: subSubjects?.data?.data,
          topicOptions: topics?.data?.data,
          subTopicOptions: subTopics?.data?.data,
        };
      });
      // setIsLoading(false);
    };
    // setIsLoading(true);
    callDropdownDependents();
  }, [
    selectedValue.subjectId,
    selectedValue.topicId,
    selectedValue.subSubjectId,
  ]);

  useEffect(() => {
    const fetchInitialGrpCubeValues = async () => {
      const callAll = await promiseAllCall([
        fetchAllGroupDetails(groupFilter),
        getGrpTime(allSubReadVelFilter, groupInstitutionId),
      ]);
      const grpCubeValues = callAll[0];
      console.log("grpCubeValues", grpCubeValues);
      setGroupDetails((prevState) => {
        return {
          ...prevState,
          groupAvgVelocity: (
            +grpCubeValues["AssessmentResponse.avgVelocity"] || 0
          ).toFixed(2),
          groupAccuracy: (
            +grpCubeValues["AssessmentResponse.avgAccuracy"] || 0
          ).toFixed(2),
          groupScore: (
            +grpCubeValues["AssessmentResponse.avgMarksScored"] || 0
          ).toFixed(2),
          groupTime: (
            +grpCubeValues["AssessmentResponse.avgTimeTaken"] || 0
          ).toFixed(2),
        };
      });
      const time_text = callAll[1];
      // console.log("time_text", time_text);
      setCubeData((prevState) => {
        return { ...prevState, timeMark: time_text };
      });
      // setIsLoading(false);
    };
    if (initialRender.current) {
      // setIsLoading(true);
      fetchInitialGrpCubeValues();
    } else {
      initialRender.current = true;
    }
  }, [groupInstitutionId]);

  // console.log("allSubReadVelFilter outside", allSubReadVelFilter);
  useEffect(() => {
    const fetchCubeDataAgain = async () => {
      allSubReadVelFilter = createStudentFilter(
        studentId,
        selectedValue.assessmentModeId,
        selectedValue.assessmentTypeId,
        selectedValue.organiserId,
        selectedValue.topicId,
        selectedValue.subjectId,
        selectedValue.objectiveId,
        (individualStudentDetail = true)
      );
      await fetchInitialCubeData();
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchCubeDataAgain();
  }, [
    selectedValue.subjectId,
    selectedValue.topicId,
    selectedValue.assessmentModeId,
    selectedValue.assessmentTypeId,
    selectedValue.objectiveId,
    selectedValue.organiserId,
  ]);

  useEffect(() => {
    const getStudentTrendData = async () => {
      try {
        const studentTrendData = await fetchStudentTrendChart(
          studentId,
          trendStartDate,
          trendEndDate
        );
        console.log("studentTrendData", studentTrendData);
        setStudentDetails((prevState) => {
          return { ...prevState, trendData: studentTrendData };
        });
      } catch (err) {
        console.log("err in getStudentTrendData", err);
      }
    };
    getStudentTrendData();
  }, [trendStartDate, trendEndDate]);

  const fetchInitialDropdown = async () => {
    try {
      const fetchInitialDropdownData = await promiseAllCall([
        getModes(),
        getOrganiser(),
        getAssessmentType(),
        getObjective(),
        getSubjectStudy(institutionId, studyCircleId),
      ]);
      setOptions((prevState) => {
        return {
          ...prevState,
          modeOptions: fetchInitialDropdownData[0]?.data?.data,
          organiserOptions: fetchInitialDropdownData[1]?.data?.data,
          assessmentOptions: fetchInitialDropdownData[2]?.data?.data,
          objectivesOptions: fetchInitialDropdownData[3]?.data?.data,
          subjectOptions: fetchInitialDropdownData[4]?.data?.data,
        };
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleDropDown = (e, selectedDropdown) => {
    if (selectedDropdown == "mode") {
      setSelectedValue((prevState) => {
        return { ...prevState, assessmentModeId: e.target.value };
      });
    } else if (selectedDropdown == "assessmentType") {
      setSelectedValue((prevState) => {
        return { ...prevState, assessmentTypeId: e.target.value };
      });
    } else if (selectedDropdown == "organiser") {
      setSelectedValue((prevState) => {
        return { ...prevState, organiserId: e.target.value };
      });
    } else if (selectedDropdown == "subject") {
      setSelectedValue((prevState) => {
        return { ...prevState, subjectId: e.target.value };
      });
    } else if (selectedDropdown == "subSubject") {
      setSelectedValue((prevState) => {
        return { ...prevState, subSubjectId: e.target.value };
      });
    } else if (selectedDropdown == "topic") {
      setSelectedValue((prevState) => {
        return { ...prevState, topicId: e.target.value };
      });
    } else if (selectedDropdown == "subTopic") {
      setSelectedValue((prevState) => {
        return { ...prevState, subTopicId: e.target.value };
      });
    } else if (selectedDropdown == "objective") {
      setSelectedValue((prevState) => {
        return { ...prevState, objectiveId: e.target.value };
      });
    } else return null;
  };

  const getStudyCirclePercentile = async () => {
    const callAll = await promiseAllCall([
      fetchStudentStudyCircleRank(myStudyCircleRankFilter),
      fetchTotalStudentsInStudyCircle(totalStudentsInStudyCircleFilter),
    ]);
    const myStudyCircleRank = callAll[0];
    const totalStudentsInStudyCircle = callAll[1];
    // console.log(
    //   "myStudyCircleRank, totalStudentsInStudyCircle",
    //   myStudyCircleRank,
    //   totalStudentsInStudyCircle
    // );
    const studyCirclePercentile = (
      +(1 - (myStudyCircleRank * 1.0) / totalStudentsInStudyCircle) * 100
    ).toFixed(2);
    // console.log("study circle percentile", studyCirclePercentile);
    return studyCirclePercentile;
  };

  const getGroupPercentile = async () => {
    const callAll = await promiseAllCall([
      fetchStudentGroupRank(myGroupRankFilter),
      fetchTotalStudentsInGroup(),
    ]);
    const myGroupRank = callAll[0];
    const totalStudentsInGroup = callAll[1];
    // console.log(
    //   "group percentile: myGroupRank, totalStudentsInGroup",
    //   myGroupRank,
    //   totalStudentsInGroup
    // );
    const groupPercentile = (
      +(1 - (myGroupRank * 1.0) / totalStudentsInGroup) * 100
    ).toFixed(2);
    // console.log("group percentile", groupPercentile);
    return groupPercentile;
  };

  const fetchInitialCubeData = async () => {
    console.log(
      "allSubReadVelFilter inside fetchInitialCubeData",
      allSubReadVelFilter
    );
    try {
      const callAll = await promiseAllCall([
        getSubjectReadVelocity(allSubReadVelFilter, studyCircleId, studentId),
        fetchStudyAvg(allSubReadVelFilter, studyCircleId),
        getStudyCirclePercentile(),
        getGroupPercentile(),
        getDifficultyChartData(allSubReadVelFilter),
        getScoreGraph(allSubReadVelFilter),
        fetchAllStudentDetails([...allSubReadVelFilter, ...studentFilter]),
      ]);
      const subjectReadVel = callAll[0];
      const studyAvgVal = callAll[1];
      const studentStudyCirclePercentile = callAll[2];
      const studentGroupPercentile = callAll[3];
      const difficultyChartData = callAll[4];
      const studentScoreGraph = callAll[5];
      const studentDetails = callAll[6];
      // console.log("subjectReadVel", subjectReadVel);
      // console.log("studyAvgVal", studyAvgVal, typeof studyAvgVal);
      // console.log("studentStudyCirclePercentile", studentStudyCirclePercentile);
      // console.log("studentGroupPercentile", studentGroupPercentile);
      // console.log("difficultyChartData", difficultyChartData);
      // console.log("studentScoreGraph", studentScoreGraph);
      setCubeData((prevState) => {
        return {
          ...prevState,
          subjectReadVel: subjectReadVel,
          studyAvgReadVelocity: (
            +studyAvgVal["AssessmentResponse.avgVelocity"] || 0
          ).toFixed(2),
          studyCirclePercentile: studentStudyCirclePercentile,
          groupPercentile: studentGroupPercentile,
          difficultySum: difficultyChartData,
          scoreGraph: studentScoreGraph,
        };
      });
      // console.log("studentDetails", studentDetails);
      if (!studentDetails) {
        toast.error("Data not found for selected dropdowns");
        return;
      }
      setStudentDetails((prevState) => {
        return {
          ...prevState,
          studentReadVelocity: (
            +studentDetails["AssessmentResponse.avgVelocity"] || 0
          ).toFixed(2),
          score: studentDetails["AssessmentResponse.marksScored"] || 0,
          accuracy: (
            +studentDetails["AssessmentResponse.avgAccuracy"] || 0
          ).toFixed(2),
          time: (
            +studentDetails["AssessmentResponse.avgTimeTaken"] || 0
          ).toFixed(2),
          attendance: {
            present: studentDetails["AssessmentResponse.attendancePresent"],
            total: studentDetails["AssessmentResponse.attendanceTotal"],
          },
          handRaise: studentDetails["StudentHandRaise.handRaise"],
        };
      });
    } catch (err) {
      console.log("err in fetchInitialCubeData", err);
    }
  };

  // Calender date change handler
  const handleStartDateChange = async (date) => {
    console.log("date", moment(date).format("YYYY-MM-DD"));
    let dateDifference = moment(date)
      .add(1, "day")
      .diff(moment(trendEndDate), "days");
    console.log("dateDifference", dateDifference);
    if (dateDifference > 0) {
      toast.error("Start date should be before end date!");
      return;
    }
    if (dateDifference < 0) {
      dateDifference = -dateDifference;
    }
    if (dateDifference > 0 && dateDifference < 7) {
      setDateDiff(dateDifference);
    } else if (dateDifference >= 7) {
      setDateDiff(7);
    }
    setTrendStartDate(date);
  };

  // Calender date change handler
  const handleEndDateChange = async (date) => {
    console.log("date", moment(date).format("YYYY-MM-DD"));
    let dateDifference = moment(date)
      .add(1, "day")
      .diff(moment(trendStartDate), "days");
    console.log("dateDifference", dateDifference);
    if (dateDifference < 0) {
      toast.error("End date should be after the start date!");
      return;
    }
    if (dateDifference > 0 && dateDifference < 7) {
      setDateDiff(dateDifference);
    } else if (dateDifference >= 7) {
      setDateDiff(7);
    }
    setTrendEndDate(date);
  };

  // Calender date change handler
  const handleDateArrowClick = (n) => {
    let date = moment(trendStartDate).add(n, "days");
    setTrendStartDate(new Date(date.format("YYYY-MM-DD")));
    let endDate = moment(trendEndDate).add(n, "days");
    setTrendEndDate(new Date(endDate.format("YYYY-MM-DD")));
  };

  return (
    <>
      {isLoading ? <LoadingBars /> : null}
      <StudentBasicInfo
        styles={styles}
        studentName={studentName}
        studentProfilePic={studentProfilePic}
        studyCircleName={studyCircleName}
        rollNumber={rollNumber}
        options={options}
        selectedValue={selectedValue}
        handleDropDown={handleDropDown}
      />
      <StudentReadVelocity
        styles={styles}
        readVelocityData={cubeData.subjectReadVel}
      />
      <div className={styles.selectRow}>
        <GridContainer>
          <div className={styles.selectBox}>
            <div className={styles.selectBox1}>
              <StudentDetailDropdown
                className={styles.select4}
                name="subject"
                option={"All Subject"}
                value={selectedValue.subjectId}
                onChangeFun={handleDropDown}
                options={options.subjectOptions}
                selectedId={"subject_id"}
                displayName={"subject"}
              />
              <StudentDetailDropdown
                className={styles.select4}
                name="subSubject"
                option={"Sub-subject"}
                value={selectedValue.subSubjectId}
                onChangeFun={handleDropDown}
                options={options.subSubjectOptions}
                selectedId={"sub_subject_id"}
                displayName={"sub_subject_name"}
              />
              <StudentDetailDropdown
                className={styles.select4}
                name="topic"
                option={"All Topic"}
                value={selectedValue.topicId}
                onChangeFun={handleDropDown}
                options={options.topicOptions}
                selectedId={"topic_id"}
                displayName={"topic_name"}
              />
              <StudentDetailDropdown
                className={styles.select4}
                name="subTopic"
                option={"Sub-Topic"}
                value={selectedValue.subTopicId}
                onChangeFun={handleDropDown}
                options={options.subTopicOptions}
                selectedId={"sub_topic_id"}
                displayName={"sub_topic_name"}
              />
              <StudentDetailDropdown
                className={styles.select4}
                name="objective"
                option={"Objective"}
                value={selectedValue.objectiveId}
                onChangeFun={handleDropDown}
                options={options.objectivesOptions}
                selectedId={"objective_id"}
                displayName={"objective"}
              />
            </div>
          </div>
        </GridContainer>
        <div className={styles.chartRow}>
          <GridContainer>
            <div className={styles.left}>
              <StudentReadVelocityHeader
                styles={styles}
                studentReadVelocity={studentDetails.studentReadVelocity}
                groupAvgVelocity={groupDetails.groupAvgVelocity}
                studyAvgReadVelocity={cubeData.studyAvgReadVelocity}
                studyCirclePercentile={cubeData.studyCirclePercentile}
                groupPercentile={cubeData.groupPercentile}
              />
              <div className={styles.Acc}>
                <StudentDifficultyChart
                  styles={styles}
                  difficultySum={cubeData.difficultySum}
                />
                <StudentAccuracySpeedometer
                  styles={styles}
                  accuracy={studentDetails.accuracy}
                  groupAccuracy={groupDetails.groupAccuracy}
                />
              </div>
              <div className={styles.Acc}>
                <StudentScoreBar
                  styles={styles}
                  studentScore={studentDetails.score}
                  groupScore={groupDetails.groupScore}
                  scoreGraph={cubeData.scoreGraph}
                />
                <StudentTimeBar
                  styles={styles}
                  studentTime={studentDetails.time}
                  groupTime={groupDetails.groupTime}
                  timeMark={cubeData.timeMark}
                />
              </div>
            </div>
          </GridContainer>
        </div>
        <div className={styles.chartRow}>
          <GridContainer>
            <div className={styles.last_div}>
              <StudentTrendSection
                styles={styles}
                trendStartDate={trendStartDate}
                trendEndDate={trendEndDate}
                dateDiff={dateDiff}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                handleDateArrowClick={handleDateArrowClick}
                trendData={studentDetails.trendData}
              />
              <StudentHandRaiseSection
                studentDetails={studentDetails}
                styles={styles}
              />
            </div>
          </GridContainer>
        </div>
      </div>
    </>
  );
};

export default StudentDetails;
