import React, { useState, useEffect } from "react";
import { GridContainer } from "../../../components/grid/index";
import "./SessionScreen.css";
import moment from "moment";
import { getQuestionBar } from "./SessionDetailsComponents/getQuestionBar";
import { getStudentWiseResult } from "./SessionDetailsComponents/getStudentWiseResult";
import { getAssessmentDetails } from "../../../api/masterReadAnalytics/sessionAPI";
import { getStudentWiseSummary } from "./SessionDetailsComponents/getStudentWiseSummary";
import { getQuestionDetails } from "../../../api/masterReadAnalytics/sessionAPI";
import {
  getGroupInstitutionId,
  getGrpSessionList,
} from "../../../api/masterReadAnalytics/sessionAPI";
import {
  getReadVelocitySessionAPI,
  getReadVelocityTopicAPI,
} from "../../../api/cube/sessionAPI";
import { toast } from "react-toastify";
import LoadingBars from "../../../components/LoadingBars/LoadingBars";
import AssessmentSerialNumber from "../../../components/AssessmentSerialNumber/AssessmentSerialNumber";
import promiseAllCall from "../../../utils/promise";
import styles from "./SessionScreen.module.css";
import AssessmentHeaderCarousel from "../../../components/AssessmentHeaderCarousel/AssessmentHeaderCarousel";
import AssessmentStudioSection from "../../../components/AssessmentStudioSection/AssessmentStudioSection";
import AssessmentQuestionSection from "../../../components/AssessmentQuestionSection/AssessmentQuestionSection";
import AssessmentAnswerDetails from "../../../components/AssessmentAnswerDetails/AssessmentAnswerDetails";
import AssessmentStudentRankSection from "../../../components/AssessmentStudentRankSection/AssessmentStudentRankSection";
import AssessmentStudentResponses from "../../../components/AssessmentStudentResponses/AssessmentStudentResponses";

const SessionScreen = () => {
  const [charts, setCharts] = useState([
    {
      data: [10, 40, 20, 20],
    },
    {
      data: [10, 40, 20, 20],
    },
    {
      data: [40, 40, 10, 10],
    },
    {
      data: [10, 40, 40, 10],
    },
    {
      data: [40, 20, 20, 20],
    },
    {
      data: [20, 40, 10, 20],
    },
    {
      data: [10, 40, 20, 20],
    },
    {
      data: [30, 30, 20, 20],
    },
    {
      data: [20, 30, 30, 20],
    },
    {
      data: [20, 40, 10, 20],
    },
    {
      data: [10, 40, 20, 20],
    },
    {
      data: [30, 30, 20, 20],
    },
    {
      data: [20, 30, 30, 20],
    },
  ]);
  const [readVelocitySession, setReadVelocitySession] = useState({});
  const [readVelocityTopic, setReadVelocityTopic] = useState("");
  const [assessmentDetails, setAssessmentDetails] = useState({
    start_time: "00000",
    end_time: "00000",
  });
  const [studentWiseResult, setStudentWiseResult] = useState({
    studentWiseResultArray: [],
    count: [],
  });
  const [studentWiseAnswer, setStudentWiseAnswer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const [groupInstitutionId, setGroupInstitutionId] = useState("");
  const [questionDetails, setQuestionDetails] = useState({});
  const [answerOptions, setAnswerOptions] = useState([]);
  const [grpSessionList, setGrpSessionList] = useState([]);
  const [displayCode, setDisplayCode] = useState("");

  const sessionThead = ["R#", "Name", "Session", "Topic"];
  const assessmentId = window.location.pathname.slice(19);
  const studyCircleId = window.localStorage.getItem("studyCircleId");
  const institutionId = window.localStorage.getItem("cmn_school_id");
  const assesDate = window.localStorage.getItem("assesmentDate");
  const sessionCode = window.localStorage.getItem("groupSessionCode");
  const assessmentList = JSON.parse(localStorage.getItem("assessmentList"));
  let studentWiseFirst;

  useEffect(() => {
    const fetchSessionDetailsCubeData = async () => {
      try {
        const callVelocity = await promiseAllCall([
          getGroupInstitutionId(studyCircleId),
          getReadVelocitySessionAPI(assessmentId, institutionId), //cube
          getAssessmentDetails(assessmentId, studyCircleId),
          getQuestionBar(assessmentId, institutionId),
        ]);
        console.log("callVelocity", callVelocity);
        if (callVelocity[0].group_institution_id !== null) {
          setGroupInstitutionId(callVelocity[0].group_institution_id);
        } else {
          toast.error("Group data is not available");
        }
        setReadVelocitySession(callVelocity[1]);
        if (callVelocity[2] instanceof Error) {
          toast.error(callVelocity[2]);
        }
        setAssessmentDetails(callVelocity[2]);
        await velocityStudent(callVelocity[2]?.topic_id);
        setDisplayCode(sessionCode);
        setCharts(callVelocity[3]);
        let charts = callVelocity[3];
        if (charts?.length > 0 && charts[0]?.questionId) {
          setQuestionId(charts[0].questionId);
          await stuWiseResult(charts[0].questionId);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error in fetchSessionDetailsCubeData", error);
        toast.error("Something went wrong, try again");
        if (error?.response?.data) {
          toast.error(error.response.data.message);
        }
      }
    };
    setLoading(true);
    fetchSessionDetailsCubeData();
  }, []);

  const velocityStudent = async (topic_id) => {
    if (topic_id) {
      try {
        // setLoading(true);
        const callSummary = await promiseAllCall([
          getReadVelocityTopicAPI(topic_id, institutionId),
          getStudentWiseSummary(
            assessmentId,
            topic_id,
            institutionId,
            studyCircleId
          ),
          getGrpSessionList(topic_id, institutionId),
        ]);
        setReadVelocityTopic(callSummary[0]);
        setStudentWiseAnswer(callSummary[1]);
        setGrpSessionList(callSummary[2]);
        // setLoading(false);
      } catch (error) {
        console.log("error2", error);
        setLoading(false);
        toast.error("Something went wrong, try again. ");
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        }
      }
    }
  };

  const stuWiseResult = async (questionId) => {
    try {
      // setLoading(true);
      const callQueDetails = await promiseAllCall([
        getStudentWiseResult(assessmentId, questionId, institutionId),
        getQuestionDetails(questionId),
      ]);
      console.log("callQueDetails", callQueDetails);
      setStudentWiseResult(callQueDetails[0]);
      setQuestionDetails(callQueDetails[1]);
      let questionDetails = callQueDetails[1];
      if (questionDetails?.answer_json) {
        const answer = JSON.parse(questionDetails?.answer_json);
        // console.log("answer", answer);
        setAnswerOptions(answer);
      }
      studentWiseFirst = callQueDetails[0];
      // setLoading(false);
    } catch (error) {
      console.log("error in stuWiseResult", error);
      setLoading(false);
      toast.error("Something went wrong, try again. ");
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
    }
  };

  const calandarSection = () => {
    return (
      <div>
        {moment(assesDate).format("ddd") +
          ", " +
          moment(assesDate).format("DD") +
          " " +
          moment(assesDate).format("MMMM") +
          " " +
          moment(assesDate).format("yyyy")}
      </div>
    );
  };

  const sortArray = (value, key) => {
    switch (key) {
      case "R#":
        if (value) {
          return setStudentWiseAnswer([
            ...studentWiseAnswer.sort((a, b) =>
              a["AssessmentResponse.studentId"] >
              b["AssessmentResponse.studentId"]
                ? 1
                : -1
            ),
          ]);
        } else {
          return setStudentWiseAnswer([
            ...studentWiseAnswer
              .sort((a, b) =>
                a["AssessmentResponse.studentId"] >
                b["AssessmentResponse.studentId"]
                  ? 1
                  : -1
              )
              .reverse(),
          ]);
        }
      case "Name":
        if (value) {
          return setStudentWiseAnswer([
            ...studentWiseAnswer.sort((a, b) =>
              a["Student.name"].toUpperCase() > b["Student.name"].toUpperCase()
                ? 1
                : -1
            ),
          ]);
        } else {
          return setStudentWiseAnswer([
            ...studentWiseAnswer
              .sort((a, b) =>
                a["Student.name"].toUpperCase() >
                b["Student.name"].toUpperCase()
                  ? 1
                  : -1
              )
              .reverse(),
          ]);
        }
      case "Session":
        if (value) {
          return setStudentWiseAnswer([
            ...studentWiseAnswer.sort((a, b) =>
              a["AssessmentResponse.avgVelocity"] >
              b["AssessmentResponse.avgVelocity"]
                ? 1
                : -1
            ),
          ]);
        } else {
          return setStudentWiseAnswer([
            ...studentWiseAnswer
              .sort((a, b) =>
                a["AssessmentResponse.avgVelocity"] >
                b["AssessmentResponse.avgVelocity"]
                  ? 1
                  : -1
              )
              .reverse(),
          ]);
        }
      case "Topic":
        if (value) {
          return setStudentWiseAnswer([
            ...studentWiseAnswer.sort((a, b) =>
              a.topicAvgVelocity > b.topicAvgVelocity ? 1 : -1
            ),
          ]);
        } else {
          return setStudentWiseAnswer([
            ...studentWiseAnswer
              .sort((a, b) =>
                a.topicAvgVelocity > b.topicAvgVelocity ? 1 : -1
              )
              .reverse(),
          ]);
        }
      default:
        return setStudentWiseAnswer(studentWiseFirst);
    }
  };

  const handleViewTopicPerformanceClick = () => {
    window.localStorage.setItem(
      "performanceData",
      JSON.stringify({
        studyCircleId: studyCircleId,
        studyCircleName: assessmentDetails?.study_circle,
        assessmentModeId: assessmentDetails?.assessment_mode_id,
        assessmentTypeId: assessmentDetails?.assessment_type_id,
        topic_id: assessmentDetails?.topic_id,
        subjectId: assessmentDetails?.subject_id,
        prevDate: assesDate,
        nextDate: assesDate,
        calendarSel: "d",
      })
    );
    window.location.href = `/admin/performance/${studyCircleId}`;
    return;
  };

  const handleQuestionBarClick = async (clickedQuestionId) => {
    if (+clickedQuestionId !== +questionId) {
      setLoading(true);
      setQuestionId(clickedQuestionId);
      await stuWiseResult(clickedQuestionId);
      setLoading(false);
    }
  };

  console.log("assessmentList", assessmentList);
  console.log("studentWiseAnswer, charts", studentWiseAnswer, charts);

  const handleArrowClick = (command) => {
    let elementIndex = assessmentList.findIndex(
      (ele) => ele["GroupSession.code"] === displayCode
    );
    if (command == "next" && assessmentList[elementIndex + 1] !== undefined) {
      setDisplayCode(assessmentList[elementIndex + 1]["GroupSession.code"]);

      window.localStorage.setItem(
        "groupSessionCode",
        assessmentList[elementIndex + 1]["GroupSession.code"]
      );
      window.localStorage.setItem(
        "assesmentDate",
        assessmentList[elementIndex + 1]["AssessmentResponse.assessmentDate"]
      );
      window.localStorage.setItem(
        "studyCircleId",
        assessmentList[elementIndex + 1]["StudyCircle.id"]
      );
      let assessment_id =
        assessmentList[elementIndex + 1]["AssessmentResponse.assessmentId"];
      window.location.href = `/admin/assessments/${assessment_id}`;
    } else if (
      command == "prev" &&
      assessmentList[elementIndex - 1] !== undefined
    ) {
      setDisplayCode(assessmentList[elementIndex - 1]["GroupSession.code"]);

      window.localStorage.setItem(
        "groupSessionCode",
        assessmentList[elementIndex - 1]["GroupSession.code"]
      );
      window.localStorage.setItem(
        "assesmentDate",
        assessmentList[elementIndex - 1]["AssessmentResponse.assessmentDate"]
      );
      window.localStorage.setItem(
        "studyCircleId",
        assessmentList[elementIndex - 1]["StudyCircle.id"]
      );
      let assessment_id =
        assessmentList[elementIndex - 1]["AssessmentResponse.assessmentId"];
      window.location.href = `/admin/assessments/${assessment_id}`;
    } else if (
      assessmentList[elementIndex - 1] == undefined ||
      assessmentList[elementIndex + 1] !== undefined
    ) {
      toast.info("No sessions found");
    } else return;
  };

  console.log("assessmentDetails", assessmentDetails);
  console.log("grpSessionList", grpSessionList);

  const handleSessionCodeClick = (
    assessmentId,
    sessionCode,
    assessmentDate,
    studyCircleId
  ) => {
    window.localStorage.setItem("groupSessionCode", sessionCode);
    window.localStorage.setItem("assesmentDate", assessmentDate);
    window.localStorage.setItem("studyCircleId", studyCircleId);
    window.location.href = `/admin/assessments/${assessmentId}`;
  };

  return (
    <>
      {/* calander section */}
      {loading ? <LoadingBars /> : null}
      <div className={styles.top}>
        <GridContainer>
          <div className={styles.boxOne}>
            <AssessmentSerialNumber
              styles={styles}
              handleArrowClick={handleArrowClick}
              displayCode={displayCode}
            />
            <div className={styles.calandarSection}>{calandarSection()}</div>
          </div>
        </GridContainer>
      </div>

      {/* main body left */}
      <div className={styles.boxTwo}>
        <GridContainer>
          <div className={styles.boxTwo_main}>
            <AssessmentHeaderCarousel
              styles={styles}
              grpSessionList={grpSessionList}
              handleSessionCodeClick={handleSessionCodeClick}
              readVelocityTopic={readVelocityTopic}
              sessionCode={sessionCode}
              readVelocitySession={readVelocitySession}
              assessmentDetails={assessmentDetails}
              handleViewTopicPerformanceClick={handleViewTopicPerformanceClick}
            />
            <AssessmentStudioSection
              styles={styles}
              readVelocitySession={readVelocitySession}
              assessmentDetails={assessmentDetails}
            />
          </div>
        </GridContainer>
      </div>

      <div className={styles.boxThr}>
        <GridContainer>
          <div className={styles.main_div}>
            <div className={styles.left_div}>
              <AssessmentQuestionSection
                styles={styles}
                charts={charts}
                questionId={questionId}
                questionDetails={questionDetails}
                handleQuestionBarClick={handleQuestionBarClick}
              />
              <AssessmentAnswerDetails
                styles={styles}
                charts={charts}
                answerOptions={answerOptions}
                questionId={questionId}
                groupInstitutionId={groupInstitutionId}
                institutionId={institutionId}
              />
              <AssessmentStudentRankSection
                styles={styles}
                studentWiseResult={studentWiseResult}
              />
            </div>
            <div className={styles.right_div}>
              <AssessmentStudentResponses
                styles={styles}
                sessionThead={sessionThead}
                sortArray={sortArray}
                studentWiseAnswer={studentWiseAnswer}
                charts={charts}
              />
            </div>
          </div>
        </GridContainer>
      </div>
    </>
  );
};

export default SessionScreen;
