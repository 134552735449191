import React, { useState, useEffect, useRef, forwardRef } from "react";
import styles from "./PerformanceSubjectDetails.module.css";
import { GridContainer, GridItem } from "../../../components/grid/index";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getAssessmentType,
  getModes,
  getSubjectStudy,
  getSubSubject,
  getTopic,
  getSubTopic,
  getObjective,
} from "../../../api/masterReadAnalytics/dropdownAPI";
import {
  fetchStudyCircleRankForGroup,
  fetchTotalStudyCircleCount,
  sessionHeaderFetch,
} from "../../../api/cube/performanceAPI";
import {
  fetchAllPerformanceDetails,
  fetchCombinedPerformanceDetails,
} from "../../../api/cube/combineAPI";
import { fetchPerformanceTrendChart } from "../../../api/cube/performanceTrendChart";
import { sessionwiseHandriseSummary } from "../../../api/cube/handRiseAPI";
import { createPerformanceFilter } from "../helperFunctions/createPerformanceFilter";
import { performanceScoreGraph } from "../helperFunctions/performanceScoreGraph";
import { difficultySummary } from "../helperFunctions/difficultySummary";
import { performanceSpeed } from "../helperFunctions/performanceSpeed";
import { whichTimeFrame } from "../../../components/whichTImeFrame/whichTimeFrame";
import LoadingBars from "../../../components/LoadingBars/LoadingBars";
import HandRisePopup from "../../../components/HandRisePopup/HandRisePopup";
import PerformanceCalendarSection from "../../../components/PerformanceCalendarSection/PerformanceCalendarSection";
import PerformanceDetailsDropdown from "../../../components/PerformanceDetailsDropdown/PerformanceDetailsDropdown";
import PerformanceReadVelocityHeader from "../../../components/PerformanceReadVelocityHeader/PerformanceReadVelocityHeader";
import PerformanceDifficultyChart from "../../../components/PerformanceDifficultyChart/PerformanceDifficultyChart";
import PerformanceAccuracySpeedometer from "../../../components/PerformanceAccuracySpeedometer/PerformanceAccuracySpeedometer";
import PerformanceScoreBar from "../../../components/PerformanceScoreBar/PerformanceScoreBar";
import PerformanceTimeBar from "../../../components/PerformanceTimeBar/PerformanceTimeBar";
import PerformanceTrendSection from "../../../components/PerformanceTrendSection/PerformanceTrendSection";
import PerformanceHandRiseSection from "../../../components/PerformanceHandriseSection/PerformanceHandRiseSection";
import promiseAllCall from "../../../utils/promise";

const PerformanceSubjectDetail = () => {
  const calendarDate =
    localStorage.getItem("oncalendardate") === null
      ? new Date()
      : new Date(localStorage.getItem("oncalendardate"));
  let month = calendarDate.getMonth();
  let today = calendarDate.getDate();
  let year = calendarDate.getFullYear();

  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState({
    isMonth: true,
    isDay: false,
    isYear: false,
    t_body: [],
    modeOptions: [],
    typeOptions: [],
    topicOptions: [],
    subTopicOptions: [],
    subjectOptions: [],
    subSubjectOptions: [],
    objectiveOptions: [],
  });
  const [startdate, setStartDate] = useState(calendarDate);
  const [selectedValues, setSelectedValues] = useState({
    selectedMode: -1,
    selectedType: -1,
    selectedTopic: -1,
    selectedSubTopic: -1,
    selectedSubject: -1,
    selectedSubSubject: -1,
    selectedObjectives: -1,
  });
  const [sessionData, setSessionData] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("-1");
  const [cubeData, setCubeData] = useState({
    readVelocity: 0,
    grpReadVelocity: 0,
    groupPercentile: 0,
    difficultySum: {
      difficulty: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      correct: [30, 40, 80, 60, 80, 50, 40, 20, 30, 60],
      nearCorrect: [30, 10, 7, 0, 12, 50, 45, 40, 10, 15],
      incorrect: [10, 30, 7, 40, 8, 0, 10, 0, 40, 20],
      notAnswered: [30, 20, 6, 0, 0, 0, 5, 40, 20, 5],
      totalValue: [10, 7, 6, 5, 10, 9, 5, 4, 2, 8],
    },
    accuracy: 0,
    grpAccuracy: 0,
    score: 0,
    grpScore: 0,
    scoreGraph: {
      correct: 25,
      incorrect: 25,
      nearCorrect: 25,
      notAnswered: 25,
      total: 100,
      correctPer: "25%",
      incorrectPer: "25%",
      nearCorrectPer: "25%",
      notAnsweredPer: "25%",
    },
    timeMark: [
      { value: 0, label: "0" },
      { value: 60, label: "60" },
    ],
    time: 0,
    grpTime: 0,
    trendData: [],
  });
  const [trendStartDate, setTrendStartDate] = useState(
    new Date(moment().subtract(7, "days").format("YYYY-MM-DD"))
  );
  const [trendEndDate, setTrendEndDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [performanceHandriseValues, setPerformanceHandriseValues] = useState({
    totalHandrise: 0,
    uniqueStudent: 0,
    avgHandRaisePerMin: 0,
  });
  const initialRender = useRef(false);

  const performanceData = JSON.parse(
    window.localStorage.getItem("performanceData")
  );
  const studyCircleName = performanceData.studyCircleName;
  const calendarSel = performanceData.calendarSel;
  const institutionId = window.localStorage.getItem("cmn_school_id");
  const studyCircleId = window.location.pathname.slice(19);
  const stylesss = {
    monthAct: {
      backgroundColor: "#FD8500",
      color: "#fff",
      fontWeight: 500,
    },
    dayAct: {
      backgroundColor: "#FD8500",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      color: "#fff",
      fontWeight: 500,
    },
    yearAct: {
      backgroundColor: "#FD8500",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
      color: "#fff",
      fontWeight: 500,
    },
  };
  const scores = [
    {
      ranks: [96, 33.4, 11.1, 36.3, 19.2],
    },
    {
      test: [
        "questions",
        "correct",
        "near correct",
        "incorrect",
        "not answered",
      ],
    },
  ];
  let prevDate = performanceData.prevDate;
  let nextDate = performanceData.nextDate;
  let totaldaysInMonth,
    filter = [],
    score_graph_values = {};

  useEffect(() => {
    const fetchInitialValues = async () => {
      try {
        const callAll = await promiseAllCall([
          getModes(),
          getAssessmentType(),
          getSubjectStudy(institutionId, studyCircleId),
          getObjective(),
          sessionwiseHandriseSummary(studyCircleId),
        ]);
        setStates((prevState) => {
          return {
            ...prevState,
            modeOptions: callAll[0]?.data?.data,
            typeOptions: callAll[1]?.data?.data,
            subjectOptions: callAll[2]?.data?.data,
            objectiveOptions: callAll[3]?.data?.data,
          };
        });
        // const perfHandriseVal = await sessionwiseHandriseSummary(studyCircleId);
        const perfHandriseVal = callAll[4];
        // console.log("perfHandriseVal", perfHandriseVal);
        const perfHandriseValObj = await perfHandriseVal?.loadResponses[0]
          ?.data[0];
        // console.log("perfHandriseValObj", perfHandriseValObj);
        if (perfHandriseValObj) {
          setPerformanceHandriseValues((prevState) => {
            return {
              ...prevState,
              totalHandrise: perfHandriseValObj["HandRaise.count"],
              uniqueStudent: perfHandriseValObj["HandRaise.uniqueStudentcount"],
              avgHandRaisePerMin: (+perfHandriseValObj[
                "HandRaise.avgHandRaisePerMin"
              ])?.toFixed(2),
            };
          });
        }
        setIsLoading(!isLoading);
      } catch (err) {
        console.log("err in PerformanceSubjectDetail", err);
        toast.error("Something went wrong, please try again!");
        setIsLoading(!isLoading);
      }
    };
    setIsLoading(!isLoading);
    fetchInitialValues();
  }, []);

  useEffect(() => {
    const fetchDependencies = async () => {
      try {
        const subSubject = await getSubSubject(
          institutionId,
          selectedValues.selectedSubject
        );

        const topic = await getTopic(
          institutionId,
          selectedValues.selectedSubject,
          selectedValues.selectedSubSubject
        );

        const subTopic = await getSubTopic(
          institutionId,
          selectedValues.selectedSubject,
          selectedValues.selectedSubSubject,
          selectedValues.selectedTopic
        );
        setStates((prevState) => {
          return {
            ...prevState,
            subSubjectOptions: subSubject?.data?.data,
            topicOptions: topic?.data?.data,
            subTopicOptions: subTopic?.data?.data,
          };
        });
      } catch (err) {
        console.log("err in fetchDependencies PerformanceSubjectDetails", err);
      }
    };
    fetchDependencies();
  }, [
    selectedValues.selectedSubject,
    selectedValues.selectedSubSubject,
    selectedValues.selectedTopic,
  ]);

  useEffect(() => {
    const getPerformanceTrendData = async () => {
      try {
        const trendData = await fetchPerformanceTrendChart(
          moment(trendStartDate).format("YYYY-MM-DD"),
          moment(trendEndDate).format("YYYY-MM-DD")
        );
        const data = trendData?.loadResponses?.[0]?.data;
        if (!data || data.length === 0) return;
        setCubeData((prevState) => {
          return {
            ...prevState,
            trendData: data,
          };
        });
      } catch (err) {
        console.log("err in getPerformanceTrendData", err);
      }
    };
    getPerformanceTrendData();
  }, [trendStartDate, trendEndDate]);

  useEffect(() => {
    const fetchDropdownCubeDependencies = async () => {
      filter = createPerformanceFilter(
        studyCircleId,
        selectedValues.selectedMode,
        selectedValues.selectedType,
        selectedValues.selectedTopic,
        selectedValues.selectedSubTopic,
        selectedValues.selectedSubject,
        selectedValues.selectedSubSubject,
        selectedValues.selectedObjectives,
        prevDate,
        nextDate
      );
      console.log("filter", filter);
      try {
        const session = await sessionHeaderFetch(filter);
        console.log("session", session);
        setSessionData(session);
        if (session[0]) {
          const sessGrpId = session[0]["Assessment.groupSessionId"];
          setSelectedGroupId(sessGrpId);
          setIsLoading(!isLoading);
        } else {
          setIsLoading(!isLoading);
          toast.error("No data for the date selected");
        }
      } catch (err) {
        console.log("error in fetchDropdownCubeDependencies", err);
      }
    };
    setIsLoading(!isLoading);
    fetchDropdownCubeDependencies();
  }, [
    selectedValues.selectedMode,
    selectedValues.selectedType,
    selectedValues.selectedTopic,
    selectedValues.selectedSubTopic,
    selectedValues.selectedSubject,
    selectedValues.selectedSubSubject,
    selectedValues.selectedObjectives,
    nextDate,
    prevDate,
  ]);

  useEffect(() => {
    const fetchgrpIdDependencies = async () => {
      try {
        let startTime = Date.now();
        const callCubeDependencies = await promiseAllCall([
          fetchAllPerformanceDetails(studyCircleId, selectedGroupId),
          fetchCombinedPerformanceDetails(selectedGroupId),
          getGroupPercentile(),
          difficultySummary(studyCircleId, selectedGroupId),
        ]);
        let endTime = Date.now();
        let timeTaken = endTime - startTime;
        console.log(
          "Total time taken for RA_PERF_11 + RA_PERF_10 - Combine Queries",
          timeTaken
        );
        console.log("callCubeDependencies", callCubeDependencies);
        const time = callCubeDependencies[0]
          ? (+callCubeDependencies[0][
              "AssessmentResponse.avgTimeTaken"
            ]).toFixed(2)
          : 0;
        const time_mark = [
          { value: 0, label: "0" },
          {
            value: Number(
              callCubeDependencies[0]
                ? (+callCubeDependencies[0][
                    "AssessmentResponse.avgTimeTaken"
                  ]).toFixed(2)
                : 0
            ),
            label: String(
              callCubeDependencies[0]
                ? (+callCubeDependencies[0][
                    "AssessmentResponse.avgTimeTaken"
                  ]).toFixed(2)
                : 0
            ),
          },
          { value: 60, label: 60 },
        ];
        score_graph_values["AssessmentResponse.questionsCount"] =
          callCubeDependencies[0]["AssessmentResponse.questionsCount"];
        score_graph_values["AssessmentResponse.totalCorrects"] =
          callCubeDependencies[0]["AssessmentResponse.totalCorrects"];
        score_graph_values["AssessmentResponse.totalIncorrects"] =
          callCubeDependencies[0]["AssessmentResponse.totalIncorrects"];
        score_graph_values["AssessmentResponse.totalNearCorrects"] =
          callCubeDependencies[0]["AssessmentResponse.totalNearCorrects"];
        score_graph_values["AssessmentResponse.totalNotAnswereds"] =
          callCubeDependencies[0]["AssessmentResponse.totalNotAnswereds"];
        // console.log("score_graph_values", score_graph_values);
        const scoreGraph = await performanceScoreGraph(score_graph_values);
        setCubeData((prevState) => {
          return {
            ...prevState,
            readVelocity: callCubeDependencies[0][
              "AssessmentResponse.avgVelocity"
            ]
              ? (+callCubeDependencies[0][
                  "AssessmentResponse.avgVelocity"
                ])?.toFixed(2)
              : 0,
            accuracy: callCubeDependencies[0]["AssessmentResponse.avgAccuracy"]
              ? (+callCubeDependencies[0][
                  "AssessmentResponse.avgAccuracy"
                ]).toFixed(2)
              : 0,
            score: callCubeDependencies[0]["AssessmentResponse.marksScored"]
              ? (+callCubeDependencies[0][
                  "AssessmentResponse.marksScored"
                ]).toFixed(2)
              : 0,
            scoreGraph: scoreGraph,
            time: time,
            timeMark: time_mark,
            grpReadVelocity: callCubeDependencies[1][
              "AssessmentResponse.avgVelocity"
            ]
              ? (+callCubeDependencies[1][
                  "AssessmentResponse.avgVelocity"
                ]).toFixed(2)
              : 0,
            grpAccuracy: callCubeDependencies[1][
              "AssessmentResponse.avgAccuracy"
            ]
              ? (+callCubeDependencies[1][
                  "AssessmentResponse.avgAccuracy"
                ]).toFixed(2)
              : 0,
            grpScore: callCubeDependencies[1][
              "AssessmentResponse.avgMarksScored"
            ]
              ? (+callCubeDependencies[1][
                  "AssessmentResponse.avgMarksScored"
                ]).toFixed(2)
              : 0,
            grpTime: callCubeDependencies[1]["AssessmentResponse.avgTimeTaken"]
              ? (+callCubeDependencies[1][
                  "AssessmentResponse.avgTimeTaken"
                ]).toFixed(2)
              : 0,
            groupPercentile: callCubeDependencies[2],
            difficultySum: callCubeDependencies[3],
          };
        });
        setIsLoading(!isLoading);
      } catch (err) {
        console.log(
          "err in PerformanceSubjectDetails,selectedGroupId useEffect",
          err
        );
        toast.error("Something went wrong in fetching performance data");
        setIsLoading(!isLoading);
      }
    };
    if (initialRender.current) {
      setIsLoading(!isLoading);
      fetchgrpIdDependencies();
    } else {
      initialRender.current = true;
    }
  }, [selectedGroupId]);

  console.log("cubeData", cubeData);
  const getGroupPercentile = async () => {
    const callAll = await promiseAllCall([
      fetchStudyCircleRankForGroup(studyCircleId),
      fetchTotalStudyCircleCount(),
    ]);
    // const result1 = await fetchStudyCircleRankForGroup(studyCircleId);
    const result1 = callAll[0];
    const studyCircleRankForGroup =
      result1?.loadResponses?.[0]?.data?.[0][
        "GroupPerformanceRank.studyCircleGroupRank"
      ];
    // const result2 = await fetchTotalStudyCircleCount();
    const result2 = callAll[1];
    const numStudyCircles =
      result2?.loadResponses?.[0]?.data?.[0]["GroupPerformanceRank.count"];

    let partial = (studyCircleRankForGroup * 1.0) / numStudyCircles;
    let groupPercentile = ((1 - partial) * 100 || 0).toFixed(2);
    return groupPercentile;
  };

  const handleChange = async (date, arrowdate) => {
    setIsLoading(true);
    if (arrowdate === "prev") {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() - 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth
        ? currentdate.getMonth() === 0
          ? 11
          : currentdate.getMonth() - 1
        : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() - 1
        : previousday.getFullYear();
      setStartDate(new Date(year, month, today));
      whichTimeFrame(year, month, today, states);

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? `${year}-${month + 1}-01`
        : states.isYear
        ? `${year}-01-01`
        : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? year + "-" + (month + 1) + "-" + totaldaysInMonth
        : states.isYear
        ? `${year}-12-31`
        : null;
    } else if (arrowdate === "next") {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() + 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth
        ? currentdate.getMonth() === 11
          ? 0
          : currentdate.getMonth() + 1
        : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() + 1
        : previousday.getFullYear();
      setStartDate(new Date(year, month, today));
      whichTimeFrame(year, month, today, states);

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? `${year}-${month + 1}-01`
        : states.isYear
        ? `${year}-01-01`
        : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? year + "-" + (month + 1) + "-" + totaldaysInMonth
        : states.isYear
        ? `${year}-12-31`
        : null;
    } else if (arrowdate === "calander") {
      setStartDate(date);
      let date_val = new Date(date);
      localStorage.setItem("oncalendardate", date_val);
      month = date_val.getMonth();
      today = date_val.getDate();
      year = date_val.getFullYear();

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? `${year}-${month + 1}-01`
        : states.isYear
        ? `${year}-01-01`
        : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? year + "-" + (month + 1) + "-" + totaldaysInMonth
        : states.isYear
        ? `${year}-12-31`
        : null;
    }
    setIsLoading(false);
    // changeMode("e", "date");
  };

  const handleClick = async (values) => {
    setIsLoading(true);
    if (values === "d") {
      prevDate = `${year}-${month + 1}-${today}`;
      nextDate = year + "-" + (month + 1) + "-" + today;
    } else if (values === "m") {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
    } else if (values === "y") {
      prevDate = `${year}-01-01`;
      nextDate = `${year}-12-31`;
    }
    setStates({
      ...states,
      isDay: values === "d" ? true : false,
      isMonth: values === "m" ? true : false,
      isYear: values === "y" ? true : false,
    });
    whichTimeFrame(year, month, today, {
      isDay: values === "d" ? true : false,
      isMonth: values === "m" ? true : false,
      isYear: values === "y" ? true : false,
    });
    setIsLoading(false);
  };

  const changeMode = (e, name) => {
    const selectedVal = e.target.value;
    if (name === "mode") {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedMode: selectedVal };
      });
    } else if (name === "type") {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedType: selectedVal };
      });
    } else if (name === "subject") {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedSubject: selectedVal };
      });
    } else if (name === "subSubject") {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedSubSubject: selectedVal };
      });
    } else if (name === "topic") {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedTopic: selectedVal };
      });
    } else if (name === "subTopic") {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedSubTopic: selectedVal };
      });
    } else if (name === "objectives") {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedObjectives: selectedVal };
      });
    } else return null;
  };

  const handleDateChange = async (date) => {
    // console.log("date", moment(date).format("YYYY-MM-DD"));
    setTrendStartDate(date);
  };

  const handleEndDateChange = async (date) => {
    // console.log("date", moment(date).format("YYYY-MM-DD"));
    setTrendEndDate(date);
  };

  const handleStartDateChange = async (n) => {
    let date = moment(trendStartDate).add(n, "days");
    setTrendStartDate(new Date(date.format("YYYY-MM-DD")));
    let endDate = moment(trendEndDate).add(n, "days");
    setTrendEndDate(new Date(endDate.format("YYYY-MM-DD")));
  };

  return (
    <>
      {isLoading ? <LoadingBars /> : null}
      <PerformanceCalendarSection
        studyCircleName={studyCircleName}
        handleChange={handleChange}
        handleClick={handleClick}
        startdate={startdate}
        states={states}
        stylesss={stylesss}
        styles={styles}
      />
      <div style={{ marginTop: "20px" }}>
        <GridContainer>
          <div className={styles.select_div}>
            <div className={styles.select_div_sel}>
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={"All Mode"}
                modeslabel={"mode"}
                name={"mode"}
                changeMode={changeMode}
                options={states.modeOptions}
                value={selectedValues.selectedMode}
                selectedId={"assessment_mode_id"}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={"All Type"}
                modeslabel={"Type"}
                name={"type"}
                changeMode={changeMode}
                options={states.typeOptions}
                value={selectedValues.selectedType}
                selectedId={"assessment_type_id"}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={"All Subjects"}
                modeslabel={"Subject"}
                name={"subject"}
                changeMode={changeMode}
                options={states.subjectOptions}
                value={selectedValues.selectedSubject}
                selectedId={"subject_id"}
                displayName={"subject"}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={"All Sub-Subject"}
                modeslabel={"Sub Subject"}
                name={"subSubject"}
                changeMode={changeMode}
                options={states.subSubjectOptions}
                value={selectedValues.selectedSubSubject}
                selectedId={"sub_subject_id"}
                displayName={"sub_subject_name"}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={"All Topic"}
                modeslabel={"Topic"}
                name={"topic"}
                changeMode={changeMode}
                options={states.topicOptions}
                value={selectedValues.selectedTopic}
                selectedId={"topic_id"}
                displayName={"topic_name"}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={"All Sub Topic"}
                modeslabel={"Sub Topic"}
                name={"subTopic"}
                changeMode={changeMode}
                options={states.subTopicOptions}
                value={selectedValues.selectedSubTopic}
                selectedId={"sub_topic_id"}
                displayName={"sub_topic_name"}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={"All Objectives"}
                modeslabel={"Objective"}
                name={"objectives"}
                changeMode={changeMode}
                options={states.objectiveOptions}
                value={selectedValues.selectedObjectives}
                selectedId={"objective_id"}
                displayName={"objective"}
              />
            </div>
          </div>
        </GridContainer>
      </div>
      <div style={{ marginTop: "10px", paddingTop: "10px" }}>
        <GridContainer
          style={{
            backgroundColor: "#ffffff",
            boxShadow: "5px 5px 10px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <PerformanceReadVelocityHeader
            styles={styles}
            setSelectedGroupId={setSelectedGroupId}
            studyCircleId={studyCircleId}
            sessionData={sessionData}
            cubeData={cubeData}
          />
          <div className={styles.Acc}>
            <PerformanceDifficultyChart styles={styles} cubeData={cubeData} />
            <PerformanceAccuracySpeedometer
              styles={styles}
              cubeData={cubeData}
            />
          </div>
          <div className={styles.Acc}>
            <PerformanceScoreBar
              styles={styles}
              cubeData={cubeData}
              scores={scores}
            />
            <PerformanceTimeBar
              styles={styles}
              time={cubeData.time}
              groupTime={cubeData.grpTime}
              timeMark={cubeData.timeMark}
            />
          </div>
        </GridContainer>
      </div>
      <div style={{ marginTop: "20px" }}>
        <GridContainer>
          <div className={styles.last_div}>
            <PerformanceTrendSection
              cubeData={cubeData}
              trendStartDate={trendStartDate}
              trendEndDate={trendEndDate}
              handleDateChange={handleDateChange}
              handleEndDateChange={handleEndDateChange}
              handleStartDateChange={handleStartDateChange}
              styles={styles}
            />
            <PerformanceHandRiseSection
              styles={styles}
              performanceHandriseValues={performanceHandriseValues}
            />
          </div>
        </GridContainer>
      </div>
    </>
  );
};

export default PerformanceSubjectDetail;
