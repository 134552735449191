import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { GridContainer } from "../../components/grid/index";
import "./students.css";
import styled from "./StudentStyle.module.css";
import {
  getStudentData,
  getStudentDataRV,
} from "./StudentsData/getStudentsData";
import { fetchTotalStudentsInGroup } from "../../api/cube/studentAPI";
import {
  getAssessmentType,
  getModes,
  getOrganiser,
  getStudyCircle,
  // getSubject,
  getSubSubject,
  getTopic,
  getSubTopic,
  getObjective,
  getSubjectStudy,
} from "../../api/masterReadAnalytics/dropdownAPI";
import { toast } from "react-toastify";
import { filterBuildStudent } from "./filterBuildStudents";
import StudentTable from "./StudentTable/StudentTable";
import LoadingBars from "../../components/LoadingBars/LoadingBars";
import promiseAllCall from "../../utils/promise";
import StudentTableDropdown from "../../components/StudentTableDropdown/StudentTableDropdown";

const Students = (props) => {
  const { updateDisplayText } = props;

  let filter = [];
  let individualStudentDetail = false;
  const [dropdownVals, setDropdownVals] = useState({
    selectedMode: "-1",
    selectedAssessmentType: "-1",
    selectedOrganiser: "-1",
    selectedStudy: "-1",
    selectedTopic: "-1",
    selectedSubTopic: "-1",
    selectedSubject: "-1",
    selectedSubSubject: "-1",
    selectedObjectives: "-1",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState({
    isMonth: true,
    isDay: false,
    isYear: false,
    t_head: [
      "RA#",
      "Name",
      "Study Circle",
      "Attendance",
      "Score",
      "Read Velocity",
      "Study Circle Rank",
      "Group percentile",
      "Trend",
      "Hand Raise",
    ],
    modeOptions: [],
    assessmentOptions: [],
    organiserOptions: [],
    studyOptions: [],
    topicOptions: [],
    subTopicOptions: [],
    subjectOptions: [],
    subSubjectOptions: [],
    objectiveOptions: [],
  });
  const [data, setData] = useState([]);
  const initialRender = useRef(false);
  const institutionId = window.localStorage.getItem("cmn_school_id");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const callAll = await promiseAllCall([
          getModes(),
          getOrganiser(),
          getAssessmentType(),
          getStudyCircle(institutionId),
          getObjective(),
        ]);
        // console.log("callAll", callAll);
        setStates((prevState) => {
          return {
            ...prevState,
            modeOptions: callAll[0]?.data?.data,
            organiserOptions: callAll[1]?.data?.data,
            assessmentOptions: callAll[2]?.data?.data,
            studyOptions: callAll[3]?.data?.data,
            objectiveOptions: callAll[4]?.data?.data,
          };
        });
      } catch (err) {
        console.log("err in fetchInitialData for Students table", err);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchDropdowndependencies = async () => {
      try {
        const subjects = await getSubjectStudy(
          institutionId,
          dropdownVals.selectedStudy
        );
        const subSubjects = await getSubSubject(
          institutionId,
          dropdownVals.selectedSubject
        );
        const topics = await getTopic(
          institutionId,
          dropdownVals.selectedSubject,
          dropdownVals.selectedSubSubject
        );
        const subTopics = await getSubTopic(
          institutionId,
          dropdownVals.selectedSubject,
          dropdownVals.selectedSubSubject,
          dropdownVals.selectedTopic
        );
        // console.log(
        //   "subjects, subSubjects, topics, subTopics",
        //   subjects,
        //   subSubjects,
        //   topics,
        //   subTopics
        // );
        setStates((prevState) => {
          return {
            ...prevState,
            subjectOptions: subjects?.data?.data,
            subSubjectOptions: subSubjects?.data?.data,
            topicOptions: topics?.data?.data,
            subTopicOptions: subTopics?.data?.data,
          };
        });
      } catch (err) {
        console.log("err in fetchDropdowndependencies", err);
        handleError(err);
      }
    };
    if (initialRender.current) {
      fetchDropdowndependencies();
    } else {
      initialRender.current = true;
    }
  }, [
    dropdownVals.selectedStudy,
    dropdownVals.selectedSubject,
    dropdownVals.selectedSubSubject,
    dropdownVals.selectedTopic,
    dropdownVals.selectedSubTopic,
  ]);

  useEffect(() => {
    const fetchStudentTable = async () => {
      filter = filterBuildStudent(
        institutionId,
        dropdownVals.selectedMode,
        dropdownVals.selectedAssessmentType,
        dropdownVals.selectedOrganiser,
        dropdownVals.selectedSubSubject,
        dropdownVals.selectedTopic,
        dropdownVals.selectedStudy,
        dropdownVals.selectedObjectives
      );
      console.log("filter inside fetchStudentTable", filter);
      // let tableData = await getStudentData(filter);
      // console.log("tableData from getStudentData", tableData);
      // let tableDataRV = await getStudentDataRV(filter);
      // console.log("tableData from getStudentDataRV", tableDataRV);
      const callTableAPI = await promiseAllCall([
        getStudentData(filter),
        getStudentDataRV(filter),
        fetchTotalStudentsInGroup({}),
      ]);
      let tableData = calculateTrend(callTableAPI[0], callTableAPI[1]);
      // tableData = calculateTrend(tableData, tableDataRV);
      console.log(
        "tableData from getStudentData after calculateTrend",
        tableData
      );

      let totalNumStudents = callTableAPI[2];

      let processedTableData = await new Promise((resolve, reject) => {
        const getTableData = tableData.map((d) => {
          d.percentile = +(
            (1 -
              ((+d["GroupRank.studentGroupRank"] || 0) * 1.0) /
                totalNumStudents) *
            100
          ).toFixed(2);
          return d;
        });
        resolve(getTableData);
      });
      // return processedTableData;
      setData(processedTableData);
      setIsLoading(false);
      return;
    };
    setIsLoading(true);
    fetchStudentTable();
  }, [
    dropdownVals.selectedMode,
    dropdownVals.selectedAssessmentType,
    dropdownVals.selectedOrganiser,
    dropdownVals.selectedTopic,
    dropdownVals.selectedStudy,
    dropdownVals.selectedObjectives,
    dropdownVals.selectedSubSubject,
  ]);

  // Error handler
  function handleError(error) {
    // console.log("error", error);
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    }
  }

  function calculateTrend(rows, rowsRV) {
    // from getStudentData
    let result = rows.map((v) => {
      let key = v["AssessmentResponse.raStudentGroupKey"];
      let rvs = rowsRV.filter(
        (d) => d["AssessmentResponse.raStudentGroupKey"] === key
      );
      v.trendData = rvs;

      let one = v.trendData.filter(
        (d) => d["AssessmentResponse.rowNumStudentTrend"] == 1
      )[0]?.["AssessmentResponse.avgVelocity"];
      let two = v.trendData.filter(
        (d) => d["AssessmentResponse.rowNumStudentTrend"] == 2
      )[0]?.["AssessmentResponse.avgVelocity"];
      let three = v.trendData.filter(
        (d) => d["AssessmentResponse.rowNumStudentTrend"] == 3
      )[0]?.["AssessmentResponse.avgVelocity"];

      let sum = 0,
        count = 0;
      v.trend = 0;
      if (two) {
        count++;
        sum += two;
      }
      if (three) {
        count++;
        sum += three;
      }
      if (count > 0) {
        let prev = sum / count;
        if (one > prev + 0.01) v.trend = 1;
        else if (one < prev - 0.01) v.trend = -1;
        else v.trend = 0;
      }
      v.one = one;
      v.two = two;
      v.three = three;
      return v;
    });
    console.log("calculateTrend", result);
    return result;
  }

  // Handles dropdown change
  const changeMode = async (e, name) => {
    console.log("e", e.target.value, name);
    if (name === "mode") {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedMode: e.target.value };
      });
    } else if (name === "organiser") {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedOrganiser: e.target.value };
      });
    } else if (name === "assessmentType") {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedAssessmentType: e.target.value };
      });
    } else if (name === "studyCircle") {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedStudy: e.target.value };
      });
    } else if (name === "subject") {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedSubject: e.target.value };
      });
    } else if (name === "subSubject") {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedSubSubject: e.target.value };
      });
    } else if (name === "topic") {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedTopic: e.target.value };
      });
    } else if (name === "subTopic") {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedSubTopic: e.target.value };
      });
    } else if (name === "objective") {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedObjectives: e.target.value };
      });
    }
  };

  // Onclick of student table row
  const tableClick = (rowData) => {
    console.log("rowData", rowData);
    window.localStorage.setItem(
      "studentData",
      JSON.stringify({
        studyCircleId: rowData["AssessmentResponse.studyCircleId"],
        studyCircleName: rowData["StudyCircle.name"],
        rollNumber: rowData["AssessmentResponse.studentId"],
        studentName: rowData["Student.name"],
        assessmentModeId: dropdownVals.selectedMode,
        assessmentTypeId: dropdownVals.selectedAssessmentType,
        organiserId: dropdownVals.selectedOrganiser,
        subjectId: dropdownVals.selectedSubject,
        subSubjectId: dropdownVals.selectedSubSubject,
        topicId: dropdownVals.selectedTopic,
        subTopicId: dropdownVals.selectedSubTopic,
        objectiveId: dropdownVals.selectedObjectives,
        studentProfilePic: rowData["Student.profilePicUrl"],
      })
    );
    return (window.location.href =
      "/admin/students/" + rowData["AssessmentResponse.studentId"]);
  };

  return (
    <>
      {isLoading ? <LoadingBars /> : null}
      <GridContainer>
        <div className={styled.main_box}>
          <div className={styled.top_box}>
            <div className={styled.select_box}>
              <div className={styled.selectBox}>
                {/* <div className={styled.searchIcon}><img src={search_icon} alt="search" /></div> */}
                <StudentTableDropdown
                  modeslabel={"Mode"}
                  name={"mode"}
                  changeMode={changeMode}
                  value={dropdownVals.selectedMode}
                  selected_id={"assessment_mode_id"}
                  displayValue={"name"}
                  defaultName={"All Mode"}
                  options={states.modeOptions}
                  styled={styled}
                />
                <StudentTableDropdown
                  modeslabel={"Organiser"}
                  name={"organiser"}
                  changeMode={changeMode}
                  value={dropdownVals.selectedOrganiser}
                  selected_id={"organiser_type_id"}
                  displayValue={"name"}
                  defaultName={"All Organiser"}
                  options={states.organiserOptions}
                  styled={styled}
                />
                <StudentTableDropdown
                  modeslabel={"Assessment Type"}
                  name={"assessmentType"}
                  changeMode={changeMode}
                  value={dropdownVals.selectedAssessmentType}
                  selected_id={"assessment_type_id"}
                  displayValue={"name"}
                  defaultName={"All Assessment"}
                  options={states.assessmentOptions}
                  styled={styled}
                />
                <StudentTableDropdown
                  modeslabel={"Study Circle"}
                  name={"studyCircle"}
                  changeMode={changeMode}
                  value={dropdownVals.selectedStudy}
                  selected_id={"study_circle_id"}
                  displayValue={"study_circle_name"}
                  defaultName={"All Study Circle"}
                  options={states.studyOptions}
                  styled={styled}
                />
                <StudentTableDropdown
                  modeslabel={"Subject"}
                  name={"subject"}
                  changeMode={changeMode}
                  value={dropdownVals.selectedSubject}
                  selected_id={"subject_id"}
                  displayValue={"subject"}
                  defaultName={"All Subjects"}
                  options={states.subjectOptions}
                  styled={styled}
                />
                <StudentTableDropdown
                  modeslabel={"Sub Subject"}
                  name={"subSubject"}
                  changeMode={changeMode}
                  value={dropdownVals.selectedSubSubject}
                  selected_id={"sub_subject_id"}
                  displayValue={"sub_subject_name"}
                  defaultName={"All Sub-Subjects"}
                  options={states.subSubjectOptions}
                  styled={styled}
                />
                <StudentTableDropdown
                  modeslabel={"Topic"}
                  name={"topic"}
                  changeMode={changeMode}
                  value={dropdownVals.selectedTopic}
                  selected_id={"topic_id"}
                  displayValue={"topic_name"}
                  defaultName={"All Topic"}
                  options={states.topicOptions}
                  styled={styled}
                />
                <StudentTableDropdown
                  modeslabel={"Sub Topic"}
                  name={"subTopic"}
                  changeMode={changeMode}
                  value={dropdownVals.selectedSubTopic}
                  selected_id={"sub_topic_id"}
                  displayValue={"sub_topic_name"}
                  defaultName={"All Sub Topic"}
                  options={states.subTopicOptions}
                  styled={styled}
                />
                <StudentTableDropdown
                  modeslabel={"Objective"}
                  name={"objective"}
                  changeMode={changeMode}
                  value={dropdownVals.selectedObjectives}
                  selected_id={"objective_id"}
                  displayValue={"objective"}
                  defaultName={"All Objectives"}
                  options={states.objectiveOptions}
                  styled={styled}
                />
              </div>
            </div>
            {/* <div className={styled.calSec}>{calandarSection()}</div> */}
          </div>
          <div>
            <div className={styled.student_length}>
              Total Records - {data.length}
            </div>
            <StudentTable
              states={states}
              studentData={data}
              tableClick={tableClick}
            />
          </div>
        </div>
      </GridContainer>

      {/* {tableClick ? <StudentDetails /> : false } */}
    </>
  );
};

export default Students;
