import React, { useState } from "react"
import "./HandRaiseChart.css"
import Modal from "react-modal";
import HandRaiseBarGraph from "../HandRaiseBarGraph/HandRaiseBarGraph"
import HandRaiseBarDetail from "../HandRaiseBarDetail/HandRaiseBarDetail";
import handRaise from "../../assets/images/handRise.png"

const customStyles = {
    content: {
        position: "relative",
        top: "22%",
        left: "52%",
        display: "inline-block",
        minWidth: "auto",
        maxWidth: "1000px",
        height: "520px",
        borderRadius: "4px",
        transform: "translate(-50%, -13%)",
        padding: "5px",
        WebkitOverflowScrolling: "touch",
        boxShadow: "5px 5px 10px 0px rgba(51, 51, 51, 0.05)",
        background: "#E9EAEE"
    },
    overlay: {
        zIndex: "2",
        background: "rgba(128, 128, 128, 0.5)"
    },
};
Modal.setAppElement("#root");

const HandRaiseChart = (props) => {
    const { handRaiseChartOpen, handleHandRaiseChartOpen } = props

    const [chartOpenClose, setChartOpenClose] = useState(false)
    const chartData = [
        { question_id: 1, number_of_handraise: 18 },
        { question_id: 2, number_of_handraise: 47 },
        { question_id: 3, number_of_handraise: 6 },
        { question_id: 4, number_of_handraise: 83 },
        { question_id: 5, number_of_handraise: 69 },
        { question_id: 6, number_of_handraise: 37 },
        { question_id: 7, number_of_handraise: 78 },
        { question_id: 8, number_of_handraise: 68 },
        { question_id: 9, number_of_handraise: 58 },
        { question_id: 10, number_of_handraise: 48 },
        { question_id: 11, number_of_handraise: 38 },
        { question_id: 12, number_of_handraise: 28 },
        { question_id: 13, number_of_handraise: 18 },
    ]
    const width = 140 * chartData?.length + 100

    const toggleChartOpenClose = () => {
        setChartOpenClose(!chartOpenClose)
        handleHandRaiseChartOpen()
    }

    return (
        <>
            <Modal
                isOpen={handRaiseChartOpen}
                onRequestClose={toggleChartOpenClose}
                style={customStyles}
                contentLabel="Handraise Table"
            >
                <div className="handraise-bar" style={{ width: "96%", height: "80%", padding: "0 20px" }}>
                    <div style={{ height: "100%", width: `98%`, overflowX: "scroll", overflowY: "hidden" }}>
                        <HandRaiseBarGraph chartData={chartData} xLineLength={width} />
                    </div>
                </div>
                <div className="handraise-detail-box" style={{ height: "17%", width: "100%" }}>
                    <div className="handraise-detail-box__img">
                        <img src={handRaise} className="handraise-img" />
                    </div>
                    <HandRaiseBarDetail text={"Raise Hand"} num={"149"} />
                    <HandRaiseBarDetail text={"Unique Students"} num={"12"} />
                    <HandRaiseBarDetail text={"Avg. per min."} num={"8"} />
                </div>
            </Modal>
        </>
    )
}

export default HandRaiseChart
