import React, { useState } from "react"
import { GridItem } from "../grid/index";
import PerformanceDetailsCarousel from "../PerformanceDetailsCarousel/PerformanceDetailsCarousel";
import { greenArrow, orangeArrow, gause } from "../../pages/Performance/Images/Images"

const PerformanceReadVelocityHeader = (props) => {
    const { styles, setSelectedGroupId, studyCircleId, sessionData, cubeData } = props
    const [count, setCount] = useState(0)
    console.log("sessionData", sessionData, "cubeData", cubeData)
    return (
        <>
            <div className={styles.left}>
                <GridItem>
                    <div
                        className={styles.readVelocity1}
                        style={{ paddingBottom: "5px" }}
                    >
                        <div className={styles.readVelocity_left_div1}>
                            {sessionData.length !== 0
                                ? sessionData[count]["Topic.name"]
                                    ? sessionData[count]["Topic.name"]
                                    : "No Title"
                                : "No Title"}
                        </div>
                        <PerformanceDetailsCarousel sessionData={sessionData} styles={styles} count={count} setSelectedGroupId={setSelectedGroupId} setCount={setCount} studyCircleId={studyCircleId} />
                    </div>
                </GridItem>
            </div>
            <div className={styles.left}>
                <div className={styles.readVelocity}>
                    <div className={styles.readVelocity_left_div}>
                        <div className={styles.readVelocity_left_div_img}>
                            <img src={gause} alt="gause" />
                        </div>
                        <div className={styles.readVelocity_left_div_txt}>
                            READ VELOCITY
                        </div>
                        <div className={styles.readVelocity_left_div_rank}>
                            <span>{`${cubeData.readVelocity}`}</span>
                            <span style={{ paddingLeft: "10px" }}>
                                {+cubeData.readVelocity >= +cubeData.grpReadVelocity ? (
                                    <img src={greenArrow} alt="green_arrow" />
                                ) : (
                                    <img src={orangeArrow} alt="orange arrow" />
                                )}
                            </span>
                        </div>
                        <div className={styles.readVelocity_left_div_avg}>
                            <div className={styles.readVelocity_left_div_avg_mark}>
                                group avg.
                            </div>
                            <div
                                className={styles.readVelocity_left_div_avg_point}
                            >{`${cubeData.grpReadVelocity}`}</div>
                        </div>
                    </div>
                    {/* <div>{"View Session Performances"}</div> */}
                    <div className={styles.readVelocity_right_div}>
                        {/* <div>{"View Session Performances"}</div> */}
                        <div className={styles.readVelocity_right_div_ranking}>
                            <div className={styles.readVelocity_right_div_ranking_rank}>
                                Rank (Group)
                            </div>
                            <div
                                className={styles.readVelocity_right_div_ranking_percent}
                            >
                                percentile
                            </div>
                        </div>
                        <div className={styles.readVelocity_right_point}>
                            {cubeData.groupPercentile}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PerformanceReadVelocityHeader