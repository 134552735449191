export const sortSession = (value, key, states, setStates) => {
  console.log(key);
  switch (key) {
    case "Date":
      if (value) {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body.sort((a, b) =>
                a["AssessmentResponse.assessmentDate"] >
                  b["AssessmentResponse.assessmentDate"]
                  ? 1
                  : -1
              ),
            ],
          };
        });
      } else {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body
                .sort((a, b) =>
                  a["AssessmentResponse.assessmentDate"] >
                    b["AssessmentResponse.assessmentDate"]
                    ? 1
                    : -1
                )
                .reverse(),
            ],
          };
        });
      }
    case "Group Session #":
      if (value) {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body.sort((a, b) =>
                a["GroupSession.code"].toUpperCase() >
                  b["GroupSession.code"].toUpperCase()
                  ? 1
                  : -1
              ),
            ],
          };
        });
      } else {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body
                .sort((a, b) =>
                  a["GroupSession.code"].toUpperCase() >
                    b["GroupSession.code"].toUpperCase()
                    ? 1
                    : -1
                )
                .reverse(),
            ],
          };
        });
      }
    case "Title":
      if (value) {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body.sort((a, b) =>
                a["Assessment.title"].toUpperCase() >
                  b["Assessment.title"].toUpperCase()
                  ? 1
                  : -1
              ),
            ],
          };
        });
      } else {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body
                .sort((a, b) =>
                  a["Assessment.title"].toUpperCase() >
                    b["Assessment.title"].toUpperCase()
                    ? 1
                    : -1
                )
                .reverse(),
            ],
          };
        });
      }
    case "Type":
      if (value) {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body.sort((a, b) =>
                a["AssessmentType.name"].toUpperCase() >
                  b["AssessmentType.name"].toUpperCase()
                  ? 1
                  : -1
              ),
            ],
          };
        });
      } else {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body
                .sort((a, b) =>
                  a["AssessmentType.name"].toUpperCase() >
                    b["AssessmentType.name"].toUpperCase()
                    ? 1
                    : -1
                )
                .reverse(),
            ],
          };
        });
      }
    case "Organizer":
      if (value) {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body.sort((a, b) =>
                a["AssessmentOrganizer.name"].toUpperCase() >
                  b["AssessmentOrganizer.name"].toUpperCase()
                  ? 1
                  : -1
              ),
            ],
          };
        });
      } else {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body
                .sort((a, b) =>
                  a["AssessmentOrganizer.name"].toUpperCase() >
                    b["AssessmentOrganizer.name"].toUpperCase()
                    ? 1
                    : -1
                )
                .reverse(),
            ],
          };
        });
      }
    case "Study Circle":
      if (value) {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body.sort((a, b) =>
                a["StudyCircle.name"].toUpperCase() >
                  b["StudyCircle.name"].toUpperCase()
                  ? 1
                  : -1
              ),
            ],
          };
        });
      } else {
        return setStates((prevState) => {
          return {
            ...prevState,
            t_body: [
              ...states.t_body
                .sort((a, b) =>
                  a["StudyCircle.name"].toUpperCase() >
                    b["StudyCircle.name"].toUpperCase()
                    ? 1
                    : -1
                )
                .reverse(),
            ],
          };
        });
      }
    default:
      return setStates((prevState) => {
        return { ...prevState, t_body: [...states.t_body] };
      });
  }
};