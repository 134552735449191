import { CUBEJS_API } from "../../config/Api";
import cubejs from "@cubejs-client/core";
import { toast } from "react-toastify";

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem("cmn_cubeJs_token");
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(error) {
  console.error(error);
  if (error?.response?.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

//Reference - RA-ASSESS-15 Trend graph by student_id
export async function fetchStudentTrendChart(student_id, startDate, endDate) {
  const cubeJsApi = cubeFunction();
  const qScore = {
    measures: [
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.avgScore",
      "AssessmentResponse.avgAccuracy",
      "AssessmentResponse.attendancePresent",
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: [
      ["AssessmentResponse.avgVelocity", "desc"],
      ["GroupRank.studentGroupRank", "asc"],
    ],
    dimensions: [
      "AssessmentResponse.assessmentDate",
      "GroupRank.studentGroupRank",
    ],
    filters: [
      {
        member: "AssessmentResponse.studentId",
        operator: "equals",
        values: [`${student_id}`],
      },
      {
        member: "AssessmentResponse.assessmentDate",
        operator: "inDateRange",
        values: [startDate, endDate],
      },
    ],
  };

  try {
    // const result = await cubeJsApi.load(qScore);
    // console.log("result inside cube query fetchStudentTrendChart", result);
    let startTime = Date.now()
    const result = await cubeJsApi.load(qScore);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchStudentTrendChart", timeTaken)
    return result?.loadResponses[0]?.data;
  } catch (error) {
    handleError(error);
  }
}
