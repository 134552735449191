import React from "react"
import "../../pages/Performance/index.css"

const PerformanceTableDropdown = (props) => {
    const { styles, modeslabel, name, defaultOption, id_name, selected_id, value, changeMode, options } = props

    return (
        <span className="select1">
            <div className={styles.modeslabel}>{modeslabel}</div>
            <select
                name={name}
                id={name}
                onChange={(e) => changeMode(e, name)}
                value={value}
            >
                <option value="-1">{defaultOption}</option>
                {options?.map((el, key) => (
                    <option value={el[selected_id]} key={key}>
                        {el[id_name]}
                    </option>
                ))}
            </select>
        </span>
    )
}

export default PerformanceTableDropdown