export const filterBuildStudent = (
    institutionId,
    selectedMode,
    selectedAssessmentType,
    selectedOrganiser,
    selectedSubject,
    selectedTopic,
    studyCircleId,
    selectedObjectives,
) => {
    let filter = []
    if (institutionId !== "-1") {
        filter.push({
            member: "AssessmentResponse.institutionId",
            operator: "equals",
            values: [institutionId],
        });
    }
    if (+selectedMode !== -1) {
        filter.push({
            member: "Assessment.assessmentModeId",
            operator: "equals",
            values: [selectedMode],
        });
    }
    if (+selectedAssessmentType !== -1) {
        filter.push({
            member: "Assessment.assessmentTypeId",
            operator: "equals",
            values: [selectedAssessmentType],
        });
    }
    if (+selectedOrganiser !== -1) {
        filter.push({
            member: "Assessment.organizerTypeId",
            operator: "equals",
            values: [selectedOrganiser],
        });
    }
    if (+selectedSubject !== -1) {
        filter.push({
            member: "Assessment.subjectId",
            operator: "equals",
            values: [selectedSubject],
        });
    }
    if (+selectedTopic !== -1) {
        filter.push({
            member: "Assessment.topicId",
            operator: "equals",
            values: [selectedTopic],
        });
    }
    if (+studyCircleId !== -1) {
        filter.push({
            member: "AssessmentResponse.studyCircleId",
            operator: "equals",
            values: [studyCircleId],
        });
    }
    if (+selectedObjectives !== -1) {
        filter.push({
            member: "Objective.id",
            operator: "equals",
            values: [selectedObjectives],
        });
    }
    console.log("filter inside CreateFilter individualStudentDetail", filter)
    return filter;
}
/* 
"Assessment.assessmentModeId",
    "Assessment.assessmentTypeId",
    "Assessment.organizerTypeId",
    "Assessment.subjectId",
    "Assessment.topicId",
    “AssessmentResponse.studyCircleId”,
      "Objective.id", */
