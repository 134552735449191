import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./adminStyle";
import "react-toastify/dist/ReactToastify.css";
// core components
import Navbar from "../../components/navbar/NavBar";
import Sidebar from "../../components/sidebar/SideBar";
//routing of contents
import routes from "../../routes/routes";
import RouteParent from "../../components/RouteParent/RouteParent";
import Logo from "../../assets/images/left-sidebar-icons/logo.png";
import headerImg from "../../assets/images/college_icon.png";
import sidebarHeader from "../../assets/images/profile.png";

const useStyles = makeStyles(styles);
export default function Admin({ ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const schoolLogo = window.localStorage.getItem("schoolEmblem");
  const schoolName = window.localStorage.getItem("schoolName");
  const schoolLocation = window.localStorage.getItem("schoolLocation");

  React.useEffect(() => {
    document.body.style.overflowY = "auto";
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== null;
  };

  const updateDisplayText = (text) => {
    setDisplayText(text);
  };

  if (window.localStorage.getItem("token") === null) {
    return <Redirect to="/" />;
  }

  return (
    <div className="wrapper">
      <Sidebar
        routes={routes}
        avatar={sidebarHeader}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        lecture={window.localStorage.getItem("cmn_user_full_name")}
        occupation={window.localStorage.getItem("role")}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          displayText={displayText}
          headerImg={headerImg}
          dashboardheader={schoolLogo}
          Logo={Logo}
          logoText={"Read Analytics"}
          dashboardheaderTextTop={
            schoolName === "undefined" || schoolName === "null"
              ? ""
              : schoolName
          }
          dashboardheaderTextBottom={
            schoolLocation === "undefined" || schoolName === "null"
              ? ""
              : schoolLocation
          }
          {...rest}
        />
        {getRoute() ? (
          <div
            className={
              window.location.href === "http://localhost:3000/admin/reports"
                ? classes.reportContent
                : classes.content
            }
          >
            <div className={classes.container}>
              <RouteParent updateDisplayText={updateDisplayText} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
