import https from "../../init/https";

//Referance: RA-ASMT-01 Assessment details of session details screen
export async function getAssessmentDetails(assessmentId, studyCircleId) {
  console.log("assessmentId, studyCircleId", assessmentId, studyCircleId);
  const assessmentDetails = await https.get(
    `/assessment/assessments?assessment_id=${assessmentId}&study_circle_id=${studyCircleId}`
  );
  console.log("RA-ASMT-01 assessmentDetails", assessmentDetails);
  if (assessmentDetails?.data?.data) {
    return assessmentDetails.data.data;
  } else {
    return new Error("No data found");
  }
}

//Referance - RA-ASMT-02 - Question details of session details page
export async function getQuestionDetails(questionId) {
  try {
    const questionDetails = await https.get(
      `/assessment/questions?question_id=${questionId}`
    );
    // console.log("questionDetails", questionDetails);
    if (questionDetails.data.data) {
      return questionDetails.data.data;
    } else {
      return {
        access_level: "",
        access_level_id: -1,
        added_by_user_id: -1,
        added_by_user_name: "",
        answer_json: "",
        difficulty_level: "",
        image_path: "",
        objective: "",
        objective_id: -1,
        question_id: questionId,
        question_type: "",
        question_type_id: -1,
      };
    }
  } catch (error) {
    console.log("err in getQuestionDetails", error)
    if (error?.response?.data) {
      return error.respone.data.message;
    } else {
      return error;
    }
  }
}

//Referance - RA-ASMT-03 - Get Group Institution Id for given Study Circle
export async function getGroupInstitutionId(studyCircleId) {
  try {
    const grpDetails = await https.get(
      `/assessment/group_institution?study_circle_id=${studyCircleId}`
    );
    console.log("grpDetails.data.data", grpDetails.data.data);
    return grpDetails?.data?.data;
  } catch (error) {
    if (error.response.data) {
      console.log(error.respone.data.message);
      return error.respone.data.message;
    } else {
      return error;
    }
  }
}

// RA-ASMT-05: Get the list of Group Session and assessments  for given topic
export const getGrpSessionList = async (topicId, institutionId) => {
  // console.log("topicId, institutionId", topicId, institutionId)
  try {
    const sessionList = await https.get(
      `/assessment/topics?topic_id=${topicId}&institution_id=${institutionId}`
    );
    console.log("sessionList", sessionList);
    // console.log("sessionList", sessionList.data?.data);
    return sessionList?.data?.data;
  } catch (error) {
    if (error.response.data) {
      console.log("error in RA-ASMT-05", error.respone.data.message);
      return error.respone.data.message;
    } else {
      return error;
    }
  }
};
