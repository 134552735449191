import { CUBEJS_API } from "../../config/Api";
import cubejs from "@cubejs-client/core";
import { toast } from "react-toastify";

function cubeFunction() {
  const cubeJsToken = window.localStorage.getItem("cmn_cubeJs_token");
  const cubejsApi = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsApi;
}
/* const startTime = Date.now()
    const result = await cubejsApi.load(qSummaryTwo);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getStudentWiseSummaryTwoAPI", timeTaken) */
//Reference: RA-ASSESS-02 - Cube API to fetch session/assessment rows
export async function getSessionRowsAPI(filter) {
  console.log("filter for getSessionRowsAPI", filter)
  var cubejsApi = cubeFunction();
  const q = {
    dimensions: [
      "AssessmentResponse.assessmentId",
      "AssessmentResponse.assessmentDate",
      "GroupSession.code",
      "Topic.name",
      "AssessmentType.name",
      "AssessmentOrganizer.name",
      "StudyCircle.name",
      "StudyCircle.id",
      "Assessment.groupSessionId"
    ],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: {
      "AssessmentResponse.assessmentDate": "asc",
      "GroupSession.code": "asc",
    },
    filters: filter,
    measures: [
      "AssessmentResponse.attendanceTotal",
      "AssessmentResponse.attendancePresent",
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNotAnswereds",
      "HandRaise.count"
    ],
  };
  try {
    const startTime = Date.now()
    const result = await cubejsApi.load(q);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getSessionRowsAPI", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-ASSESS-06-01 - Answer details of session details page
export async function getAnswerDetailsAPI(
  assessmentId,
  questionId,
  institutionId
) {
  var cubejsApi = cubeFunction();
  const questionIdString = questionId.toString();
  const instId = institutionId.toString();
  const qAnswerDetails = {
    dimensions: ["AssessmentResponse.questionId"],
    filters: [
      {
        member: "AssessmentResponse.assessmentId",
        operator: "equals",
        values: [assessmentId],
      },
      {
        member: "AssessmentResponse.questionId",
        operator: "equals",
        values: [questionIdString],
      },
      {
        member: "Institution.id",
        operator: "equals",
        values: [instId],
      },
    ],
    measures: [
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalNotAnswereds",
    ],
  };
  try {
    const result = await cubejsApi.load(qAnswerDetails);
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-ASSESS-06-02 Answer details of session details page
export async function getGrpAnswerDetailsAPI(
  assessmentId,
  questionId,
  groupInstitutionID
) {
  var cubejsApi = cubeFunction();
  const questionIdString = questionId.toString();
  const grpIdString = groupInstitutionID.toString();
  const qGrpAnswerDetails = {
    dimensions: ["AssessmentResponse.questionId"],
    filters: [
      {
        member: "AssessmentResponse.assessmentId",
        operator: "equals",
        values: [assessmentId],
      },
      {
        member: "AssessmentResponse.questionId",
        operator: "equals",
        values: [questionIdString],
      },
      {
        member: "Assessment.groupInstitutionId",
        operator: "equals",
        values: [grpIdString],
      },
    ],
    measures: [
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalNotAnswereds",
    ],
  };
  try {
    const result = await cubejsApi.load(qGrpAnswerDetails);
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-ASSESS-04 - Question bar session/assessment details page
export async function getQuestionBarAPI(assessmentId, institutionId) {
  var cubejsApi = cubeFunction();
  const instId = institutionId.toString();
  const qQuestionBar = {
    dimensions: ["AssessmentResponse.questionId"],
    filters: [
      {
        member: "AssessmentResponse.assessmentId",
        operator: "equals",
        values: [assessmentId],
      },
      {
        member: "Institution.id",
        operator: "equals",
        values: [instId],
      },
    ],
    measures: [
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalNotAnswereds",
    ],
  };
  try {
    const startTime = Date.now()
    const result = await cubejsApi.load(qQuestionBar);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getQuestionBarAPI", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-ASSESS-03-01 session/assessment read velocity
export async function getReadVelocitySessionAPI(assessmentId, institutionId) {
  var cubejsApi = cubeFunction();
  const instId = institutionId.toString();
  const q = {
    measures: ["AssessmentResponse.avgVelocity", "HandRaise.count"],
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: {
      "AssessmentResponse.assessmentDate": "asc",
    },
    filters: [
      {
        member: "AssessmentResponse.assessmentId",
        operator: "equals",
        values: [assessmentId],
      },
      {
        member: "Institution.id",
        operator: "equals",
        values: [instId],
      },
    ],
  };
  try {
    const startTime = Date.now()
    const result = await cubejsApi.load(q);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getReadVelocitySessionAPI", timeTaken);
    return result?.loadResponse?.results[0]?.data[0];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-ASSESS-03-02 session/assessment read velocity
export async function getReadVelocityTopicAPI(topicId, institutionId) {
  var cubejsApi = cubeFunction();
  const topicIdString = topicId.toString();
  const instId = institutionId.toString();
  // console.log(topicId, topicIdString);
  const qReadVelocityTopic = {
    measures: ["AssessmentResponse.avgVelocity"],
    order: {
      "AssessmentResponse.assessmentDate": "asc",
    },
    filters: [
      {
        member: "Assessment.topicId",
        operator: "equals",
        values: [topicIdString],
        // values: ["1367"],
      },
      {
        member: "Institution.id",
        operator: "equals",
        values: [instId],
      },
    ],
  };
  try {
    const startTime = Date.now()
    const result = await cubejsApi.load(qReadVelocityTopic);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getReadVelocityTopicAPI", timeTaken)
    // console.log("result inside ASSESS-03-02 topicwise", result);
    return result?.loadResponse?.results[0]?.data[0][
      "AssessmentResponse.avgVelocity"
    ];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-ASSESS-07 - Student wise details of session/assessment details page
export async function getStudentWiseResultAPI(
  assessmentId,
  questionId,
  institutionId
) {
  var cubejsApi = cubeFunction();
  var questionIdString = questionId.toString();
  const instId = institutionId.toString();
  const qStudentWiseResult = {
    filters: [
      {
        member: "AssessmentResponse.assessmentId",
        operator: "equals",
        values: [assessmentId],
      },
      {
        member: "AssessmentResponse.questionId",
        operator: "equals",
        values: [questionIdString],
      },
      {
        member: "Institution.id",
        operator: "equals",
        values: [instId],
      },
      {
        member: "Student.name",
        operator: "set",
      },
    ],
    dimensions: [
      "AssessmentResponse.result",
      "AssessmentResponse.response",
      "AssessmentResponse.studentId",
      "AssessmentResponse.timeTaken",
      "Student.name",
      "Student.profilePicUrl",
    ],
    order: [["AssessmentResponse.result", "asc"]],
  };
  try {
    const startTime = Date.now()
    const result = await cubejsApi.load(qStudentWiseResult);
    // console.log("result for getStudentWiseResultAPI", result)
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getStudentWiseResultAPI", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-ASSESS-08-01 - Student summary session/assessment details page
export async function getStudentWiseSummaryOneAPI(
  assessmentId,
  institutionId,
  studyCircleId
) {
  var cubejsApi = cubeFunction();
  const instId = institutionId.toString();
  const studyId = studyCircleId.toString();
  const qSummaryOne = {
    filters: [
      {
        member: "AssessmentResponse.assessmentId",
        operator: "equals",
        values: [assessmentId],
      },
      {
        member: "AssessmentResponse.result",
        operator: "notEquals",
        values: ["absent"],
      },
      {
        member: "Institution.id",
        operator: "equals",
        values: [instId],
      },
      {
        member: "Student.name",
        operator: "set",
      },

      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyId],
      },
    ],
    dimensions: [
      "AssessmentResponse.studentId",
      "Student.name",
      "Student.profilePicUrl",
      "StudyCircleStudent.rollNumber",
      "StudentHandRaise.handRaise"
    ],
    order: {
      "AssessmentResponse.avgVelocity": "desc",
    },
    measures: ["AssessmentResponse.avgVelocity"],
  };
  try {
    const startTime = Date.now()
    const result = await cubejsApi.load(qSummaryOne);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getStudentWiseSummaryOneAPI", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-ASSESS-08-01-02 - Student summary session/assessment details page
export async function getStudentWiseSummaryTwoAPI(
  assessmentId,
  institutionId,
  studyCircleId
) {
  var cubejsApi = cubeFunction();
  const instId = institutionId.toString();
  const studyId = studyCircleId.toString();
  const qSummaryTwo = {
    filters: [
      {
        member: "AssessmentResponse.assessmentId",
        operator: "equals",
        values: [assessmentId],
      },
      {
        member: "AssessmentResponse.result",
        operator: "notEquals",
        values: ["absent"],
      },
      {
        member: "Institution.id",
        operator: "equals",
        values: [instId],
      },
      {
        member: "Student.name",
        operator: "set",
      },

      {
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [studyId],
      },
    ],
    dimensions: [
      "AssessmentResponse.studentId",
      "AssessmentResponse.questionId",
      "AssessmentResponse.result",
    ],
    order: [
      ["AssessmentResponse.studentId", "asc"],
      ["AssessmentResponse.questionId", "asc"],
    ],
  };
  try {
    const startTime = Date.now()
    const result = await cubejsApi.load(qSummaryTwo);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getStudentWiseSummaryTwoAPI", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-ASSESS-08-01-03 - Student summary session/assessment details page
export async function getStudentWiseSummaryThreeAPI(topicId, studentIdArray) {
  var cubejsApi = cubeFunction();
  var topicIdString = topicId.toString();
  const qSummaryThree = {
    filters: [
      {
        member: "AssessmentResponse.result",
        operator: "notEquals",
        values: ["absent"],
      },
      {
        member: "Assessment.topicId",
        operator: "equals",
        values: [topicIdString],
      },
      {
        member: "AssessmentResponse.studentId",
        operator: "equals",
        values: [...studentIdArray],
      },
    ],
    dimensions: ["AssessmentResponse.studentId"],
    order: {
      "AssessmentResponse.avgVelocity": "desc",
    },
    measures: ["AssessmentResponse.avgVelocity"],
  };
  try {
    const startTime = Date.now()
    const result = await cubejsApi.load(qSummaryThree);
    const endTime = Date.now()
    const timeTaken = endTime - startTime
    console.log("Time taken for getStudentWiseSummaryThreeAPI", timeTaken)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}
