import React, { useState } from "react"
import "./HandRisePopup.css"
import Modal from "react-modal";
import close from "../../assets/images/close-sign.png"
import moment from "moment";
import HandRaiseChart from "../HandRaiseChart/HandRaiseChart"

const customStyles = {
    content: {
        position: "relative",
        top: "22%",
        left: "52%",
        display: "inline-block",
        width: "auto",
        maxWidth: "700px",
        height: "auto",
        maxHeight: "420px",
        background: "#FFFFFF",
        borderRadius: "4px",
        transform: "translate(-50%, -5%)",
        // overflow: "auto",
        padding: "5px",
        WebkitOverflowScrolling: "touch",
        boxShadow: "5px 5px 10px 0px rgba(51, 51, 51, 0.05)"
    },
    overlay: {
        zIndex: "1",
        background: "rgba(128, 128, 128, 0.5)"
    },
};
Modal.setAppElement("#root");

const HandRisePopup = (props) => {
    const { handleModalOpen, modalOpen, handRiseData } = props

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [handRaiseChartOpen, setHandRaiseChartOpen] = useState(false)

    const modalOpenClose = async () => {
        setModalIsOpen(!modalIsOpen);
        handleModalOpen();
    };

    const handleCancel = () => {
        modalOpenClose();
    };

    const handleHandRaiseChartOpen = () => {
        setHandRaiseChartOpen(!handRaiseChartOpen)
    }

    // console.log("handRiseData", handRiseData)
    return (
        <>
            <Modal
                isOpen={modalOpen}
                onRequestClose={modalOpenClose}
                style={customStyles}
                contentLabel="Handraise Table"
            >
                <div className="handrise-popup">
                    <div className="handrise-popup-header">
                        <div className="handrise-popup-header__text">Attendence - Hand Rise Details</div>
                        <img
                            src={close}
                            alt="close button"
                            onClick={handleCancel}
                            style={{ cursor: "pointer", marginRight: "12px" }}
                        />
                    </div>
                    <table className="handrise-popup__table">
                        <thead>
                            <tr>
                                <td>Session #</td>
                                <td>Date</td>
                                <td>Topic Name</td>
                                <td>Hand Rise</td>
                                <td>Timeline</td>
                            </tr>
                        </thead>

                        <tbody>
                            {handRiseData?.length > 0 ?
                                (handRiseData.map((ele) => {
                                    const date = moment(ele["HandRaise.assessmentDate"]).format("Do MMM YYYY")
                                    return (
                                        <tr key={ele["HandRaise.assessmentId"]}>
                                            <td>{ele["GroupSession.code"]}</td>
                                            <td>{date}</td>
                                            <td>{ele["Topic.name"]}</td>
                                            <td>{ele["HandRaise.count"]}</td>
                                            <td
                                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                                onClick={handleHandRaiseChartOpen}
                                            >{"View"}
                                            </td>
                                        </tr>
                                    )
                                })) :
                                <tr>
                                    <td>{"No data found"}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Modal>
            {handRaiseChartOpen &&
                <HandRaiseChart
                    handRaiseChartOpen={handRaiseChartOpen}
                    handleHandRaiseChartOpen={handleHandRaiseChartOpen}
                />
            }
        </>
    )
}

export default HandRisePopup