import s3 from "../config/DigitalOcean";
import configuration from "../config/Config";
// import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import axios from "axios";
import fileDownload from "js-file-download";

const UploadPDFFile = (filepath, file) => {
  console.log("uploading", filepath, file, configuration);

  const params = {
    Body: file,
    Bucket: `${configuration.bucketName}`,
    Key: filepath,
    ACL: "public-read"
  };
  console.log("params", params)
  s3.upload(params, (err, data) => {
    console.log("Successfully updloaded file", "err", err, "data", data)
  })
};

const DownloadPDFFile = async (filepath, filename) => {
  const fullpath = configuration.spaces + filepath + "/" + filename;
  // saveAs(fullpath, filename);
  // console.log("successfully saved file", fullpath, filename);
  // let exists;
  // try {
  //   const params = {
  //     Bucket: `${configuration.bucketName}`,
  //     Key: filepath + "/" + filename,
  //   };
  //   console.log("params", params);
  //   exists = await s3.headObject(params).promise();
  //   console.log("report exists", exists);
  // } catch (err) {
  //   console.log("err", err);
  //   if (err.name === "NotFound") {
  //     console.log("report not found", err.message);
  //   }
  //   throw err;
  // }

  // try {
  //   console.log("fetch", configuration.spaces + filepath + "/" + filename);
  //   await fetch(configuration.spaces + filepath + "/" + filename, {
  //     method: "GET",
  //     type: "application/pdf",
  //   })
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       const fileURL = window.URL.createObjectURL(blob);
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       alink.download = filename;
  //       console.log("clicking on link");
  //       alink.click();
  //     })
  //     .catch((err) => {
  //       console.log("fetch err", err);
  //       throw err;
  //     });

  //   console.log("fetch: successfully downloaded file");
  //   return true;
  // } catch (err) {
  //   console.error("fetch", err);
  //   throw err;
  // }

  try {
    console.log("downloading", configuration.spaces, filepath, filename);
    await axios
      .get(configuration.spaces + filepath + "/" + filename, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
        console.log("successfully downloaded");
      });
  } catch (err) {
    console.log("failed downloading", filepath, err);
    return false;
  }
  return true;
};

export { UploadPDFFile, DownloadPDFFile };
