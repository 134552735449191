import React from "react"
import PerformanceCalender from "../PerformanceCalendar/PerformanceCalendar"
import { GridContainer } from "../grid/index";

const PerformanceCalendarSection = (props) => {
    const { studyCircleName, handleChange, handleClick, startdate, states, stylesss, styles } = props

    return (
        <div className={styles.top}>
            <GridContainer>
                <div className={styles.box_1}>
                    <div className={styles.oval}>
                        <div className={styles.box_1_oval}>
                            <div className={styles.box_1_oval_txt}>{studyCircleName}</div>
                        </div>
                    </div>

                    <div className={styles.feedbacks}>
                        <div
                            style={{
                                marginTop: "8px",
                                marginBottom: "16px",
                                marginRight: "40px",
                            }}
                        >
                            <PerformanceCalender
                                handleChange={handleChange}
                                handleClick={handleClick}
                                startdate={startdate}
                                states={states}
                                stylesss={stylesss}
                                styles={styles}
                            />
                        </div>
                        {/* <div className={styles.feedbacks_repo}>{results1()}</div> */}
                    </div>
                </div>
            </GridContainer>
        </div>
    )
}

export default PerformanceCalendarSection