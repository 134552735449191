import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  BsChevronRight,
  BsChevronLeft,
  BsArrowUpRight,
  BsArrowRight,
  BsArrowDownRight,
} from "react-icons/all";
import "react-datepicker/dist/react-datepicker.css";
import ReactLoading from "react-loading";
import { GridContainer } from "../../components/grid/index";

import ResultChart from "../Session/results_chart";
import TrendGraph from "./charts/TrendGraph";
import PerformanceGraph from "./charts/PerformanceGraph";
import AttendanceGraph from "./charts/AttendanceGraph";
import {
  fetchOrganiserSummary,
  fetchRecentSessionTable,
  fetchDashboardTrendTable,
  fetchDashboardTrend,
} from "../../api/cube/dashboardAPI";
import moment from "moment";
import { toast } from "react-toastify";

import {
  studio,
  classroom,
  darkNextArrow,
  darkPrevArrowDisable,
  readVelocityImg,
  handRaise,
  stucle,
  allMode,
} from "./images/images";

import styles from "./DashboardStyle.module.css";
import tableStyle from "../../components/table/TableStyle";
import { whichTimeFrame } from "../../components/whichTImeFrame/whichTimeFrame";
import promiseAllCall from "../../utils/promise";

const useStyles = makeStyles(tableStyle);
var prevDate;
var nextDate;
var totaldaysInMonth;

// Educator / inst - classroom
// Grp educator - studio
//
// Student self - stucle

const Dashboard = (props) => {
  // const startTime = Date.now();
  // console.log("startTime", startTime);
  let institution_id = localStorage.getItem("cmn_school_id");
  // if (!AttendanceData[`${institution_id}`]) institution_id = 25;
  const classes = useStyles();
  const calendarDate =
    localStorage.getItem("oncalendardate") === null
      ? new Date()
      : new Date(localStorage.getItem("oncalendardate"));
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startdate, setStartDate] = useState(calendarDate);
  const [states, setStates] = useState({
    isMonth: true,
    isDay: false,
    isYear: false,
    t_head: [
      "Teacher Name",
      "Subject",
      "Avg Time Spent",
      "Last Login Web",
      "Last Login Classroom",
      "Last Login Studio",
      "Questions",
    ],
  });
  const [organiserSummary, setOrganiserSummary] = useState([]);
  const [recentSessionTable, setRecentSessionTable] = useState([]);
  const [trendTable, setTrendTable] = useState([]);
  const [studentTrendValues, setStudentTrendValues] = useState([]);
  const [trendGraphData, setTrendGraphData] = useState([
    {
      text: "Improving",
      value: 20,
    },
    {
      text: "Maintained",
      value: 26,
    },
    {
      text: "Declining",
      value: 17,
    },
  ]);
  const [showGraph, setShowGraph] = useState(false);
  const recentSessionThead = [
    "Date",
    "Session No.",
    "Topic",
    "Studio/Classroom",
    "Study Circle",
    "Attendance",
  ];
  const trendHead = ["RA #", "Student Name", "Study Circle"];
  let month = calendarDate.getMonth();
  let today = calendarDate.getDate();
  let year = calendarDate.getFullYear();
  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  totaldaysInMonth = getDaysInMonth(month + 1, year);
  let timeFrame = window.localStorage.getItem("timeFrame");
  const stylesss = {
    monthAct: {
      backgroundColor: "#FD8500",
      color: "#fff",
      fontWeight: 500,
    },
    dayAct: {
      backgroundColor: "#FD8500",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      color: "#fff",
      fontWeight: 500,
    },
    yearAct: {
      backgroundColor: "#FD8500",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
      color: "#fff",
      fontWeight: 500,
    },
  };

  console.log("organiserSummary", organiserSummary);
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const stTime = Date.now();
        const callAll = await promiseAllCall([
          fetchOrganiserSummary(institution_id),
          fetchRecentSessionTable(institution_id),
          fetchDashboardTrend(institution_id),
          fetchDashboardTrendTable(institution_id),
        ]);
        const endTime = Date.now();
        console.log("time for fetching combine queries", endTime - stTime);
        console.log("callAll", callAll);
        if (callAll.length > 0) {
          callAll[1] && setRecentSessionTable(callAll[1]);
          callAll[2] && setStudentTrendValues(callAll[2]);
        }
        if (callAll[0].length >= 2) {
          const allDetails = callAll[0].reduce(
            (acc, val) => {
              if (acc["Assessment.count"]) {
                acc["Assessment.count"] =
                  acc["Assessment.count"] + val["Assessment.count"];
              } else {
                acc["Assessment.count"] = val["Assessment.count"];
              }
              if (acc["AssessmentResponse.questionsCount"]) {
                acc["AssessmentResponse.questionsCount"] =
                  acc["AssessmentResponse.questionsCount"] +
                  val["AssessmentResponse.questionsCount"];
              } else {
                acc["AssessmentResponse.questionsCount"] =
                  val["AssessmentResponse.questionsCount"];
              }
              return acc;
            },
            { "AssessmentOrganizer.name": "All Educators" }
          );
          console.log("allDetails", allDetails);
          setOrganiserSummary([...callAll[0], allDetails]);
        } else {
          setOrganiserSummary(callAll[0]);
        }
        let trendTableValues = callAll[3];
        console.log("trendTableValues", trendTableValues);
        if (trendTableValues?.length > 0) {
          setShowGraph(true);
          trendTableValues = await calculateTrend(trendTableValues, callAll[2]);
          console.log("trendTableValues", trendTableValues);
          setTrendTable(trendTableValues);
          const improvingCount = trendTableValues.filter((ele) => {
            return ele.trend == 1;
          });
          const decliningCount = trendTableValues.filter((ele) => {
            return ele.trend == -1;
          });
          const maintainingCount = trendTableValues.filter((ele) => {
            return ele.trend == 0;
          });
          // console.log("improvingCount", improvingCount);
          // console.log("decliningCount", decliningCount);
          // console.log("maintainingCount", maintainingCount);
          setTrendGraphData((prevState) => {
            const newTrendGraph = prevState.map((obj) => {
              if (obj.text === "Improving") {
                return { ...obj, value: improvingCount?.length };
              } else if (obj.text === "Maintained") {
                return { ...obj, value: maintainingCount?.length };
              } else if (obj.text === "Declining") {
                return { ...obj, value: decliningCount?.length };
              } else {
                return obj;
              }
            });
            return newTrendGraph;
          });
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error(err?.message);
        toast.error("Server Error");
      }
    };
    setIsLoading(true);
    fetchInitialData();
  }, []);

  useEffect(() => {
    dateData();
    //eslint-disable-next-line
  }, []);

  if (timeFrame) {
    if (timeFrame === "month") {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
    }
    if (timeFrame === "day") {
      prevDate = year + "-" + (month + 1) + "-" + today;
      nextDate = year + "-" + (month + 1) + "-" + today;
    }
    if (timeFrame === "year") {
      prevDate = year + "-" + 1 + "-" + 1;
      nextDate = year + "-" + 12 + "-" + 31;
    }
  } else {
    prevDate = `${year}-${month + 1}-01`;
    nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
  }

  function dateData() {
    if (timeFrame) {
      setStates((prevState) => {
        return {
          ...prevState,
          isMonth: timeFrame === "month" ? true : false,
          isDay: timeFrame === "day" ? true : false,
          isYear: timeFrame === "year" ? true : false,
        };
      });
    }
  }

  // const [breakPoints,setBreakPoints] = React.useState([
  //   { width: 1, itemsToShow: 1 },
  //   { width: 450, itemsToShow: 2},
  //   { width: 550, itemsToShow: 3},
  //   { width: 650, itemsToShow: 3 },
  //   { width: 750, itemsToShow: 5 },
  //   { width: 850, itemsToShow: 5 },
  //   { width: 950, itemsToShow: 5 },
  // ])

  const handleClick = async (values) => {
    // setIsLoading(true);
    if (values === "d") {
      prevDate = `${year}-${month + 1}-${today}`;
      nextDate = year + "-" + (month + 1) + "-" + today;
    } else if (values === "m") {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
    } else if (values === "y") {
      prevDate = `${year}-01-01`;
      nextDate = `${year}-12-31`;
    }
    // try {
    //   const getTableData = await (
    //     await Axios.get(`${BASE_URL}/session/listing-table?date_from=${prevDate}&date_to=${nextDate}&institution_id=${institutionId}&session_mode=${selectedMode}&study_circle_id=${selectedStudy}&teacher_id=${selectedTeacher}&subject_id=${selectedSubject}`
    //     , Auth)
    //   ).data;
    //   setIsLoading(false);
    setStates({
      ...states,
      isDay: values === "d" ? true : false,
      isMonth: values === "m" ? true : false,
      isYear: values === "y" ? true : false,
      // t_body: getTableData.data
    });
    whichTimeFrame(year, month, today, {
      isDay: values === "d" ? true : false,
      isMonth: values === "m" ? true : false,
      isYear: values === "y" ? true : false,
    });
    // } catch (err) {
    //   console.log(err.response, "error");
    // }
  };

  const handleChange = async (date, arrowdate) => {
    setIsLoading(true);
    if (arrowdate === "prev") {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() - 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth
        ? currentdate.getMonth() - 1
        : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() - 1
        : previousday.getFullYear();
      setStartDate(new Date(year, month, today));
      whichTimeFrame(year, month, today, states);

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? `${year}-${month + 1}-01`
        : states.isYear
        ? `${year}-01-01`
        : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? year + "-" + (month + 1) + "-" + totaldaysInMonth
        : states.isYear
        ? `${year}-12-31`
        : null;
    } else if (arrowdate === "next") {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() + 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth
        ? currentdate.getMonth() + 1
        : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() + 1
        : previousday.getFullYear();
      setStartDate(new Date(year, month, today));
      whichTimeFrame(year, month, today, states);

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? `${year}-${month + 1}-01`
        : states.isYear
        ? `${year}-01-01`
        : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? year + "-" + (month + 1) + "-" + totaldaysInMonth
        : states.isYear
        ? `${year}-12-31`
        : null;
    } else if (arrowdate === "calander") {
      setStartDate(date);
      let date_val = new Date(date);
      localStorage.setItem("oncalendardate", date_val);
      month = date_val.getMonth();
      today = date_val.getDate();
      year = date_val.getFullYear();

      prevDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? `${year}-${month + 1}-01`
        : states.isYear
        ? `${year}-01-01`
        : null;

      nextDate = states.isDay
        ? `${year}-${month + 1}-${today}`
        : states.isMonth
        ? year + "-" + (month + 1) + "-" + totaldaysInMonth
        : states.isYear
        ? `${year}-12-31`
        : null;
    }
    // try {
    //   const getTableData = await (
    //     await Axios.get(`${BASE_URL}/session/listing-table?date_from=${prevDate}&date_to=${nextDate}&institution_id=${institutionId}&session_mode=${selectedMode}&study_circle_id=${selectedStudy}&teacher_id=${selectedTeacher}&subject_id=${selectedSubject}`
    //     ,Auth)
    //   ).data;
    //   setIsLoading(false);
    //   setStates({
    //     ...states,
    //     t_body: getTableData.data
    //   });
    // } catch (err) {
    //   console.log(err.response, "error");
    // }
  };

  async function calculateTrend(tableRows, trendVal) {
    // console.log("calculateTrend inputs", tableRows, trendVal);
    if (!trendVal || !trendVal?.length)
      return tableRows.map((v) => {
        v.trendData = [];
        v.trend = 0;
        v.one = v.two = v.three = null;
        return v;
      });
    let result = tableRows.map((v) => {
      let key = v["AssessmentResponse.raStudentGroupKey"];
      let rvs = trendVal.filter(
        (d) => d["AssessmentResponse.raStudentGroupKey"] === key
      );
      v.trendData = rvs;

      let one = v.trendData.filter(
        (d) => d["AssessmentResponse.rowNumStudentTrend"] == 1
      )[0]?.["AssessmentResponse.avgVelocity"];
      let two = v.trendData.filter(
        (d) => d["AssessmentResponse.rowNumStudentTrend"] == 2
      )[0]?.["AssessmentResponse.avgVelocity"];
      let three = v.trendData.filter(
        (d) => d["AssessmentResponse.rowNumStudentTrend"] == 3
      )[0]?.["AssessmentResponse.avgVelocity"];

      let sum = 0,
        count = 0;
      v.trend = 0;
      if (two) {
        count++;
        sum += two;
      }
      if (three) {
        count++;
        sum += three;
      }
      if (count > 0) {
        let prev = sum / count;
        if (one > prev + 0.05) v.trend = 1;
        else if (one < prev - 0.05) v.trend = -1;
        else v.trend = 0;
      }
      v.one = one;
      v.two = two;
      v.three = three;
      return v;
    });
    console.log("calculateTrend", result);
    return result;
  }

  // console.log("trendTable", trendTable);
  return (
    <>
      {isLoading ? (
        <div className={styles.loading_animation}>
          <ReactLoading
            type="bars"
            color="#FC8802"
            height="10%"
            width="10%"
            className={styles.reactLoadingBars}
            // className="startLoadSpin"
          />
        </div>
      ) : null}
      {/* calander section */}
      <div className={styles.AttenTop}>
        <GridContainer>
          <div className={styles.calanderDiv}>
            {/* <div className={styles.TopdivLeft}>
              <div className={styles.TopdivLeft_Larr}>
                <BsChevronLeft />
              </div>
              <div className={styles.TopdivLeft_Txt}>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Aeneancommodo ligula..
              </div>
              <div className={styles.TopdivLeft_Rarr}>
                <BsChevronRight />
              </div>
            </div> */}
            {/* <div style={{ width: "38%" }}>
              <span className="select1">
                <select name="mode" id="mode">
                  <option value="">{"All Mode"}</option>
                </select>
              </span>
            </div>
            <div className={styles.TopdivRight}>
              <div className={styles.calanderContainer}>
                <div className={styles.calander}>
                  <div className="calCont">
                    <div className="dateDate">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={require("../../assets/images/prev-arrow.png")}
                          alt="prev"
                          onClick={(e) => handleChange(startdate, "prev")}
                          style={{ marginLeft: "40px" }}
                        />
                        <Datepicker
                          id={"fromdate"}
                          selected={startdate}
                          onChange={(e) => handleChange(e, "calander")}
                          dateFormat={
                            states.isDay
                              ? "d MMM yyyy"
                              : states.isMonth
                              ? "MMM yyyy"
                              : states.isYear
                              ? "yyyy"
                              : null
                          }
                          className={styles.input}
                          readOnly={false}
                          showMonthYearPicker={states.isMonth ? true : null}
                          showYearDropdown={states.isYear ? true : null}
                          // show={()=>test()}
                        />
                        <img
                          src={require("../../assets/images/next-arrow.png")}
                          alt="next"
                          onClick={(e) => handleChange(startdate, "next")}
                        />
                        <label htmlFor="fromdate">
                          <img
                            src={require("../../assets/images/calender-icon.png")}
                            alt="cal"
                            // onChange={(e) => handleChange(e, "calander")}
                            style={{ margin: "0px 30px", cursor: "pointer" }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d_w_m_y">
                      <div
                        className="ddd"
                        style={states.isDay ? stylesss.dayAct : null}
                        onClick={() => handleClick("d")}
                      >
                        <p>D</p>
                      </div>
                      <div
                        className="mmm"
                        style={states.isMonth ? stylesss.monthAct : null}
                        onClick={() => handleClick("m")}
                      >
                        <p>M</p>
                      </div>
                      <div
                        className="yyy"
                        style={states.isYear ? stylesss.yearAct : null}
                        onClick={() => handleClick("y")}
                      >
                        <p>Y</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </GridContainer>
      </div>

      {/* attendance section */}
      <div>
        {organiserSummary?.length > 0 && (
          <GridContainer>
            <div className={styles.std_cls}>
              {organiserSummary?.map((el, index) => {
                return (
                  <div
                    className={
                      index % 2 == 0
                        ? styles.std_cls_left
                        : styles.std_cls_right
                    }
                    key={index}
                  >
                    <div className={styles.studioSec}>
                      <div className={styles.studioSec_name}>
                        <div>
                          <img
                            src={
                              el["AssessmentOrganizer.name"] == "Educator"
                                ? classroom
                                : el["AssessmentOrganizer.name"] ==
                                  "Group Educator"
                                ? studio
                                : el["AssessmentOrganizer.name"] ==
                                  "Student Self"
                                ? stucle
                                : allMode
                            }
                            alt="studio_image"
                          />
                        </div>
                        <div className={styles.studioSecName}>
                          {el["AssessmentOrganizer.name"]}
                        </div>
                      </div>
                      <div className={styles.studioSec_attn}>
                        <div className={styles.studioSecAttn}>
                          {el["Assessment.count"]}
                        </div>
                        <div className={styles.studioSecTattn}>conducted</div>
                      </div>
                      <div className={styles.studioSec_que}>
                        <div className={styles.studioSecQue}>
                          {el["AssessmentResponse.questionsCount"]}
                        </div>
                        <div className={styles.studioSecTque}>questions</div>
                      </div>
                      {/* <div className={styles.std_cls_chart}>
                        {<AttendanceGraph value={[0, 0, 0, 0]} />}
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </GridContainer>
        )}
      </div>

      {/* recent sessions section */}
      <div className={styles.recent_session}>
        <GridContainer style={{ backgroundColor: "none" }}>
          <div className={styles.recent_session_top}>recent sessions</div>
        </GridContainer>
      </div>
      <div style={{ marginTop: "-18px" }}>
        <GridContainer>
          <div className={classes.tableResponsive}>
            {recentSessionTable?.length > 0 ? (
              <table id="students" className="sessionTable">
                <thead>
                  <tr style={{ background: "none", padding: "0px" }}>
                    {recentSessionThead.map((el, index) => {
                      return (
                        <th style={{ background: "none" }} key={index}>
                          <div
                            className={
                              el === "Topic" || el === "Session Code"
                                ? styles.tpic
                                : styles.Thead
                            }
                          >
                            <span className={styles.TheadVal}>{el}</span>
                          </div>
                        </th>
                      );
                    })}
                    <th style={{ background: "none" }}>
                      <img
                        src={readVelocityImg}
                        alt="raise"
                        style={{ marginBottom: "-17px", marginLeft: "15px" }}
                      />
                    </th>
                    <th style={{ background: "none" }}>
                      <img
                        src={handRaise}
                        alt="raise"
                        style={{ marginBottom: "-10px", marginLeft: "10px" }}
                      />
                    </th>
                    <th
                      style={{ color: "#000000", background: "none" }}
                      className={styles.result}
                    >
                      <div style={{ marginBottom: "-17px" }}>Result</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {recentSessionTable?.length > 0 &&
                    recentSessionTable.map((el, index) => {
                      const sessionAvgVelocity = (
                        +el["AssessmentResponse.avgVelocity"] || 0
                      ).toFixed(2);
                      const date = el["AssessmentResponse.assessmentDate"];
                      const dateInfo = `${moment(date).format("ddd DD/MM")}`;
                      const doughnutData = [];
                      el["AssessmentResponse.totalCorrects"]
                        ? doughnutData.push(
                            el["AssessmentResponse.totalCorrects"]
                          )
                        : doughnutData.push(0);
                      el["AssessmentResponse.totalNearCorrects"]
                        ? doughnutData.push(
                            el["AssessmentResponse.totalNearCorrects"]
                          )
                        : doughnutData.push(0);
                      el["AssessmentResponse.totalIncorrects"]
                        ? doughnutData.push(
                            el["AssessmentResponse.totalIncorrects"]
                          )
                        : doughnutData.push(0);
                      el["AssessmentResponse.totalNotAnswereds"]
                        ? doughnutData.push(
                            el["AssessmentResponse.totalNotAnswereds"]
                          )
                        : doughnutData.push(0);
                      const startTimeIndex =
                        el["Assessment.startTime"].lastIndexOf(":");
                      const endTimeIndex =
                        el["Assessment.endTime"].lastIndexOf(":");
                      const startTime = el["Assessment.startTime"].slice(
                        0,
                        startTimeIndex
                      );
                      const endTime = el["Assessment.endTime"].slice(
                        0,
                        endTimeIndex
                      );
                      return (
                        <tr key={index}>
                          <td style={{ backgroundColor: "#fff" }}>
                            <div className={styles.Date}>
                              <div className={styles.Day}>{dateInfo}</div>
                              <div
                                className={styles.Time}
                              >{`${startTime} - ${endTime}`}</div>
                            </div>
                          </td>
                          <td
                            className={styles.SessionNo}
                            style={{ width: "10%", backgroundColor: "#fff" }}
                          >
                            <div>{el["GroupSession.code"]}</div>
                          </td>
                          <td style={{ width: "30%", backgroundColor: "#fff" }}>
                            <div className={styles.Topic}>
                              {el["Topic.name"]}
                            </div>
                          </td>
                          <td
                            className={styles.Classroom}
                            style={{ backgroundColor: "#fff" }}
                          >
                            {/* {el["AssessmentOrganizer.name"] == "Educator"
                              ? `Studio`
                              : `Classroom`} */}
                            {"Studio"}
                          </td>
                          <td
                            className={styles.Class}
                            style={{ backgroundColor: "#fff" }}
                          >
                            {el["StudyCircle.name"]}
                          </td>
                          <td
                            className={styles.Attendance}
                            style={{ backgroundColor: "#fff" }}
                          >
                            {`${el["AssessmentResponse.attendancePresent"]} / ${el["AssessmentResponse.attendanceTotal"]}`}
                          </td>
                          <td
                            className={
                              (sessionAvgVelocity > 0 ||
                                sessionAvgVelocity == 0) &&
                              sessionAvgVelocity < 1
                                ? "a"
                                : sessionAvgVelocity > 1 &&
                                  sessionAvgVelocity < 2
                                ? "a"
                                : sessionAvgVelocity > 2 &&
                                  sessionAvgVelocity < 3
                                ? "b"
                                : sessionAvgVelocity > 3 &&
                                  sessionAvgVelocity < 4
                                ? "c"
                                : sessionAvgVelocity > 4 &&
                                  sessionAvgVelocity <= 5
                                ? "dM"
                                : undefined
                            }
                            style={{ backgroundColor: "#fff" }}
                          >
                            <span
                              className={
                                sessionAvgVelocity >= 0 &&
                                sessionAvgVelocity < 1
                                  ? "readVelocity_red"
                                  : sessionAvgVelocity > 1 &&
                                    sessionAvgVelocity < 2
                                  ? "readVelocity_red"
                                  : sessionAvgVelocity > 2 &&
                                    sessionAvgVelocity < 3
                                  ? "readVelocity_yellow"
                                  : sessionAvgVelocity > 3 &&
                                    sessionAvgVelocity < 4
                                  ? "readVelocity_blue"
                                  : sessionAvgVelocity > 4 &&
                                    sessionAvgVelocity <= 5
                                  ? "readVelocity_green"
                                  : undefined
                              }
                            >
                              {sessionAvgVelocity ? sessionAvgVelocity : 0}
                            </span>
                          </td>
                          <td
                            className={styles.handraise}
                            style={{ backgroundColor: "#fff" }}
                          >
                            {el["HandRaise.count"] || 0}
                          </td>
                          <td style={{ backgroundColor: "#fff" }}>
                            {
                              <ResultChart
                                data={
                                  doughnutData?.length > 0
                                    ? doughnutData
                                    : [0, 0, 0, 0]
                                }
                              />
                            }
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <div style={{ marginTop: "20px" }}>
                {"No recent sessions found"}
              </div>
            )}
          </div>
        </GridContainer>
      </div>

      {/* trend section */}
      <div className={styles.performance}>
        <GridContainer style={{ backgroundColor: "none" }}>
          <div className={styles.trend_top}>trend</div>
        </GridContainer>
      </div>
      <div /* style={{ marginTop: "5px" }} */>
        <GridContainer>
          <div className={styles.trend_cls}>
            <div className={styles.trend_cls_left}>
              <div className={classes.tableResponsive}>
                {trendTable?.length > 0 ? (
                  <table
                    id="students"
                    className="sessionTable"
                    style={{ display: "block", height: "410px" }}
                  >
                    <thead>
                      <tr style={{ background: "none", padding: "0px" }}>
                        {trendHead?.map((el, index) => (
                          <th style={{ background: "none" }} key={index}>
                            <div className={styles.Thead}>
                              <span
                                className={
                                  el === "Student Name"
                                    ? styles.stdHead_name
                                    : styles.stdHead
                                }
                              >
                                {el}
                              </span>
                            </div>
                          </th>
                        ))}
                        <th style={{ background: "none" }}>
                          <img
                            src={readVelocityImg}
                            alt="raise"
                            style={{
                              marginLeft: "40px",
                              marginBottom: "-10px",
                            }}
                          />
                        </th>
                        <th
                          style={{ color: "#000000", background: "none" }}
                          className={styles.result}
                        >
                          <div style={{ marginBottom: "-15px" }}>Trend</div>{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {trendTable?.length &&
                        trendTable.map((ele, index) => {
                          // const studentPerformance = Number(
                          //   ele["AssessmentResponse.avgVelocity"] || 0
                          // ).toFixed(2);
                          const studentPerformance = (
                            +ele["AssessmentResponse.avgVelocity"] || 0
                          ).toFixed(2);
                          return (
                            <tr key={index}>
                              <td
                                className={styles.Ra}
                                style={{ backgroundColor: "#fff" }}
                              >
                                {ele["AssessmentResponse.studentId"]}
                              </td>
                              <td
                                className={styles.StudentName}
                                style={{ backgroundColor: "#fff" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  {ele["Student.name"]}
                                </div>
                              </td>
                              <td
                                className={styles.className}
                                style={{ backgroundColor: "#fff" }}
                              >
                                <div className={styles.classes}>
                                  {ele["StudyCircle.name"]}
                                </div>
                              </td>
                              <td
                                className={
                                  (studentPerformance > 0 ||
                                    studentPerformance == 0) &&
                                  studentPerformance < 1
                                    ? "a"
                                    : studentPerformance > 1 &&
                                      studentPerformance < 2
                                    ? "a"
                                    : studentPerformance > 2 &&
                                      studentPerformance < 3
                                    ? "b"
                                    : (studentPerformance > 3 &&
                                        studentPerformance < 4) ||
                                      studentPerformance === 3
                                    ? "c"
                                    : studentPerformance > 4 &&
                                      studentPerformance <= 5
                                    ? "dM"
                                    : undefined
                                }
                                style={{ backgroundColor: "#fff" }}
                              >
                                <span
                                  className={
                                    (studentPerformance > 0 ||
                                      studentPerformance == 0) &&
                                    studentPerformance < 1
                                      ? "readVelocity_red"
                                      : studentPerformance > 1 &&
                                        studentPerformance < 2
                                      ? "readVelocity_red"
                                      : studentPerformance > 2 &&
                                        studentPerformance < 3
                                      ? "readVelocity_yellow"
                                      : (studentPerformance > 3 &&
                                          studentPerformance < 4) ||
                                        studentPerformance === 3
                                      ? "readVelocity_blue"
                                      : studentPerformance > 4 &&
                                        studentPerformance <= 5
                                      ? "readVelocity_green"
                                      : undefined
                                  }
                                >
                                  {studentPerformance}
                                </span>
                              </td>
                              <td style={{ backgroundColor: "#fff" }}>
                                {ele["trend"] === 0 ? (
                                  <BsArrowRight
                                    color="#000000"
                                    style={{ opacity: 0.5 }}
                                    size="2.5em"
                                  />
                                ) : ele["trend"] === 1 ? (
                                  <BsArrowUpRight
                                    size="2.5em"
                                    color="#0FC155"
                                    // className={el.trend}
                                    style={{ opacity: 0.9, fontWeight: "bold" }}
                                  />
                                ) : (
                                  <BsArrowDownRight
                                    size="2.5em"
                                    color="#E50E33"
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                ) : (
                  <div>{"No Data found"}</div>
                )}
              </div>
            </div>

            {showGraph && (
              <div className={styles.trend_cls_right}>
                <div
                  style={{ margin: " 10px 0px -5px", textAlign: "start" }}
                  className={styles.Thead}
                >
                  Students-Trend Graph
                </div>
                <div className={styles.trend}>
                  <div className={styles.trend_left}>
                    {trendGraphData?.map((el, index) => {
                      return (
                        <div className={styles.textcontent} key={index}>
                          <div>
                            {el.text === "Declining" ? (
                              <BsArrowDownRight
                                size="2.5em"
                                color="#E50E33"
                                className={el.trend}
                              />
                            ) : el.text === "Maintained" ? (
                              <BsArrowRight size="2.5em" color="#A6A6A6" />
                            ) : el.text === "Improving" ? (
                              <BsArrowUpRight
                                color="#0FC155"
                                style={{ opacity: 0.5 }}
                                size="2.5em"
                              />
                            ) : null}
                          </div>
                          <div className={styles.trendText}>
                            <div className={styles.trendText_val}>
                              {el.value}
                            </div>
                            <div
                              className={styles.trendText_txt}
                              style={{
                                color:
                                  el.text === "Improving"
                                    ? "#0FC155"
                                    : el.text === "Maintained"
                                    ? "#A6A6A6"
                                    : "#E50E33",
                              }}
                            >
                              {el.text}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.trend_right}>
                    {
                      <TrendGraph
                        data={trendGraphData?.map((v) => v.value)}
                        labels={trendGraphData?.map((v) => v.text)}
                      />
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
        </GridContainer>
      </div>
      {/* {console.log("endTime", Date.now())} */}
    </>
  );
};
export default Dashboard;
