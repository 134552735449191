export function filterBuildAttendance(
  institutionId,
  selectedMode,
  selectedOrganiser,
  selectedAssessmentType,
  selectedStudyCircleId,
  selectedSubjectId,
  prevDate,
  nextDate
) {
  const filter = [];

  filter.push({
    member: "AssessmentResponse.institutionId",
    operator: "equals",
    values: [institutionId],
  });
  if (+selectedMode !== -1) {
    filter.push({
      member: "Assessment.assessmentModeId",
      operator: "equals",
      values: [selectedMode],
    });
  }
  if (+selectedAssessmentType !== -1) {
    filter.push({
      member: "Assessment.assessmentTypeId",
      operator: "equals",
      values: [selectedAssessmentType],
    });
  }
  if (+selectedOrganiser !== -1) {
    filter.push({
      member: "Assessment.assessmentOrganizerTypeId",
      operator: "equals",
      values: [selectedOrganiser],
    });
  }
  if (+selectedStudyCircleId !== -1) {
    filter.push({
      member: "AssessmentResponse.studyCircleId",
      operator: "equals",
      values: [selectedStudyCircleId],
    });
  }
  if (+selectedSubjectId !== -1) {
    filter.push({
      member: "Assessment.subjectId",
      operator: "equals",
      values: [selectedSubjectId],
    });
  }
  if (prevDate && nextDate) {
    filter.push({
      member: "AssessmentResponse.assessmentDate",
      operator: "inDateRange",
      values: [prevDate, nextDate],
    });
  }
  return filter;
}
