import React from "react"
import { IoIosArrowDropdown } from "react-icons/all";
import { Avatar } from "@material-ui/core";
import iconAns from "../../assets/images/icons-answers.png";

const AssessmentStudentRankSection = (props) => {
    const { styles, studentWiseResult } = props

    return (
        <div className={styles.rankList}>
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "2px",
                    boxShadow: "5px 5px 10px 0 rgba(51, 51, 51, 0.05)",
                }}
            >
                <div className={styles.rankList_dtl_result}>
                    <div className={styles.rankList_dtl_results}>
                        <div>
                            <img
                                src={iconAns}
                                alt="resultIcon"
                                style={{ display: "flex", paddingRight: "10px" }}
                            />
                        </div>
                        <div className={styles.student_res}>
                            Student Wise Result
                        </div>
                    </div>
                    <div style={{ textAlign: "center", display: "flex" }}>
                        <IoIosArrowDropdown size={20} />
                    </div>
                </div>

                <div className={styles.rankList_details}>
                    <div className={styles.divsec}>
                        <div className={styles.correct_style}>
                            <div style={{ paddingLeft: "10px" }}>Correct</div>
                            <div>{studentWiseResult.count[0]}</div>
                        </div>
                        <div className={styles.card_C}>
                            {studentWiseResult.studentWiseResultArray
                                ?.sort((std1, std2) => {
                                    return std1["AssessmentResponse.timeTaken"] >
                                        std2["AssessmentResponse.timeTaken"]
                                        ? 1
                                        : std1["AssessmentResponse.timeTaken"] <
                                            std2["AssessmentResponse.timeTaken"]
                                            ? -1
                                            : 0;
                                })
                                ?.map((key, resIndex) => {
                                    return (
                                        key["AssessmentResponse.result"] ===
                                        "correct" && (
                                            <div
                                                key={resIndex}
                                                className={styles.first_div}
                                            >
                                                <Avatar
                                                    src={key["Student.profilePicUrl"]}
                                                    alt="avatar"
                                                />
                                                <div style={{ width: "110px" }}>
                                                    <div className={styles.second_div}>
                                                        {key["Student.name"]}
                                                    </div>
                                                    <div className={styles.third_div}>
                                                        {key["AssessmentResponse.studentId"]}
                                                    </div>
                                                </div>
                                                <div className={styles.forth_div}>
                                                    <div>{key["AssessmentResponse.timeTaken"] +
                                                        "sec"}</div>
                                                    <div className={styles.ans_card}>{`${key["AssessmentResponse.response"]}`}</div>
                                                </div>
                                            </div>
                                        )
                                    );
                                })}
                        </div>
                    </div>
                    <div className={styles.divsec}>
                        <div className={styles.near_correct}>
                            <div style={{ paddingLeft: "10px" }}>
                                Near Correct
                            </div>
                            <div>{studentWiseResult.count[1]}</div>
                        </div>
                        <div className={styles.card_C}>
                            {studentWiseResult.studentWiseResultArray.map((key) => {
                                return (
                                    key["AssessmentResponse.result"] ===
                                    "near correct" && (
                                        <div className={styles.first_div}>
                                            <Avatar
                                                src={key["Student.profilePicUrl"]}
                                                alt="avatar"
                                            />
                                            <div style={{ width: "110px" }}>
                                                <div className={styles.second_div}>
                                                    {key["Student.name"]}
                                                </div>
                                                <div className={styles.third_div}>
                                                    {key["AssessmentResponse.studentId"]}
                                                </div>
                                            </div>
                                            <div className={styles.forth_div}>
                                                <div>{key["AssessmentResponse.timeTaken"] + "sec"}</div>
                                                <div className={styles.ans_card}>{`${key["AssessmentResponse.response"]}`}</div>
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.divsec}>
                        <div className={styles.incorrect}>
                            <div style={{ paddingLeft: "10px" }}>
                                InCorrect
                            </div>
                            <div>{studentWiseResult.count[2]}</div>
                        </div>
                        <div className={styles.card_C}>
                            {studentWiseResult.studentWiseResultArray.map(
                                (key, incorrectIndex) => {
                                    return (
                                        key["AssessmentResponse.result"] ===
                                        "incorrect" && (
                                            <div
                                                key={incorrectIndex}
                                                className={styles.first_div}
                                            >
                                                <Avatar
                                                    src={key["Student.profilePicUrl"]}
                                                    alt="avatar"
                                                />
                                                <div style={{ width: "110px" }}>
                                                    <div className={styles.second_div}>
                                                        {key["Student.name"]}
                                                    </div>
                                                    <div className={styles.third_div}>
                                                        {key["AssessmentResponse.studentId"]}
                                                    </div>
                                                </div>
                                                <div className={styles.forth_div}>
                                                    <div>{key["AssessmentResponse.timeTaken"] +
                                                        "sec"}</div>
                                                    <div className={styles.ans_card}>{`${key["AssessmentResponse.response"]}`}</div>
                                                </div>
                                            </div>
                                        )
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <div className={styles.divsec} style={{ paddingBottom: "10px" }}>
                        <div className={styles.not_answered}>
                            <div style={{ paddingLeft: "10px" }}>
                                Not Answered
                            </div>
                            <div>{studentWiseResult.count[3]}</div>
                        </div>
                        <div className={styles.card_C}>
                            {studentWiseResult?.studentWiseResultArray?.map(
                                (key, absentIndex) => {
                                    return (
                                        (key["AssessmentResponse.result"] ===
                                            "not answered" ||
                                            key["AssessmentResponse.result"] ===
                                            "not_answered" ||
                                            key["AssessmentResponse.result"] ===
                                            "absent") && (
                                            <div
                                                key={absentIndex}
                                                className={styles.first_div}
                                            >
                                                <Avatar
                                                    src={key["Student.profilePicUrl"]}
                                                    alt="avatar"
                                                />
                                                <div style={{ width: "110px" }}>
                                                    <div className={styles.second_div}>
                                                        {key["Student.name"]}
                                                    </div>
                                                    <div className={styles.third_div}>
                                                        {key["AssessmentResponse.studentId"]}
                                                    </div>
                                                </div>
                                                <div className={styles.forth_div}>
                                                    <div>{key["AssessmentResponse.timeTaken"] +
                                                        "sec"}</div>
                                                    {/* <div>{`Ans: ${key["AssessmentResponse.response"]}`}</div> */}
                                                </div>
                                            </div>
                                        )
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssessmentStudentRankSection