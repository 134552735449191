import { CUBEJS_API } from "../../config/Api";
import cubejs from "@cubejs-client/core";
import { toast } from "react-toastify";

function cubeFunction() {
    const cubeJsToken = window.localStorage.getItem("cmn_cubeJs_token");
    const cubejsApi = cubejs(cubeJsToken, {
        apiUrl: CUBEJS_API,
    });
    return cubejsApi;
}

export async function getReportsAPI(cubeQuery) {
    const cubejsApi = cubeFunction();
    const q = cubeQuery
    // console.log("q", q)
    try {
        const result = await cubejsApi.load(q)
        // console.log("result in cube", result)
        return result?.loadResponses[0]?.data
    } catch (error) {
        console.log(error);
        if (error.response.error) {
            console.log(error.response.error);
            toast.error(error.response.error);
        }
        return error;
    }
}