import React from "react"
import {
    DifficultyChart,
} from "../../pages/Students/StudentsDetailscreen/Charts/index";
import {
    Sequrered,
    Sequreblue,
    Sequregreen,
    Sequregrey,
} from "../../pages/Students/Images/Images";

const StudentDifficultyChart = (props) => {
    const { styles, difficultySum } = props

    return (
        <div className={styles.difficulty}>
            <div className={styles.diff_left}>
                <div className={styles.difficulty_text_difficulty}>
                    Difficulty
                </div>
                <div className={styles.diff_left_list}>
                    <div className={styles.diff_left_list_g}>
                        <span>
                            <img
                                src={Sequregreen}
                                alt=""
                                className={styles.diff_left_list_g_img}
                            />
                        </span>
                        <span className={styles.diff_left_list_g_txt}>
                            correct
                        </span>
                    </div>
                    <div className={styles.diff_left_list_b}>
                        <span>
                            <img
                                src={Sequreblue}
                                alt=""
                                className={styles.diff_left_list_b_img}
                            />
                        </span>
                        <span className={styles.diff_left_list_b_txt}>
                            near correct
                        </span>
                    </div>
                    <div className={styles.diff_left_list_r}>
                        <span>
                            <img
                                src={Sequrered}
                                alt=""
                                className={styles.diff_left_list_r_img}
                            />
                        </span>{" "}
                        <span className={styles.diff_left_list_r_txt}>
                            incorrect
                        </span>
                    </div>
                    <div className={styles.diff_left_list_r}>
                        <span>
                            <img
                                src={Sequregrey}
                                alt=""
                                className={styles.diff_left_list_r_img}
                            />
                        </span>{" "}
                        <span className={styles.diff_left_list_gr_txt}>
                            Not Answered
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.diff_right}>
                <div className={styles.diff_right_top}>
                    <div className={styles.diff_right_top_txt}>
                        total questions
                    </div>
                </div>
                <div className={styles.diff_right_middle_chart}>
                    <DifficultyChart values={difficultySum} />
                </div>
                <div className={styles.diff_right_bottom}>
                    <div className={styles.diff_right_bottom_txt}>
                        difficulty level
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentDifficultyChart