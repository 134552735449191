import React from "react";
import ReactDOM from "react-dom";
import App from './App'
import './assets/css/defaultStyle.css'

ReactDOM.render(<App />, document.getElementById("root"));

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<App />);

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<App />);

