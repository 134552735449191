import React from "react"
import { AccuracyChart } from "../../pages/Students/StudentsDetailscreen/Charts";
import { orangeArrow, greenArrow } from "../../pages/Students/Images/Images";

const StudentAccuracySpeedometer = (props) => {
    const { styles, accuracy, groupAccuracy } = props

    // cubeData.accuracy = accuracy, cubeData.groupAccuracy = groupAccuracy
    return (
        <div className={styles.Accuracy}>
            <div className={styles.Accuracy_text}>
                <div className={styles.Accuracy_text_top}>
                    <div className={styles.Accuracy_text_Accuracy}>
                        Accuracy
                    </div>
                    <div className={styles.Accuracy_text_btn}>
                        <span className={styles.Accuracy_text_num} style={{ paddingRight: "6px" }}>
                            {accuracy}
                        </span>
                        <span>
                            {+accuracy >= +groupAccuracy ? (
                                <img src={greenArrow} alt="green_arrow" />
                            ) : (
                                <img src={orangeArrow} alt="orange arrow" />
                            )}
                        </span>
                    </div>
                </div>
                <div className={styles.Accuracy_text_bottom}>
                    <div className={styles.Accuracy_text_bottom_avg}>
                        Group Avg.
                    </div>
                    <div className={styles.Accuracy_text_bottom_num}>
                        {groupAccuracy}
                    </div>
                </div>
            </div>
            <div className={styles.Accuracy_gause_chart}>
                <AccuracyChart
                    value={accuracy > 0 ? +accuracy : 0}
                />
            </div>
        </div>
    )
}

export default StudentAccuracySpeedometer