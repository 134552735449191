const tableStyle = theme => ({
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    borderCollapse: "collapse",
    borderBottom: 'none',
  },
  tableHeadCell: {
    borderBottom: 'none',
  },
  tableCell: {
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    color: "#000000",
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "capitalize",
    borderBottom: 'none',
  },
  tableResponsive: {
    width: "100%",
    overflowX: "auto",
  },
});

export default tableStyle;
