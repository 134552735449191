import React from "react"

const PerformanceDetailsDropdown = (props) => {
    const { styles, name, changeMode, options, displayName, selectedId, value, displayText, modeslabel } = props

    return (
        <span className={styles.selectStudent}>
            <div className={styles.modeslabel}>{modeslabel}</div>
            <select
                name={name}
                id={name}
                value={value}
                onChange={(e) => changeMode(e, name)}
            >
                <option value="-1">{displayText}</option>
                {options.length > 0 && options.map((el, key) => (
                    <option value={el[selectedId]} key={key}>
                        {displayName ? el[displayName] : el["name"]}
                    </option>
                ))}
            </select>
        </span>
    )
}

export default PerformanceDetailsDropdown