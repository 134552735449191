import React from "react"
import Carousel, { consts } from "react-elastic-carousel";
import QuestionsChart from "../../pages/Session/session_render/charts/QuestionsChart";
import darkNext from "../../assets/images/dark-next-arrow.png";
import darkPrev from "../../assets/images/dark-prev-arrow.png";
import globe from "../../assets/images/globe.png";
import user from "../../assets/images/user.png";
import bank from "../../assets/images/bank.png";

const AssessmentQuestionSection = (props) => {
    const { styles, charts, questionDetails, handleQuestionBarClick, questionId } = props
    // console.log("questionDetails", questionDetails)
    return (
        <div className={styles.gandhi_div}>
            <div className={styles.doughnut}>
                <div className={styles.doughnutQ}>Questions</div>
                <div className={styles.doughnutC}>
                    <Carousel
                        // breakPoints={breakPoints}
                        itemsToShow={9}
                        initialActiveIndex={0}
                        focusOnSelect={true}
                        pagination={false}
                        renderArrow={({ type, onClick }) => {
                            const pointer =
                                type === consts.PREV ? (
                                    <img
                                        src={darkPrev}
                                        alt="prev arr"
                                        className={styles.prevDark}
                                    />
                                ) : (
                                    <img
                                        src={darkNext}
                                        alt="next arr"
                                        className={styles.nextDark}
                                    />
                                );
                            return <div onClick={onClick}>{pointer}</div>;
                        }}
                    >
                        {charts.map((el, indexCharts) => (
                            <div
                                key={indexCharts}
                                style={{ margin: "4px", padding: "5px" }}
                                className={
                                    questionId === el.questionId
                                        ? styles.questionChartBack
                                        : null
                                }
                            // className={styles.questionChartBack}
                            >
                                <QuestionsChart
                                    data={el}
                                    handleQuestionBarClick={handleQuestionBarClick}
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
            <div className="gandhi">
                <div className={styles.pdfFile}>
                    <img src={questionDetails.image_path} alt="question" />
                </div>
                <div className={styles.gandhi_bottom}>
                    <div className={styles.gandhi_bottom_left}>
                        <div className={styles.gandhi_bottom_left_rem}>
                            {questionDetails.objective}
                        </div>
                        <div className={styles.gandhi_bottom_left_diff}>
                            Difficulty : {questionDetails.difficulty_level}{" "}
                        </div>
                        <div className={styles.gandhi_bottom_left_jee}>
                            {questionDetails.question_type}
                        </div>
                    </div>
                    <div className={styles.gandhi_bottom_right}>
                        <div>
                            {/* <img src={webIcon} alt="browser" /> */}
                            {questionDetails.access_level === "Global" && (
                                <img src={globe} alt="globe" />
                            )}
                            {questionDetails.access_level === "Organisation" && (
                                <img src={bank} alt="bank" />
                            )}
                            {questionDetails.access_level === "Private" && (
                                <img src={user} alt="user" />
                            )}
                        </div>
                        <div className={styles.gandhi_bottom_right_by}>
                            By: {questionDetails.added_by_user_name}
                        </div>
                        <div className={styles.gandhi_bottom_right_q}>
                            Q# {questionDetails.question_id}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssessmentQuestionSection