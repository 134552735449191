export function createPerformanceFilter(
    studyCircleId,
    selectedMode,
    selectedAssessmentType,
    selectedTopic,
    selectedSubTopic,
    selectedSubject,
    selectedSubSubject,
    selectedObjectives,
    prevDate,
    nextDate
    // selectedGroupId
) {
    const filter = [];

    filter.push({
        member: "AssessmentResponse.studyCircleId",
        operator: "equals",
        values: [`${studyCircleId}`],
    });
    if (+selectedMode !== -1) {
        filter.push({
            member: "Assessment.assessmentModeId",
            operator: "equals",
            values: [`${selectedMode}`],
        });
    }
    if (+selectedAssessmentType !== -1) {
        filter.push({
            member: "Assessment.assessmentTypeId",
            operator: "equals",
            values: [`${selectedAssessmentType}`],
        });
    }
    if (+selectedSubject !== -1) {
        filter.push({
            member: "Assessment.subjectId",
            operator: "equals",
            values: [`${selectedSubject}`],
        });
    }
    // if (+selectedSubSubject !== -1) {
    //   filter.push({
    //     member: "Assessment.subSubjectId",
    //     operator: "equals",
    //     values: [selectedSubSubject],
    //   });
    // }
    if (+selectedTopic !== -1) {
        filter.push({
            member: "Assessment.topicId",
            operator: "equals",
            values: [`${selectedTopic}`],
        });
    }
    // if (+selectedSubTopic !== -1) {
    //   filter.push({
    //     member: "Assessment.subTopicId",
    //     operator: "equals",
    //     values: [selectedSubTopic],
    //   });
    // }
    if (+selectedObjectives !== -1) {
        filter.push({
            member: "Assessment.objectiveId",
            operator: "equals",
            values: [`${selectedObjectives}`],
        });
    }
    if (prevDate) {
        if (nextDate) {
            filter.push({
                member: "AssessmentResponse.assessmentDate",
                operator: "inDateRange",
                values: [`${prevDate}`, `${nextDate}`],
            });
        }
    }
    // if (selectedGroupId !== -1) {
    //   filter.push({
    //     member: "Assessment.groupSessionId",
    //     operator: "equals",
    //     values: [selectedGroupId],
    //   });
    // }
    return filter;
}
