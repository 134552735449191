import React, { useState } from "react";
import styles from "./ForgotPasswordStyle.module.css";
import { Divider, Box } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../login/TextError";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import { BASE_URL } from "../../config/Api";
import ReactLoading from "react-loading";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("email is Required"),
});

const ForgotPassword = () => {
  const [pass, setPass] = useState(true);
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);
    const formData = {
      //  email : values.email,
      email_id: values.email,
      name: "Read Analytics",
      portal: "http://localhost:2001/",
    };
    // Axios.post(`${BASE_URL}/mail/sendmail`, formData)
    Axios.post(`${BASE_URL}/login/forgot_password`, formData).then(
      (response) => {
        toast.success("Email sent");
        setLoading(false);
        if (response.data.status === true) {
          setTimeout(() => {
            setPass(false);
          }, 3000);
        }
      },
      (error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    );
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading_animation}>
          <ReactLoading
            type="bars"
            color="#FC8802"
            height="10%"
            width="10%"
            className={styles.reactLoadingBars}
          />
        </div>
      ) : null}
      <Box className={styles.header}>
        <div className={styles.header_body}>
          <img
            src={require("../../assets/images/read-analytics-logo.png")}
            alt="header-logo"
          />
        </div>
      </Box>
      <Divider
        style={{ background: "#A6C8A6", height: "1px", width: "100%" }}
      />
      <main className={styles.body}>
        <div className={styles.left}>
          <Box component="div" className={styles.reset_box}>
            <Box component="p" className={styles.reset} mb={0}>
              Reset Password
            </Box>
            <p className={styles.txt}>
              Please reset the password for security reasons or if you cannot
              remember it. We will send you a link to your registered email id.
              Click on the link, reset the password and you are ready to go
              again!
            </p>
            {pass ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                className={styles.formik}
              >
                {(formik) => {
                  return (
                    <div className={styles.form_div}>
                      <Form className={styles.form}>
                        <Box className={styles.username} component="div">
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Please enter registered email id here"
                            className={styles.username_field}
                          />
                        </Box>
                        <ErrorMessage name="email" component={TextError}>
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                        <button
                          type="submit"
                          className={styles.Button}
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          <div>Submit</div>
                        </button>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <div className={styles.form_div}>
                <div className={styles.mailSent}>
                  <img
                    src={require("../../assets/images/tick-mark.png")}
                    alt="tick-mark"
                  />
                  Mail Sent!
                </div>
              </div>
            )}
          </Box>
        </div>
        <div className={styles.right}>
          <img
            src={require("../../assets/images/resetPassword-logo.png")}
            alt="resetPassword-logo"
          />
        </div>
      </main>
      <footer className={styles.footer}>
        &copy; {new Date().getFullYear()} Apps'n'Devices Technologies Pvt Ltd.
        All rights reserved.
      </footer>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
};

export default ForgotPassword;
