import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { FaAngleRight } from "react-icons/all";
import styles from "./TrendChart.module.css";
import moment from "moment";

const variables = {
  student: {
    date: "AssessmentResponse.assessmentDate",
    rv: "AssessmentResponse.avgVelocity",
    rank: "GroupRank.studentGroupRank",
    score: "AssessmentResponse.avgScore",
    accuracy: "AssessmentResponse.avgAccuracy",
  },
  performance: {
    date: "AssessmentResponse.assessmentDate",
    rv: "AssessmentResponse.avgVelocity",
    rank: "AssessmentResponse.groupPerformanceRank",
    score: "AssessmentResponse.avgScore",
    accuracy: "AssessmentResponse.avgAccuracy",
  },
};
const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

var countChartCall = 0;
const TrendChart = (props) => {
  const { graph, trendData, startDate, endDate } = props;

  // console.log("trendData from parent", trendData);
  // console.log('char called times', ++countChartCall)

  // date range to operate upon
  const fromDate = moment(startDate, "YYYY-MM-DD");
  let toDate = moment(endDate, "YYYY-MM-DD");
  //   toDate = toDate.add(7, "days");
  // console.log(
  //   "from and to dates",
  //   fromDate.format("YYYY-MM-DD"),
  //   toDate.format("YYYY-MM-DD")
  // );
  let dateRange = [];
  let dateDiff = toDate.diff(startDate, "days");
  for (let i = 0; i < dateDiff; i++) {
    let d = fromDate.clone();
    d.add(i, "days");
    dateRange.push(d);
  }

  // data filling and sanitization
  const expandedData = dateRange.reduce((acc, date) => {
    let thisDate = date.format("YYYY-MM-DD");
    let hasData = trendData.reduce((a, item, index) => {
      if (item[variables[graph].date]?.split("T")[0] === thisDate) return index;
      return a;
    }, -1);
    if (hasData !== -1) {
      acc.push(trendData[hasData]);
    } else {
      acc.push({
        [variables[graph].date]: `${thisDate}T00:00:00.000`,
      });
    }
    let pos = acc.length - 1;
    if (!acc[pos][variables[graph].rv]) acc[pos][variables[graph].rv] = 0;
    if (!acc[pos][variables[graph].rank]) acc[pos][variables[graph].rank] = 0;
    if (!acc[pos][variables[graph].score]) acc[pos][variables[graph].score] = 0;
    if (!acc[pos][variables[graph].accuracy])
      acc[pos][variables[graph].accuracy] = 0;
    return acc;
  }, []);

  // extract data to be displayed
  const labelData = expandedData.map((d) => {
    let date = d[variables[graph].date].split("T")[0].split("-");
    let label = `${month[+date[1] - 1]} ${date[2]}`;
    return label;
  });
  const velocityData = expandedData.map((d) =>
    (+d[variables[graph].rv] || 0).toFixed(2)
  );
  const groupRankDataActual = expandedData.map(
    (d) => d[variables[graph].rank] || 0
  );
  const groupRankData = expandedData.map((d) => {
    let grd = d[variables[graph].rank];
    if (grd && grd > 100) grd = 100;
    return grd || 100;
  });

  const scoreData = expandedData.map((d) => d[variables[graph].score] || 0);
  const accuracyData = expandedData.map((d) =>
    (+d[variables[graph].accuracy] || 0).toFixed(2)
  );

  // setup variables for data display
  const chartData = [
    {
      cValue: velocityData,
    },
    {
      cValue: groupRankData,
    },
    {
      cValue: scoreData,
    },
    {
      cValue: accuracyData,
    },
  ];
  const chartSideName = [
    {
      name: "Velocity",
    },
    {
      name: "Group Rank",
    },
    {
      name: "Score",
    },
    {
      name: "Accuracy",
    },
  ];

  // console.log("chartData", chartData);
  // application logic
  const [count, setCount] = useState(0);
  const [value, setValue] = useState(chartData[0]);
  const [verticalName, setVerticalName] = useState(chartSideName[0]);
  const [scaleNumber, setScaleNumber] = useState(0);
  const [options, setOptions] = useState({});

  const handleChange = (index) => {
    setCount(index);
    setScaleNumber(index);
    setValue(chartData[index]);
    setVerticalName(chartSideName[index]);
  };

  const data = {
    labels: labelData,
    datasets: [
      {
        label: verticalName.name,
        data: chartData[scaleNumber].cValue,
        dataGroupRankActual: groupRankDataActual,
        borderColor: "#4C7DF0",
        pointBorderColor: "#00237B",
        pointBackgroundColor: "#4C7DF0",
        lineTension: 0,
        fill: false,
        borderWidth: 1,
      },
    ],
  };
  let scales = {
    scaleSteps: 5,
    scaleStepWidth: 50,
    yAxes: [
      {
        gridLines: {
          display: true,
          borderDash: [8, 4],
          color: "#e8dcdc",
        },
        ticks: {
          min: 0,
          max: 5,
          reverse: false,
          stepSize: 1,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        // categoryPercentage: .7,
        // barPercentage:.6,
        ticks: {
          max: 20,
          maxTicksLimit: 20,
          padding: 5,
          stepSize: 2,
        },
      },
    ],
    responsive: true,
    maintainAspectRatio: false,
  };
  let groupRankScales = {
    scaleSteps: 5,
    scaleStepWidth: 50,
    yAxes: [
      {
        gridLines: {
          display: true,
          borderDash: [8, 4],
          color: "#e8dcdc",
        },
        ticks: {
          min: 1,
          max: 100,
          reverse: true,
          stepSize: 20,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        // categoryPercentage: .7,
        // barPercentage:.6,
        ticks: {
          max: 20,
          maxTicksLimit: 20,
          padding: 5,
          stepSize: 2,
        },
      },
    ],
    responsive: true,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    setOptions({
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const type = data.datasets[tooltipItem.datasetIndex].label;
            const value =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            let actualRank =
              data.datasets[tooltipItem.datasetIndex].dataGroupRankActual[
              tooltipItem.index
              ];
            if (count === 1) {
              return [type + " : " + actualRank];
            } else {
              return [
                type + " : " + (+value || 0).toFixed(2),
                "Group Rank : " + actualRank,
              ];
            }
          },
          title: function () { },
        },
        backgroundColor: "#E9F0FF",
        radius: 2,
        titleFontColor: "#404040",
        bodyFontColor: "#404040",
        bodyFontSize: 12,
        displayColors: false,
        width: 104,
        height: 47,
      },
      layout: {
        padding: {
          left: 15,
          right: 5,
          top: 25,
        },
      },
      bezierCurve: false,
      elements: {
        point: {
          radius: 5,
        },
        line: {
          tension: 0,
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: verticalName.name,
        position: "left",
        backgroundColor: "#E9F0FF",
      },
      dataset: {
        barPercentage: 0.6,
        categoryPercentage: 0.7,
      },
      scales: scaleNumber === 1 ? groupRankScales : scales,
    });
  }, [scaleNumber]);
  // renderer
  return (
    <div className={styles.left_div_flex}>
      <div className={styles.left_div_col}>
        {chartSideName.map((el, index) => (
          <div
            key={index}
            className={
              count === index ? styles.trend_div_active : styles.trend_div
            }
            onClick={(e) => handleChange(index)}
          >
            <div className={styles.trend_div_name}>{el.name}</div>
            {count === index ? (
              <div style={styles.icon}>
                <FaAngleRight
                  color="#ffffff"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
      <div className={styles.right_div_col}>
        <Line options={options} data={data} width={500} height={205} />
      </div>
    </div>
  );
};

export default TrendChart;
