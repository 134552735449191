function percentage(value, totalValue) {
    const result = Math.round((value * 100) / totalValue);
    return result;
}

export async function performanceScoreGraph(scoreGraphDetails) {
    if (Object.values(scoreGraphDetails).length !== 0) {
        const data = scoreGraphDetails;
        // console.log("scoreGraphDetails", scoreGraphDetails, data)

        const numberOfResponses =
            data["AssessmentResponse.totalCorrects"] +
            data["AssessmentResponse.totalIncorrects"] +
            data["AssessmentResponse.totalNearCorrects"] +
            data["AssessmentResponse.totalNotAnswereds"];
        const totalcount = data["AssessmentResponse.questionsCount"];
        const dataObject = {
            correct: data["AssessmentResponse.totalCorrects"],
            incorrect: data["AssessmentResponse.totalIncorrects"],
            nearCorrect: data["AssessmentResponse.totalNearCorrects"],
            notAnswered: data["AssessmentResponse.totalNotAnswereds"],
            total: totalcount,
            correctPer:
                typeof data["AssessmentResponse.totalCorrects"] === "number"
                    ? percentage(
                        data["AssessmentResponse.totalCorrects"],
                        numberOfResponses
                    ) + "%"
                    : "--",
            incorrectPer:
                typeof data["AssessmentResponse.totalIncorrects"] === "number"
                    ? percentage(
                        data["AssessmentResponse.totalIncorrects"],
                        numberOfResponses
                    ) + "%"
                    : "--",
            nearCorrectPer:
                typeof data["AssessmentResponse.totalNearCorrects"] === "number"
                    ? percentage(
                        data["AssessmentResponse.totalNearCorrects"],
                        numberOfResponses
                    ) + "%"
                    : "--",
            notAnsweredPer:
                typeof data["AssessmentResponse.totalNotAnswereds"] === "number"
                    ? percentage(
                        data["AssessmentResponse.totalNotAnswereds"],
                        numberOfResponses
                    ) + "%"
                    : "--",
        };
        return dataObject;
    } else {
        return {
            correct: 0,
            incorrect: 0,
            nearCorrect: 0,
            notAnswered: 0,
            total: 0,
            correctPer: 0,
            incorrectPer: 0,
            nearCorrectPer: 0,
            notAnsweredPer: 0,
        };
    }
}
