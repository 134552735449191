export function whichTimeFrame(year, month, today, states) {
  localStorage.setItem("oncalendardate", new Date(year, month, today));
  localStorage.setItem(
    "timeFrame",
    states.isDay
      ? "day"
      : states.isMonth
        ? "month"
        : states.isYear
          ? "year"
          : "month"
  );
}
