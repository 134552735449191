import { fetchGrpTime } from "../../../../api/cube/studentAPI";
import roundTwoDecimal from "../../../../components/roundTwoDecimal/roundTwoDecimal";

export async function getGrpTime(filters, groupInstitutionId) {
  const readVel = await fetchGrpTime(filters, groupInstitutionId);
  if (
    readVel?.loadResponses[0]?.data &&
    readVel?.loadResponses[0]?.data.length !== 0
  ) {
    let data =
      readVel?.loadResponses[0]?.data[0]["AssessmentResponse.avgSpeed"];
    if (data === null) {
      data = 0;
    }
    return (+data || 0).toFixed(2);
  } else {
    return 0;
  }
}
