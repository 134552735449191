import { CUBEJS_API } from "../../config/Api";
import cubejs from "@cubejs-client/core";
import { toast } from "react-toastify";

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem("cmn_cubeJs_token");
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(error) {
  console.log(error);
  if (error?.response?.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

//RA-ASSESS-13  Combined query for student details - Performance details page
export async function fetchAllStudentDetails(filters) {
  const cubeJsApi = cubeFunction();
  const qScore = {
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    dimensions: ["StudentHandRaise.handRaise"],
    order: {
      "AssessmentResponse.assessmentDate": "asc",
    },
    filters: filters,
    measures: [
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.avgTimeTaken",
      "AssessmentResponse.marksScored",
      "AssessmentResponse.avgAccuracy",
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNotAnswereds",
      "AssessmentResponse.questionsCount",
      "AssessmentResponse.attendancePresent",
      "AssessmentResponse.attendanceTotal",
    ],
  };

  try {
    let startTime = Date.now()
    const result = await cubeJsApi.load(qScore);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchAllStudentDetails", timeTaken)
    console.log("result inside cube query --fetchAllStudentDetails", result)
    return result?.loadResponses[0]?.data[0];
  } catch (error) {
    handleError(error);
  }
}

//RA-ASSESS-14  Combined query for group details - Student details page
export async function fetchAllGroupDetails(filters) {
  const cubeJsApi = cubeFunction();
  const qScore = {
    timeDimensions: [
      {
        dimension: "AssessmentResponse.assessmentDate",
      },
    ],
    order: {
      "AssessmentResponse.assessmentDate": "asc",
    },
    filters: filters,
    measures: [
      "AssessmentResponse.avgMarksScored",
      "AssessmentResponse.avgAccuracy",
      "AssessmentResponse.avgTimeTaken",
      "AssessmentResponse.avgVelocity",
    ],
  };

  try {
    let startTime = Date.now()
    const result = await cubeJsApi.load(qScore);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Time taken for fetchAllGroupDetails", timeTaken)
    // console.log("result inside cube query --fetchAllGroupDetails", result)
    return result?.loadResponses[0]?.data[0];
  } catch (error) {
    handleError(error);
  }
}

//RA-PERF-10  Combined query for performance details
export async function fetchAllPerformanceDetails(studyCircleId, selectedGroupId) {
  // console.log("filters inside fetchAllPerformanceDetails", studyCircleId, selectedGroupId)
  const cubeJsApi = cubeFunction();
  const qScore = {
    measures: [
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.avgAccuracy",
      "AssessmentResponse.marksScored",
      "AssessmentResponse.avgTimeTaken",
      // "AssessmentResponse.avgMaxTime",
      "AssessmentResponse.questionsCount",
      "AssessmentResponse.totalCorrects",
      "AssessmentResponse.totalNearCorrects",
      "AssessmentResponse.totalIncorrects",
      "AssessmentResponse.totalNotAnswereds"
    ],
    timeDimensions: [
      {
        "dimension": "AssessmentResponse.assessmentDate"
      }
    ],
    filters: [
      {
        "member": "Assessment.groupSessionId",
        "operator": "equals",
        "values": [
          `${selectedGroupId}`
        ]
      },
      {
        "member": "AssessmentResponse.studyCircleId",
        "operator": "equals",
        "values": [
          `${studyCircleId}`
        ]
      }
    ]

  }

  try {
    let startTime = Date.now()
    const result = await cubeJsApi.load(qScore);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Total Time taken for RA_PERF_10 - Combine - fetchAllPerformanceDetails", timeTaken)
    // console.log("result inside cube query --fetchAllPerformanceDetails", result)
    return result?.loadResponses[0]?.data[0];
  } catch (error) {
    handleError(error);
  }
}

//RA-PERF-11  Combined query for performance details - grpId as param
export async function fetchCombinedPerformanceDetails(selectedGroupId) {
  console.log("filters inside fetchCombinedPerformanceDetails", selectedGroupId)
  const cubeJsApi = cubeFunction();
  const qScore = {
    "measures": [
      "AssessmentResponse.avgVelocity",
      "AssessmentResponse.avgAccuracy",
      "AssessmentResponse.avgMarksScored",
      "AssessmentResponse.avgTimeTaken"
    ],
    "timeDimensions": [
      {
        "dimension": "AssessmentResponse.assessmentDate"
      }
    ],
    "filters": [
      {
        "member": "Assessment.groupSessionId",
        "operator": "equals",
        "values": [
          `${selectedGroupId}`
        ]
      }
    ]
  }


  try {
    let startTime = Date.now()
    const result = await cubeJsApi.load(qScore);
    let endTime = Date.now()
    let timeTaken = endTime - startTime
    console.log("Total Time taken for RA_PERF_11 - Combine - fetchCombinedPerformanceDetails", timeTaken)
    // console.log("result inside cube query --fetchCombinedPerformanceDetails", result)
    return result?.loadResponses[0]?.data[0];
  } catch (error) {
    handleError(error);
  }
}