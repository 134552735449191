export function filterBuildPerformance(
  institutionId,
  selectedMode,
  selectedAssessmentType,
  selectedOrganiser,
  selectedStudy,
  //   selectedTeacher,
  //   selectedSubject,
  prevDate,
  nextDate
) {
  const filter = [];

  filter.push({
    member: "AssessmentResponse.institutionId",
    operator: "equals",
    values: [institutionId],
  });
  if (+selectedMode !== -1) {
    filter.push({
      member: "Assessment.assessmentModeId",
      operator: "equals",
      values: [selectedMode],
    });
  }
  if (+selectedAssessmentType !== -1) {
    filter.push({
      member: "Assessment.assessmentTypeId",
      operator: "equals",
      values: [selectedAssessmentType],
    });
  }
  if (+selectedOrganiser !== -1) {
    filter.push({
      member: "Assessment.organizerTypeId",
      operator: "equals",
      values: [selectedOrganiser],
    });
  }
  if (+selectedStudy !== -1) {
    filter.push({
      member: "AssessmentResponse.studyCircleId",
      operator: "equals",
      values: [selectedStudy],
    });
  }
  // if (+selectedTeacher !== -1) {
  //   filter.push({});
  // }
  // if (+selectedSubject !== -1) {
  //   filter.push({});
  // }

  if (prevDate && nextDate) {
    filter.push({
      member: "AssessmentResponse.assessmentDate",
      operator: "inDateRange",
      values: [prevDate, nextDate],
    });
  }
  return filter;
}
