import React, { useEffect, useState /* useRef */ } from "react";
// import { Link } from "react-router-dom";
import pdfIcon from "../../assets/images/pdf-icon.png";
import bookmarkTrue from "../../assets/images/bookmarkTrue.png";
import bookmarkFalse from "../../assets/images/bookmarkFalse.jpg";
import noReport from "../../assets/images/Select-report.png";
import xlsIcon from "../../assets/images/xls-icon.png";
import arrows from "../../assets/images/arrows.png";
import tableStyle from "../../components/table/TableStyle";
import styles from "./reportStyle.module.css";
import StudentTable from "./StudentTable/StudentTable";
import ReportPopup from "./ReportPopup/ReportPopup";
import moment from "moment/moment";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

import {
  getReportsList,
  fetchReportFormatData,
  fetchBookmarkedReportList,
  fetchRecentReports,
  updateReportBookmarkStatus,
  updateLastUsedTime,
} from "../../api/masterReadAnalytics/reportList";
import { getReportsAPI } from "../../api/cube/reportsCubeAPI";
import { DownloadPDFFile } from "../../utils/s3utils";
import LoadingBars from "../../components/LoadingBars/LoadingBars";
import configuration from "../../config/Config";

const useStyles = makeStyles(tableStyle);

// const stylesForSpinners = {
//   position: "fixed",
//   top: "0",
//   left: "0",
//   width: "100vw",
//   height: "100vh",
//   backgroundColor: "black",
//   zIndex: "1",
// };

const Reports = () => {
  const classes = useStyles();
  const [reportsList, setReportsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [copyPool, setCopyPool] = useState({});
  const [loading, setLoading] = useState(true);
  const [generateReports, setGenerateReports] = useState(false);
  const [reportFilename, setReportFilename] = useState("");
  const [formatObj, setFormatObj] = useState({
    modal_format: null,
    table_format: null,
    report_format: null,
    cube_query: null,
  });
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState("All");
  const [reportId, setReportId] = useState(-1);
  const [render, setRender] = useState(false);
  const [bookmarkMessage, setBookmarkMessage] = useState("");

  // console.log("formatObj", formatObj)
  const headerTextValues = formatObj.table_format
    ? Object.keys(formatObj.table_format)
    : [];
  const fixed_columns =
    formatObj?.report_format?.config?.num_fixed_columns || 0;

  useEffect(() => {
    const getAll = async () => {
      await getList();
    };
    getAll();
    //eslint-disable-next-line
  }, [searchText]);

  useEffect(() => {
    const toggleBookmark = async () => {
      try {
        let bookmarkStatus = await updateReportBookmarkStatus(reportId, {
          is_bookmarked: isBookmarked,
        });

        console.log("bookmarkStatus", bookmarkStatus);
        if (bookmarkStatus && isBookmarked) {
          setBookmarkMessage("Bookmarked!");
        } else {
          setBookmarkMessage("Unbookmarked!");
        }
      } catch (err) {
        console.log("error is updating bookmark status", err);
      }
    };
    reportsList.length > 0 && toggleBookmark();
    //eslint-disable-next-line
  }, [render]);

  const instantiateFromTemplate = (template, vars) => {
    if (template) {
      return Object.keys(vars).reduce(
        (a, n) => a.split(`\${${n}}`).join(vars[n]),
        template
      );
    }
  };

  // Input onChange handler
  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };

  // Fetch report list
  const getList = async () => {
    try {
      setLoading(true);
      // console.log("searchText inside getList", searchText)
      let availableReports = await getReportsList(searchText);
      setReportsList(availableReports);

      if (reportsList.length === 0 && searchText.length > 0) {
        let err = { message: "No such report found" };
        throw err;
      } else if (searchText.length === 0) {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error in fetching report list", err);
    }
  };

  // Fetch Report detail using cube
  const getReportData = async (vars) => {
    try {
      setLoading(true);

      if (formatObj.cube_query) {
        let finalQuery = instantiateFromTemplate(
          JSON.stringify(formatObj.cube_query),
          vars
        );
        // console.log('final query', finalQuery)

        let varTableData = await getReportsAPI(JSON.parse(finalQuery));
        setTableData(JSON.parse(JSON.stringify(varTableData)));
        setShowTable(true);

        const printExcelData = () => {
          let varExcelData = fetchExcelData(varTableData);
          setExcelData(JSON.parse(JSON.stringify(varExcelData)));
        };
        printExcelData();

        setGenerateReports(!generateReports);
        // setReportFilename(getReportFilename())
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
      setLoading(false);
    }
  };

  // Generating excel data
  const fetchExcelData = (varTableData) => {
    let localexcelData = varTableData?.map((obj) => {
      return headerTextValues.reduce((excelObj, header) => {
        if (obj[formatObj.table_format[header].variable]) {
          if (formatObj.table_format[header].type === "string") {
            excelObj[header] = obj[formatObj.table_format[header].variable]
              .trim()
              .toUpperCase();
          } else {
            excelObj[header] = obj[formatObj.table_format[header].variable];
          }
        }
        if (
          formatObj.table_format[header].type === "string" &&
          !excelObj[header]
        ) {
          excelObj[header] = "NULL";
        }
        if (
          formatObj.table_format[header].type === "number" &&
          !excelObj[header]
        ) {
          excelObj[header] = 0;
        }
        if (formatObj.table_format[header].type === "milliSec") {
          excelObj[header] = Math.round(
            +obj[formatObj.table_format[header].variable] / 1000,
            0
          );
        }
        if (formatObj.table_format[header].type === "float") {
          excelObj[header] =
            Math.round(+obj[formatObj.table_format[header].variable] * 100) /
            100;
        }
        // console.log("excelObj", excelObj)
        return excelObj;
      }, {});
    });
    // console.log('localexcelData', localexcelData)
    return localexcelData;
  };

  const handleModalOpenClose = () => {
    setOpenModal(!openModal);
  };

  // console.log("tableData", tableData)

  // creating report
  const createReport = async (variablePoolObj) => {
    setLoading(true);
    let vars = JSON.parse(JSON.stringify(variablePoolObj));
    // console.log("vars inside create report", { ...copyPool, ...vars })
    setCopyPool({ ...copyPool, ...vars });
    await getReportData({ ...copyPool, ...variablePoolObj });
    setShowTable(true);
    handleModalOpenClose();
    setReportFilename(
      instantiateFromTemplate(formatObj.report_format.csv_filename, {
        ...copyPool,
        ...variablePoolObj,
      })
    );

    let time = moment(Date.now()).format();
    // console.log("time", typeof time, time)
    await updateLastUsedTime(reportId, { lastused_time: time });
    setLoading(false);
  };

  // onClick handler for report click
  const handleReportListClick = async (report_id) => {
    setCopyPool({ ...copyPool, report_id: report_id });

    try {
      let res = await fetchReportFormatData(report_id);

      // console.log("res", res)
      let report_format = JSON.parse(res.report_format);
      // console.log("report_format 234567", report_format, typeof report_format)

      let modal_format = JSON.parse(res.modal_format);
      // console.log("modal_format 234567", modal_format, typeof modal_format)

      let table_format = JSON.parse(res.table_format);
      // console.log("table_format 234567", table_format, typeof table_format)

      // let cube_query = JSON.stringify(res.cube_query)
      let cube_query = res.cube_query;

      setFormatObj({ modal_format, table_format, report_format, cube_query });
      setIsBookmarked(res.is_bookmarked);
      setReportId(res.id);
    } catch (error) {
      console.log("error in fetching reportFormatData", error);
    }
    handleModalOpenClose();
  };

  // Handle button click for all, recent, bookmarked
  const handleButtonClick = async (string) => {
    setSelectedBtn(string);

    if (string === "All") {
      try {
        setLoading(true);
        let availableReports = await getReportsList(searchText);
        setReportsList(availableReports);
        setLoading(false);
      } catch (err) {
        console.log("error in fetching all reports", err);
        throw err;
      }
    }

    if (string === "Bookmarks") {
      try {
        setLoading(true);
        let bookmarkedReports = await fetchBookmarkedReportList(searchText);
        setReportsList(bookmarkedReports);
        setLoading(false);
      } catch (err) {
        console.log("error in fetching bookmarked reports", err);
        throw err;
      }
    }

    if (string === "Recent") {
      try {
        setLoading(true);
        let recentReports = await fetchRecentReports(searchText);
        setReportsList(recentReports);
        setLoading(false);
      } catch (err) {
        console.log("error in fetching recent reports", err);
        throw err;
      }
    }
  };

  // Animate bookmark status
  const animateBookmark = async () => {
    return setTimeout(() => {
      setBookmarkMessage("");
    }, 2000);
  };

  // handle book mark option
  const handleBookmarkOption = async () => {
    setIsBookmarked(!isBookmarked);
    setRender(!render);
    await animateBookmark();
  };

  const localFilename = (filename) => {
    console.log("filename", filename); //ra-report-1-Test Demo-SCSMR-06/09/2023.csv
    let barename = filename.split(".csv")[0];
    console.log("barename", barename);
    let vals = barename.split("-");
    let date = moment(vals[5], "DD/MM/YYYY").format("YYYY-MM-DD");
    let session_code = vals[4];
    let fname = `${date}-${session_code}-report.pdf`;
    console.log("local filename", fname);
    return fname;
  };
  const getReportFilename = (filename) => {
    console.log("filename", filename); //ra-report-1-Test Demo-SCSMR-06/09/2023.csv
    let barename = filename.split(".csv")[0];
    console.log("barename", barename);
    let vals = barename.split("-");
    let date = moment(vals[5], "DD/MM/YYYY").format("YYYY-MM-DD");
    let session_code = vals[4];
    let fname = `${date}-${session_code}-report.pdf`;
    let fullpath = configuration.spaces + "ra-pdf-reports/" + fname;
    console.log("report file name", fullpath);
    return fullpath;
  };

  const downloadPDF = async (filename) => {
    console.log("filename", filename); //ra-report-1-Test Demo-SCSMR-06/09/2023.csv
    let barename = filename.split(".csv")[0];
    console.log("barename", barename);
    let vals = barename.split("-");
    let date = moment(vals[5], "DD/MM/YYYY").format("YYYY-MM-DD");
    let session_code = vals[4];
    let fname = `${date}-${session_code}-report.pdf`;
    console.log(
      "vals, date, session_code, fname",
      vals,
      date,
      session_code,
      fname
    );
    try {
      let status = await DownloadPDFFile("ra-pdf-reports", fname);
      console.log("status", status);
      if (!status) {
        toast.error("No report for this session");
      }
    } catch (err) {
      toast.error(`Report error: ${err.message}`);
    }
  };

  // console.log("isBookmarked", isBookmarked)
  // console.log("render", render)

  return (
    <>
      {loading ? <LoadingBars /> : null}
      <div className={styles.reportScreen}>
        <div className={styles.reportScreen__left}>
          <div className={styles.selectReport}>
            <div className={styles.selectReportBtnGrp}>
              <button
                className={styles.selected__btn}
                onClick={() => handleButtonClick("All")}
                style={{
                  backgroundColor:
                    selectedBtn === "All" ? "rgba(0, 0, 0, 1)" : "#FFFFFF",
                  color:
                    selectedBtn === "All"
                      ? "rgba(255, 255, 255, 1)"
                      : "#000000",
                }}
              >
                All
              </button>
              <button
                className={styles.selected__btn}
                onClick={() => handleButtonClick("Bookmarks")}
                style={{
                  backgroundColor:
                    selectedBtn === "Bookmarks"
                      ? "rgba(0, 0, 0, 1)"
                      : "#FFFFFF",
                  color:
                    selectedBtn === "Bookmarks"
                      ? "rgba(255, 255, 255, 1)"
                      : "#000000",
                }}
              >
                Bookmarks
              </button>
              <button
                className={styles.selected__btn}
                onClick={() => handleButtonClick("Recent")}
                style={{
                  backgroundColor:
                    selectedBtn === "Recent" ? "rgba(0, 0, 0, 1)" : "#FFFFFF",
                  color:
                    selectedBtn === "Recent"
                      ? "rgba(255, 255, 255, 1)"
                      : "#000000",
                }}
              >
                Recent
              </button>
            </div>
            <input
              className={styles.report__searchBox}
              value={searchText}
              onChange={handleSearchText}
              placeholder="Search..."
            />
            {/* {errorMessage &&
              <div className={styles.search_err_message}>
                {errorMessage}
              </div>
            } */}
          </div>
          <hr
            style={{
              marginTop: "-13px",
              width: "100%",
              height: "0.1px",
              border: "1px solid lightgrey",
            }}
          ></hr>
          <div className={styles.reportList}>
            {reportsList?.length > 0 ? (
              reportsList?.map((el, index) => (
                <div className={styles.reportValue__holder} key={index}>
                  <div
                    className={styles.reportText}
                    onClick={() => handleReportListClick(el.id)}
                    key={index}
                  >
                    {`#${el.id} - ${el.title}`}
                  </div>
                  {index > 0 && index < reportsList.length && (
                    <div
                      style={{
                        border: "1px solid #E7E7E7",
                        width: "96%",
                        marginTop: "25px",
                      }}
                    ></div>
                  )}
                </div>
              ))
            ) : (
              <div
                className={styles.noReport__text}
                style={{ marginTop: "5px", color: "#F2994A" }}
              >
                {"No report found"}
              </div>
            )}
          </div>
        </div>
        {showTable ? (
          <div className={styles.reportScreen__right}>
            <div className={styles.reportScreenTable__header}>
              <div className={styles.reportTable__header__values}>
                <img src={arrows} alt="arrow" />
                <div className={styles.right_div2_left_txt}>
                  {instantiateFromTemplate(
                    formatObj.report_format?.report_title,
                    copyPool
                  )}
                </div>
              </div>
              <div className={styles.reportTable__control__btns}>
                <img
                  onClick={handleBookmarkOption}
                  src={isBookmarked ? bookmarkTrue : bookmarkFalse}
                  alt="bookmark"
                  style={{ cursor: "pointer" }}
                  className={styles.bookmark__icon}
                />

                {tableData?.length > 0 ? (
                  <CSVLink data={excelData} filename={reportFilename}>
                    <img
                      src={xlsIcon}
                      alt="xls"
                      srcSet=""
                      style={{ display: "inherit", padding: "0px 10px" }}
                    />
                  </CSVLink>
                ) : (
                  <img
                    src={xlsIcon}
                    alt="xls"
                    srcSet=""
                    style={{ display: "inherit", padding: "0px 10px" }}
                    // onClick={() => toast.info("No data found to generate excel file")}
                  />
                )}

                {
                  <img
                    src={pdfIcon}
                    alt="pdf"
                    srcSet=""
                    style={{ display: "inherit", cursor: "pointer" }}
                    onClick={(e) => downloadPDF(reportFilename)}
                  />
                }
              </div>
              {bookmarkMessage.length > 0 && (
                <div className={styles.bookmark__message}>
                  {bookmarkMessage}
                </div>
              )}
            </div>

            <div className={styles.reportScreenTable__display}>
              <StudentTable
                table_format={formatObj.table_format}
                headerTextValues={headerTextValues}
                tableData={tableData}
                classes={classes}
                styles={styles}
                fixed_columns={fixed_columns}
              />
            </div>
          </div>
        ) : (
          <div className={styles.noReports}>
            <img
              src={noReport}
              alt="no report"
              style={{ width: "300px", height: "auto" }}
            />
            <div className={styles.noReport__text}>
              {"Select report to display"}
            </div>
          </div>
        )}
      </div>
      {openModal &&
      formatObj.modal_format &&
      formatObj.modal_format.length > 0 ? (
        <ReportPopup
          reportId={reportId}
          modal_format={formatObj.modal_format}
          openModal={openModal}
          handleModalOpenClose={handleModalOpenClose}
          // updateCopyPool={updateCopyPool}
          // stylesForSpinners={stylesForSpinners}
          createReport={createReport}
        />
      ) : null}
      {/* <ToastContainer
        progressClassName={styles.toastProgress}
      /> */}
    </>
  );
};

export default Reports;
