import React from "react"
import "./ReportDropdown.css"

const ReportDropdown = (props) => {
    const { dropdownData, handleDropdownChange, name, ele, display } = props

    return (
        <select
            className="report-dropdown-selection"
            onChange={(e) => handleDropdownChange(e, name, ele)}
            id={name}
        >
            <option>{"Select"}</option>
            {dropdownData.map((ele, index) => {
                let output = display.map((n) => ele[n]).join(' - ')
                return (
                    <option key={index}>{`${output}`}</option>
                )
            })
            }
        </select>
    )
}

export default ReportDropdown