import React from "react"
import { gause, orangeArrow, greenArrow } from "../../assets/Image/Images"

const StudentReadVelocityHeader = (props) => {
    const { styles, studentReadVelocity, groupAvgVelocity, studyAvgReadVelocity, studyCirclePercentile, groupPercentile } = props

    return (
        <div className={styles.readVelocity}>
            <div className={styles.readVelocity_left_div}>
                <div className={styles.readVelocity_left_div_img}>
                    <img src={gause} alt="gause" />
                </div>
                <div className={styles.readVelocity_left_div_txt}>
                    READ VELOCITY
                </div>
                <div className={styles.readVelocity_left_div_rank}>
                    <span>{studentReadVelocity}</span>
                    <span className={styles.rv_greenArrow}>
                        {+studentReadVelocity >=
                            +groupAvgVelocity ? (
                            <img src={greenArrow} alt="green_arrow" />
                        ) : (
                            <img src={orangeArrow} alt="orange arrow" />
                        )}
                    </span>
                </div>
                <div className={styles.readVelocity_left_div_avg}>
                    <div className={styles.readVelocity_left_div_avg_mark}>
                        group avg.
                    </div>
                    <div className={styles.readVelocity_left_div_avg_point}>
                        {groupAvgVelocity}
                    </div>
                </div>
                <div className={styles.readVelocity_left_div_avg}>
                    <div className={styles.readVelocity_left_div_avg_mark}>
                        STUDY CIRCLE AVG.
                    </div>
                    <div className={styles.readVelocity_left_div_avg_point}>
                        {studyAvgReadVelocity}
                    </div>
                </div>
            </div>
            <div className={styles.readVelocityRightMainDiv}>
                <div className={styles.readVelocity_right_div}>
                    <div className={styles.readVelocity_right_div_ranking}>
                        <div
                            className={styles.readVelocity_right_div_ranking_rank}
                        >
                            Rank (Study Circle)
                        </div>
                        <div
                            className={
                                styles.readVelocity_right_div_ranking_percent
                            }
                        >
                            percentile
                        </div>
                    </div>
                    <div className={styles.readVelocity_right_point}>
                        {studyCirclePercentile}
                    </div>
                </div>
                <div className={styles.readVelocity_right_div}>
                    <div className={styles.readVelocity_right_div_ranking}>
                        <div
                            className={styles.readVelocity_right_div_ranking_rank}
                        >
                            RANK (GROUP)
                        </div>
                        <div
                            className={
                                styles.readVelocity_right_div_ranking_percent
                            }
                        >
                            percentile
                        </div>
                    </div>
                    <div className={styles.readVelocity_right_point}>
                        {groupPercentile}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentReadVelocityHeader