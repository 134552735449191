import {
  getStudentWiseSummaryOneAPI,
  getStudentWiseSummaryTwoAPI,
  getStudentWiseSummaryThreeAPI,
} from "../../../../api/cube/sessionAPI";
import promiseAllCall from "../../../../utils/promise";

export async function getStudentWiseSummary(
  assessmentId,
  topicId,
  institutionId,
  studyCircleId
) {
  let studentIdArray = [];

  try {
    const callAll = await promiseAllCall([
      getStudentWiseSummaryOneAPI(
        assessmentId,
        institutionId,
        studyCircleId
      ),
      getStudentWiseSummaryTwoAPI(
        assessmentId,
        institutionId,
        studyCircleId
      )
    ])
    const resultOne = callAll[0]
    const resultTwo = callAll[1]
    // const resultThree = await cubejsApi.load(qSummaryThree);
    // console.log(resultOne.loadResponse.results[0].data);
    // console.log(resultTwo.loadResponse.results[0].data);
    const summaryResultOne = resultOne.loadResponse.results[0].data;
    const summaryResultTwo = resultTwo.loadResponse.results[0].data;
    if (summaryResultOne.length !== 0 && summaryResultTwo.length !== 0) {
      studentIdArray = summaryResultOne.map((obj) => {
        return obj["AssessmentResponse.studentId"].toString();
      });

      const resultThree = await getStudentWiseSummaryThreeAPI(
        topicId,
        studentIdArray
      );
      // console.log(resultThree.loadResponse.results[0].data);
      const summaryResultThree = resultThree.loadResponse.results[0].data;
      if (summaryResultThree.length !== 0) {
        const tempQuestions = [];
        summaryResultOne.forEach((objOne, summaryIndex) => {
          tempQuestions.push([]);
          summaryResultTwo.forEach((objTwo) => {
            if (
              objOne["AssessmentResponse.studentId"] ===
              objTwo["AssessmentResponse.studentId"]
            ) {
              tempQuestions[summaryIndex].push({
                result: objTwo["AssessmentResponse.result"],
                questionId: objTwo["AssessmentResponse.questionId"],
              });
            }
          });
        });
        // console.log(tempQuestions);
        let tempSummary = summaryResultOne.map((obj, summayTempIndex) => {
          return { ...obj, questions: tempQuestions[summayTempIndex] };
        });
        let tempNewSummary = [];
        tempSummary.forEach((obj) => {
          summaryResultThree.forEach((objThree) => {
            if (
              obj["AssessmentResponse.studentId"] ===
              objThree["AssessmentResponse.studentId"]
            ) {
              tempNewSummary.push({
                ...obj,
                topicAvgVelocity: objThree["AssessmentResponse.avgVelocity"],
              });
            }
          });
        });
        // console.log(tempNewSummary);
        return tempNewSummary;
      } else {
        return [];
      }
    } else {
      return [];
    }
  } catch (error) {
    console.log("error in getStudentWiseSummary", error);
  }
}
