import React, { useState, useEffect } from "react";
import {
  BsArrowUpRight,
  BsArrowRight,
  BsArrowDownRight,
} from "react-icons/all";
import up from "../../../assets/images/top-arrow.png";
import down from "../../../assets/images/down-arrow.png";
import roundTwoDecimal from "../../../components/roundTwoDecimal/roundTwoDecimal";
import { sortStudents } from "../StudentsDetailscreen/sortStudents";
import styled from "../StudentStyle.module.css";

const StudentTable = ({ states, studentData, tableClick }) => {
  const [data, setData] = useState([]);
  console.log("studentData", studentData);
  useEffect(() => {
    console.log("calling StudentTable-SE studentData");
    setData(studentData);
    console.log("data inside table", studentData);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("calling StudentTable-SE studentData");
    setData(studentData);
    console.log("data inside table on change of studentData", studentData);
    //eslint-disable-next-line
  }, [studentData]);

  return (
    <div
      className={styled.tableResponsive}
      style={{ marginTop: 0, paddingTop: 0 }}
    >
      <table
        id="students"
        className={data.length === 0 ? styled.studentTable : ""}
      >
        <thead className={styled.stuTableHeader}>
          <tr className={styled.thead_row}>
            {states.t_head.map((el, index) => {
              return (
                <th
                  key={index}
                  className={data.length === 0 ? styled.studentTable_th : ""}
                >
                  <div
                    className={
                      el === "RA#"
                        ? styled.th_RA
                        : el === "Name"
                        ? styled.th_name
                        : el === "Read Velocity"
                        ? styled.th_rv
                        : styled.th
                    }
                  >
                    <span>{el}</span>
                    {el === "Attendance" ? null : (
                      <div className="arrow">
                        <span
                          className="arrUp"
                          onClick={() => sortStudents(true, el, data, setData)}
                        >
                          <img src={up} alt="up" />
                        </span>
                        <span
                          className="arrDown"
                          onClick={() => sortStudents(false, el, data, setData)}
                        >
                          <img src={down} alt="down" />
                        </span>
                      </div>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <td role="img" aria-label="jsx-a11y/accessible-emoji">
                No Data Found
              </td>
            </tr>
          ) : (
            // states.t_body.map((el, index) => {
            data.map((el, index) => {
              return (
                <tr
                  key={index}
                  className={styled.students_row}
                  onClick={() => tableClick(el)}
                >
                  <td className={styled.col_ra}>
                    RA# {el["AssessmentResponse.studentId"]}
                  </td>
                  <td className={styled.col_name}>{el["Student.name"]}</td>
                  {/* <td className={styled.col_mode}>{el["Responses.sessionMode"]}</td> */}
                  <td className={styled.col_study}>{el["StudyCircle.name"]}</td>
                  <td className={styled.col_attend}>
                    {el["AssessmentResponse.attendancePresent"]}/
                    {el["AssessmentResponse.attendanceTotal"]}
                  </td>
                  <td
                    className={
                      el["AssessmentResponse.avgScore"] >= 1 &&
                      el["AssessmentResponse.avgScore"] <= 2
                        ? "a"
                        : el["AssessmentResponse.avgScore"] >= 2 &&
                          el["AssessmentResponse.avgScore"] <= 3
                        ? "b"
                        : el["AssessmentResponse.avgScore"] >= 3 &&
                          el["AssessmentResponse.avgScore"] <= 4
                        ? "c"
                        : el["AssessmentResponse.avgScore"] >= 4 &&
                          el["AssessmentResponse.avgScore"] <= 5
                        ? "dM"
                        : "a"
                    }
                  >
                    <span
                      className={
                        el["AssessmentResponse.avgScore"] >= 1 &&
                        el["AssessmentResponse.avgScore"] <= 2
                          ? "readVelocity_red"
                          : el["AssessmentResponse.avgScore"] >= 2 &&
                            el["AssessmentResponse.avgScore"] <= 3
                          ? "readVelocity_yellow"
                          : el["AssessmentResponse.avgScore"] >= 3 &&
                            el["AssessmentResponse.avgScore"] <= 4
                          ? "readVelocity_blue"
                          : el["AssessmentResponse.avgScore"] >= 4 &&
                            el["AssessmentResponse.avgScore"] <= 5
                          ? "readVelocity_green"
                          : "readVelocity_red"
                      }
                    >
                      {/* {el["AssessmentResponse.avgScore"].toLocaleString(
                            "en-US",
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 0,
                            }
                          )} */}
                      {roundTwoDecimal(el["AssessmentResponse.avgScore"])}
                    </span>
                  </td>
                  <td
                    className={
                      el["AssessmentResponse.avgVelocity"] >= 1 &&
                      el["AssessmentResponse.avgVelocity"] <= 2
                        ? "a"
                        : el["AssessmentResponse.avgVelocity"] >= 2 &&
                          el["AssessmentResponse.avgVelocity"] <= 3
                        ? "b"
                        : el["AssessmentResponse.avgVelocity"] >= 3 &&
                          el["AssessmentResponse.avgVelocity"] <= 4
                        ? "c"
                        : el["AssessmentResponse.avgVelocity"] >= 4 &&
                          el["AssessmentResponse.avgVelocity"] <= 5
                        ? "dM"
                        : "a"
                    }
                  >
                    <span
                      className={
                        el["AssessmentResponse.avgVelocity"] >= 1 &&
                        el["AssessmentResponse.avgVelocity"] <= 2
                          ? "readVelocity_red"
                          : el["AssessmentResponse.avgVelocity"] >= 2 &&
                            el["AssessmentResponse.avgVelocity"] <= 3
                          ? "readVelocity_yellow"
                          : el["AssessmentResponse.avgVelocity"] >= 3 &&
                            el["AssessmentResponse.avgVelocity"] <= 4
                          ? "readVelocity_blue"
                          : el["AssessmentResponse.avgVelocity"] >= 4 &&
                            el["AssessmentResponse.avgVelocity"] <= 5
                          ? "readVelocity_green"
                          : "readVelocity_red"
                      }
                    >
                      {roundTwoDecimal(el["AssessmentResponse.avgVelocity"])}
                    </span>
                  </td>
                  <td className={styled.col_rank}>
                    {el["AssessmentResponse.studyCircleRankVelocity"]}
                  </td>
                  <td className={styled.col_rank}>{el.percentile}</td>
                  <td>
                    {el["trend"] === 0 ? (
                      <BsArrowRight className={styled.trendZero} />
                    ) : el["trend"] === 1 ? (
                      <BsArrowUpRight className={styled.trendPlus} />
                    ) : (
                      <BsArrowDownRight className={styled.trendMinus} />
                    )}
                  </td>
                  <td className={styled.col_attend}>
                    {el["StudentHandRaise.handRaise"]}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StudentTable;
