export const createStudentFilter = (
    id,
    selectedMode,
    selectedAssessmentType,
    selectedOrganiser,
    selectedTopic,
    selectedSubject,
    selectedObjectives,
    individualStudentDetail
) => {
    console.log("individualStudentDetail", individualStudentDetail)
    let filter = []
    if (id !== "-1") {
        filter.push({
            member: individualStudentDetail ? "AssessmentResponse.studentId" : "AssessmentResponse.institutionId",
            operator: "equals",
            values: [id],
        });
    }
    if (+selectedMode !== -1) {
        filter.push({
            member: "Assessment.assessmentModeId",
            operator: "equals",
            values: [selectedMode],
        });
    }
    if (+selectedAssessmentType !== -1) {
        filter.push({
            member: "Assessment.assessmentTypeId",
            operator: "equals",
            values: [selectedAssessmentType],
        });
    }
    if (+selectedOrganiser !== -1) {
        filter.push({
            member: "Assessment.organizerTypeId",
            operator: "equals",
            values: [selectedOrganiser],
        });
    }
    if (+selectedSubject !== -1) {
        filter.push({
            member: "Assessment.subjectId",
            operator: "equals",
            values: [selectedSubject],
        });
    }
    if (+selectedTopic !== -1) {
        filter.push({
            member: "Assessment.topicId",
            operator: "equals",
            values: [selectedTopic],
        });
    }
    if (+selectedObjectives !== -1) {
        filter.push({
            member: "Objective.id",
            operator: "equals",
            values: [selectedObjectives],
        });
    }
    console.log("filter inside CreateFilter individualStudentDetail", filter)
    return filter;
}