import {
  fetchStudentData,
  fetchStudentDataRV,
} from "../../../api/cube/studentAPI";

export async function getStudentData(filters) {
  const result = await fetchStudentData(filters);
  if (result?.loadResponse?.results[0]?.data) {
    console.log(
      "result.loadResponse.results[0].data for getStudentData",
      result.loadResponse.results[0].data
    );
    return result.loadResponse.results[0].data;
  } else {
    return [];
  }
}

export async function getStudentDataRV(filters) {
  const result = await fetchStudentDataRV(filters);
  if (result?.loadResponse?.results[0]?.data) {
    console.log(
      "result.loadResponse.results[0].data for getStudentDataRV",
      result.loadResponse.results[0].data
    );
    return result.loadResponse.results[0].data;
  } else {
    return [];
  }
}
