import { fetchScoreGraph } from "../../../../api/cube/studentAPI";
import percentage from "./percentage";

export async function getScoreGraph(filters) {
  const scoreGraph = await fetchScoreGraph(filters);
  if (scoreGraph.loadResponses[0].data !== 0) {
    const data = scoreGraph.loadResponses[0].data[0];
    const totalcount =
      data["AssessmentResponse.totalCorrects"] +
      data["AssessmentResponse.totalIncorrects"] +
      data["AssessmentResponse.totalNearCorrects"] +
      data["AssessmentResponse.totalNotAnswereds"];
    const dataObject = {
      correct: data["AssessmentResponse.totalCorrects"],
      incorrect: data["AssessmentResponse.totalIncorrects"],
      nearCorrect: data["AssessmentResponse.totalNearCorrects"],
      notAnswered: data["AssessmentResponse.totalNotAnswereds"],
      total: totalcount,
      correctPer:
        typeof data["AssessmentResponse.totalCorrects"] === "number"
          ? percentage(data["AssessmentResponse.totalCorrects"], totalcount) +
          "%"
          : "--",
      incorrectPer:
        typeof data["AssessmentResponse.totalIncorrects"] === "number"
          ? percentage(data["AssessmentResponse.totalIncorrects"], totalcount) +
          "%"
          : "--",
      nearCorrectPer:
        typeof data["AssessmentResponse.totalNearCorrects"] === "number"
          ? percentage(
            data["AssessmentResponse.totalNearCorrects"],
            totalcount
          ) + "%"
          : "--",
      notAnsweredPer:
        typeof data["AssessmentResponse.totalNotAnswereds"] === "number"
          ? percentage(
            data["AssessmentResponse.totalNotAnswereds"],
            totalcount
          ) + "%"
          : "--",
    };
    // console.log("dataObject inside getScoreGraph", dataObject);
    return dataObject;
  } else {
    return {
      correct: 0,
      incorrect: 0,
      nearCorrect: 0,
      notAnswered: 0,
      total: 0,
      correctPer: 0,
      incorrectPer: 0,
      nearCorrectPer: 0,
      notAnsweredPer: 0,
    };
  }
}
