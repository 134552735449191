import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import legendImg from "../../../../assets/images/legend-img.png";
import iconAns from "../../../../assets/images/icons-answers.png";
import styles from "./chartStyle.module.css";
import { getAnswerDetails } from "../SessionDetailsComponents/getAnswerDetails";

const BarChart = ({ questionId, groupInstitutionId, institutionId }) => {
  // console.log(questionId);
  const [data, setData] = useState({
    labels: ["Correct", "Near Correct", "Incorrect", "Not Answered"],
    datasets: [
      {
        label: "My Class",
        data: [18, 18, 18, 17],
        borderColor: "",
        backgroundColor: ["#0FC155", "#4C7DF0", "#E50E33", "#9B9B9B"],
        pointBackgroundColor: "black",
        pointBorderColor: "red",
      },
      {
        label: "Group",
        data: [15, 18, 18, 14],
        borderColor: "pink",
        backgroundColor: ["#bdffd7", '#bbcefc', '#ffc4ce', '#d9d4d4'],
        pointBackgroundColor: "orange",
        pointBorderColor: "cyan",
      },
    ],
  });

  const assesmentId = window.location.pathname.slice(19);
  const options = {
    legend: {
      display: false,
    },
    title: {
      // display:true,
      // text:'Bar chart',
      // position:'top',
      // Align:'end'
    },
    plugins: {
      datalabels: {
        formatter: (value) => {
          let sum = 0;
          let percentage = +((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
        color: "#fff",
        display: true,
      },
      labels: false,
    },
    dataset: {
      barPercentage: 0.8,
      categoryPercentage: 0.5,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
            stepSize: 25,
            padding: 10,
            callback: function (value) {
              return ((value / this.max) * 100).toFixed(0) + "%"; // convert it to percentage
            },
          },
          gridLines: {
            drawBorder: false,
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          // categoryPercentage: 0.5,
          // barPercentage: 0.8,
          ticks: {
            maxTicksLimit: 20,
            padding: 5,
            fontColor: "red",
          },
        },
      ],
    },
  };

  useEffect(
    function () {
      if (+questionId !== 0) {
        // console.log(questionId, groupInstitutionId);
        async function answerDetailsData() {
          const fetchAnswerDetails = await getAnswerDetails(
            assesmentId,
            questionId,
            institutionId,
            groupInstitutionId
          );
          // console.log("fetchAnswerDetails", fetchAnswerDetails)
          setData((prevState) => {
            return {
              ...prevState,
              datasets: [
                { ...prevState.datasets[0], data: fetchAnswerDetails.each },
                { ...prevState.datasets[1], data: fetchAnswerDetails.group },
              ],
              // datasets: [{ ...prevState.datasets[0], data: [25, 25, 25, 25] }],
            };
          });
        }
        answerDetailsData();
      }
    },
    //eslint-disable-next-line
    [questionId]
  );

  return (
    <div>
      <div className={styles.main_item}>
        <div className={styles.left_item}>
          <div className={styles.left_item_img}>
            <img src={iconAns} alt="ans_dtl" />
          </div>
          <div className={styles.left_item_txt}>Answer details</div>
        </div>
        <div className={styles.right_item}>
          <div className={styles.right_item_div1}>
            <div className={styles.right_item_div1_img}>
              <img src={legendImg} alt="legend_img" />
            </div>
            <div className={styles.right_item_div1_txt}>My Class</div>
          </div>
          <div className={styles.right_item_div2}>
            <div className={styles.right_item_div2_img}>
              <img src={legendImg} alt="legend_img" />
            </div>
            <div className={styles.right_item_div2_txt}>Group</div>
          </div>
        </div>
      </div>
      <Bar data={data} options={options} height={300} width={800} />
    </div>
  );
};

export default BarChart;
