import {
    fetchAllSubjectReadVelocity,
    fetchEachSubjectReadVelocity,
    fetchAllSubjectsGraph,
    fetchIndividualSubjectsGraph,
} from "../../../../api/cube/studentAPI";
import promiseAllCall from "../../../../utils/promise";

const getDataPoint = async (obj, studyCircleId, studentId) => {
    // console.log("obj", obj)
    let subjectId = obj["Assessment.subjectId"] || 0;
    console.log("fetching individualSubjectsGraph for subject", subjectId);
    const result = await fetchIndividualSubjectsGraph(
        studentId,
        studyCircleId,
        subjectId
    );
    // console.log("result", result);
    const value = result?.loadResponses[0]?.data;
    // console.log("fetchIndividualSubjectsGraph", value);
    let individualSubjectGraph = value.map(
        (d) => d["AssessmentResponse.avgVelocity"] || 0
    );

    while (individualSubjectGraph.length < 4) individualSubjectGraph.push(-1);
    // console.log("4 points for individualSubjectGraph", individualSubjectGraph);

    individualSubjectGraph = individualSubjectGraph.reverse();
    return {
        sub: obj["Subject.name"],
        rank: Math.round((obj["AssessmentResponse.avgVelocity"] || 0) * 10) / 10,
        value: individualSubjectGraph, //[1, 2, 1, 3],
    };
};

export async function getSubjectReadVelocity(
    filters,
    studyCircleId,
    studentId
) {
    const callAll = await promiseAllCall([
        fetchAllSubjectReadVelocity(filters, studyCircleId),
        fetchEachSubjectReadVelocity(filters, studyCircleId)
    ])
    // const allSub = await fetchAllSubjectReadVelocity(filters, studyCircleId);
    const allSub = callAll[0];
    // const subjects = await fetchEachSubjectReadVelocity(filters, studyCircleId);
    const subjects = callAll[1];
    // console.log("allSub", allSub);
    // console.log("subjects", subjects);
    var dataArray = [];
    if (
        allSub.loadResponses[0].data &&
        subjects.loadResponses[0].data.length !== 0
    ) {
        // console.log(
        //     "allSub.loadResponses[0].data[0] ,subjects.loadResponses[0].data",
        //     allSub.loadResponses[0].data[0], subjects.loadResponses[0].data
        // );
        const allSubData = allSub.loadResponses[0].data[0];
        console.log("allSubData", allSubData)
        const subData = subjects.loadResponses[0].data;
        if (allSubData === null || subData === null) {
            dataArray = [
                {
                    sub: "No Data",
                    rank: 0,
                    value: [1, 2, 1, 3],
                },
            ];
        } else {
            const result = await fetchAllSubjectsGraph(filters, studyCircleId);
            const value = result?.loadResponses?.[0]?.data;
            console.log("fetchAllSubjectsGraphValue", value);
            let allSubjectGraph = value
                .map((d) => d["AssessmentResponse.avgVelocity"] || 0)
            // .reverse();
            console.log("allSubjectGraph", allSubjectGraph)
            while (allSubjectGraph.length < 4) allSubjectGraph.push(-1)
            allSubjectGraph = allSubjectGraph.reverse()
            dataArray.push({
                sub: "All Subjects",
                rank: Math.round(allSubData["AssessmentResponse.avgVelocity"] * 10) / 10,
                value: allSubjectGraph //[0, 4, 2.2, 2.7],
            });
            // console.log("subData for points", subData);
            let dataPoints = await promiseAllCall(subData.map(async (d) => {
                // console.log("processing", d);
                let dataPoint = await getDataPoint(d, studyCircleId, studentId);
                // console.log("dataPoint", dataPoint);
                return dataPoint;
            }));
            // console.log("dataArray inside else", dataArray)
            // console.log("dataPoints", dataPoints)
            console.log("[...dataArray, ...dataPoints]", [...dataArray, ...dataPoints])
            return [...dataArray, ...dataPoints];
        }
        console.log("dataArray outside else", dataArray)
        return dataArray;
    } else {
        return [
            {
                sub: "No Data",
                rank: 0,
                value: [1, 2, 1, 3],
            },
        ];
    }
}