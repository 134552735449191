// icons
import {
  attendanceIcon,
  dashboardIcon,
  reportsIcon,
  performanceIcon,
  sessionIcon,
  studentsIcon,
} from "../assets/img/imageConsts";

// core components/views for Admin layout
import Dashboard from "../pages/Dashboard/index";
import Attendance from "../pages/Attendance/index";
import Performance from "../pages/Performance/index";
import Session from "../pages/Session/index";
import Students from "../pages/Students/index";
import Reports from "../pages/Reports/index";
// import Logout from '../pages/logout/index'
// import StudentDetail from '../pages/Students/StudentsDetailscreen/StudentDetails'

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: dashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/attendance",
    name: "Attendance",
    icon: attendanceIcon,
    component: Attendance,
    layout: "/admin",
  },
  {
    path: "/performance",
    name: "Performance",
    icon: performanceIcon,
    component: Performance,
    layout: "/admin",
  },
  {
    path: "/assessments",
    name: "Assessments",
    icon: sessionIcon,
    component: Session,
    layout: "/admin",
  },
  {
    path: "/students",
    name: "Students",
    icon: studentsIcon,
    component: Students,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: reportsIcon,
    component: Reports,
    layout: "/admin",
  },
  // {
  //   path: "/studentdetails",
  //   name: "Students",
  //   icon: reportsIcon,
  //   component: StudentDetail,
  //   layout: "/admin"
  // },
  // {
  //   path: "/logout",
  //   name: "Logout",
  //   icon: LogoutIcon,
  //   component: Logout,
  //   layout: "/admin"
  // },
];

export default dashboardRoutes;
