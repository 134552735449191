import sortFunction from "../../../components/sortFunction/sortFunction";

export const sortStudents = (value, key, data, setData) => {
  console.log(key);
  switch (key) {
    case "RA#":
      return sortFunction(
        data,
        setData,
        "AssessmentResponse.studentId",
        value,
        true
      );
    case "Name":
      return sortFunction(data, setData, "Student.name", value, false);
    case "Study Circle":
      return sortFunction(data, setData, "StudyCircle.name", value, false);
    case "Score":
      return sortFunction(
        data,
        setData,
        "AssessmentResponse.avgScore",
        value,
        true
      );
    case "Read Velocity":
      return sortFunction(
        data,
        setData,
        "AssessmentResponse.avgVelocity",
        value,
        true
      );
    case "Hand Raise":
      return sortFunction(
        data,
        setData,
        "StudentHandRaise.handRaise",
        value,
        true
      );
    default:
      return setData([...data]);
  }
};
