import React, { memo } from "react"

const StudentDetailDropdown = (props) => {
    const { className, name, value, onChangeFun, options, selectedId, option, displayName } = props

    return (
        <select
            className={className}
            name={name}
            value={value}
            onChange={(e) => onChangeFun(e, name)}
        >
            <option value="-1">{option}</option>
            {options.length > 0 && options?.map((opt, orgKey) => {
                return (
                    <option key={orgKey} value={opt[selectedId]}>
                        {displayName ? opt[displayName] : opt["name"]}
                    </option>
                );
            })}
        </select>
    )
}

export default memo(StudentDetailDropdown)