import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Admin from "./layouts/Admin/admin";
import "./css/App.css";
import Login from "./pages/login/Login";
import PageNotFound from "./pages/Page_Not_Found/Page_Not_found";
import NewPassword from "./pages/NewPassword/NewPassword";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/admin" component={Admin} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/:user_id/new-password" component={NewPassword} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
}
export default App;
