import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { Avatar } from "@material-ui/core";
import {
  BsArrowUpRight,
  BsArrowRight,
  BsArrowDownRight,
} from "react-icons/all";
// import { Link } from "react-router-dom";
// import PerformanceData from "../../Performance/performance.json";
import styles from "../PerformanceStyle.module.css";
import tableStyle from "../../../components/table/TableStyle";
import up from "../../../assets/images/top-arrow.png";
import down from "../../../assets/images/down-arrow.png";
import rv from "../../../assets/images/performance-rv.png";
import trend from "../../../assets/images/performance-trend.png";

import "./tableStyle.css";
import roundTwoDecimal from "../../../components/roundTwoDecimal/roundTwoDecimal";
import sortFunction from "../../../components/sortFunction/sortFunction";

const useStyles = makeStyles(tableStyle);

const PerformanceTable = ({ PerformanceData, tableClick }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const tHead = [
    "study circle",
    "subject",
    "type",
    "organiser",
    "questions",
    "score",
  ];

  useEffect(() => {
    setData(PerformanceData);
    //eslint-disable-next-line
  }, [PerformanceData]);

  const sortArray = (value, key) => {
    // console.log("key", key);
    switch (key) {
      case "subject":
        return sortFunction(data, setData, "Subject.name", value, false);
      case "type":
        return sortFunction(data, setData, "AssessmentType.name", value, false);
      case "study circle":
        return sortFunction(data, setData, "StudyCircle.name", value, false);
      case "organiser":
        return sortFunction(
          data,
          setData,
          "AssessmentOrganizer.name",
          value,
          false
        );
      case "questions":
        return sortFunction(
          data,
          setData,
          "AssessmentResponse.questionsCount",
          value,
          true
        );
      case "score":
        return sortFunction(
          data,
          setData,
          "AssessmentResponse.avgScore",
          value,
          true
        );
      case "rv":
        return sortFunction(
          data,
          setData,
          "AssessmentResponse.avgVelocity",
          value,
          true
        );
      // case "gp":
      //   if (value) {
      //     return setData(
      //       [...data.sort((a, b) => (a.group_rank > b.group_rank ? 1 : -1))]
      //     );
      // return sortFunction(data, setData, "group_rank", true, true);
      //   } else {
      // return sortFunction(data, setData, "group_rank", false, true);
      //   }
      // case "trend":
      //   if (value) {
      //     return setData([...data.sort((a, b) => (a.trend > b.trend ? 1 : -1))]);
      // return sortFunction(data, setData, "trend", true, true);
      //   } else {
      // return sortFunction(data, setData, "avgVelocity", false, true);
      //     );
      //   }
      default:
        return setData([...data]);
    }
  };

  console.log("data", data);
  console.log("PerformanceData", PerformanceData);
  return (
    <div
      className={classes.tableResponsive}
      style={{ marginTop: 0, paddingTop: 0 }}
    >
      <table id="students" className="preformanceTable">
        <thead className="perfTableHeader">
          <tr className={styles.performancePage}>
            {tHead.map((el, index) => {
              return (
                <th
                  key={index}
                  style={{ background: "none" }}
                  className={el === "group percentile" ? "aks" : ""}
                >
                  <div className={styles.theadName}>
                    <div style={{ color: "#000000" }}>{el}</div>
                    <div className="arrow">
                      <div className="arrUp">
                        <img
                          src={up}
                          alt="up"
                          onClick={() => sortArray(true, el)}
                        />
                      </div>
                      <div className="arrDown">
                        <img
                          src={down}
                          alt="down"
                          onClick={() => sortArray(false, el)}
                        />
                      </div>
                    </div>
                  </div>
                </th>
              );
            })}
            <th>
              <div className={styles.theadImg}>
                <div>
                  <img src={rv} alt="readVelocity" />
                </div>
                <div className="arrow">
                  <div className="arrUp">
                    <img
                      src={up}
                      alt="up"
                      onClick={() => sortArray(true, "rv")}
                    />
                  </div>
                  <div className="arrDown">
                    <img
                      src={down}
                      alt="down"
                      onClick={() => sortArray(false, "rv")}
                    />
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className={styles.theadGP}>
                <div>Group Percentile</div>
                <div className="arrow">
                  <div className="arrUp">
                    <img
                      src={up}
                      alt="up"
                      onClick={() => sortArray(true, "gp")}
                    />
                  </div>
                  <div className="arrDown">
                    <img
                      src={down}
                      alt="down"
                      onClick={() => sortArray(false, "gp")}
                    />
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className={styles.theadImg}>
                <div>
                  <img src={trend} alt="trend" />
                </div>
                <div className="arrow">
                  <div className="arrUp">
                    <img
                      src={up}
                      alt="up"
                      onClick={() => sortArray(true, "trend")}
                    />
                  </div>
                  <div className="arrDown">
                    <img
                      src={down}
                      alt="down"
                      onClick={() => sortArray(false, "trend")}
                    />
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="perfTableBody">
          {PerformanceData.length === 0 ? (
            <tr
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <td role="img" aria-label="jsx-a11y/accessible-emoji">
                No Data Found.
              </td>
            </tr>
          ) : (
            data.map((el, index) => {
              return (
                <tr
                  key={index}
                  className={styles.perBody}
                  style={{ boxShadow: "5px 5px 10px 0 rgba(0, 0, 0, 0.05)" }}
                  onClick={() => tableClick(el)}
                >
                  <td>
                    <div style={{ marginLeft: "35px" }}>
                      {el["StudyCircle.name"]}
                    </div>
                  </td>
                  <td>
                    <div style={{ marginLeft: "12px", textAlign: "left" }}>
                      {/* <div className="avatar" style={{width:'40px',height:'40px'}}>
                      <Avatar style={
                        el.icon === 'english' ? {backgroundColor:'#838B23',textTransform:'uppercase',fontSize:'15px'} :
                        el.icon === 'kannada' ? {backgroundColor:'#E0AF15',textTransform:'uppercase',fontSize:'15px'} :
                        el.icon === 'mathematics' ? {backgroundColor:'#C8661E',textTransform:'uppercase',fontSize:'15px'} :
                        el.icon === 'science' ? {backgroundColor:'#A32A1B',textTransform:'uppercase',fontSize:'15px'} :
                        el.icon === 'social science' ? {backgroundColor:'#180302',fontSize:'15px'} 
                        : {}
                      } height={20} width={20}>{el.icon.slice(0, 2)}
                      </Avatar>
                    </div> */}
                      {el["Subject.name"]}
                    </div>
                  </td>
                  <td>
                    <div style={{ marginLeft: "12px" }}>
                      {el["AssessmentType.name"]}
                    </div>
                  </td>
                  <td>
                    <div style={{ marginLeft: "6px" }}>
                      {el["AssessmentOrganizer.name"]}
                    </div>
                  </td>
                  <td>
                    <div style={{ marginLeft: "35px" }}>
                      {el["AssessmentResponse.questionsCount"]}
                    </div>
                  </td>
                  <td
                    className={
                      el["AssessmentResponse.avgScore"] >= 1 &&
                        el["AssessmentResponse.avgScore"] < 2
                        ? "a"
                        : el["AssessmentResponse.avgScore"] >= 2 &&
                          el["AssessmentResponse.avgScore"] < 3
                          ? "b"
                          : el["AssessmentResponse.avgScore"] >= 3 &&
                            el["AssessmentResponse.avgScore"] < 4
                            ? "c"
                            : el["AssessmentResponse.avgScore"] >= 4 &&
                              el["AssessmentResponse.avgScore"] <= 5
                              ? "dM"
                              : "a"
                    }
                  // style={{ backgroundColor: "#fff" }}
                  >
                    <span
                      className={
                        el["AssessmentResponse.avgScore"] >= 1 &&
                          el["AssessmentResponse.avgScore"] < 2
                          ? "readVelocity_red"
                          : el["AssessmentResponse.avgScore"] >= 2 &&
                            el["AssessmentResponse.avgScore"] < 3
                            ? "readVelocity_yellow"
                            : el["AssessmentResponse.avgScore"] >= 3 &&
                              el["AssessmentResponse.avgScore"] < 4
                              ? "readVelocity_blue"
                              : el["AssessmentResponse.avgScore"] >= 4 &&
                                el["AssessmentResponse.avgScore"] <= 5
                                ? "readVelocity_green"
                                : "readVelocity_red"
                      }
                      style={{ margin: "0", marginLeft: "4px" }}
                    >
                      {roundTwoDecimal(el["AssessmentResponse.avgScore"])}
                    </span>
                  </td>
                  <td
                    className={
                      el["AssessmentResponse.avgVelocity"] > 1 &&
                        el["AssessmentResponse.avgVelocity"] < 2
                        ? "a"
                        : el["AssessmentResponse.avgVelocity"] > 2 &&
                          el["AssessmentResponse.avgVelocity"] < 3
                          ? "b"
                          : el["AssessmentResponse.avgVelocity"] > 3 &&
                            el["AssessmentResponse.avgVelocity"] < 4
                            ? "c"
                            : el["AssessmentResponse.avgVelocity"] > 4 &&
                              el["AssessmentResponse.avgVelocity"] <= 5
                              ? "dM"
                              : "a"
                    }
                  // style={{ backgroundColor: "#fff" }}
                  >
                    <span
                      className={
                        el["AssessmentResponse.avgVelocity"] > 1 &&
                          el["AssessmentResponse.avgVelocity"] < 2
                          ? "readVelocity_red"
                          : el["AssessmentResponse.avgVelocity"] > 2 &&
                            el["AssessmentResponse.avgVelocity"] < 3
                            ? "readVelocity_yellow"
                            : el["AssessmentResponse.avgVelocity"] > 3 &&
                              el["AssessmentResponse.avgVelocity"] < 4
                              ? "readVelocity_blue"
                              : el["AssessmentResponse.avgVelocity"] > 4 &&
                                el["AssessmentResponse.avgVelocity"] <= 5
                                ? "readVelocity_green"
                                : "readVelocity_red"
                      }
                      style={{ margin: "0", marginLeft: "-15px" }}
                    >
                      {roundTwoDecimal(el["AssessmentResponse.avgVelocity"])}
                    </span>
                  </td>
                  <td>
                    <div style={{ marginLeft: "-15px" }}>{el.percentile}</div>
                  </td>
                  <td>
                    <div>
                      {el["trend"] === 0 ? (
                        <BsArrowRight
                          color="#000000"
                          style={{ opacity: 0.5 }}
                          size="2.5em"
                        />
                      ) : el["trend"] === 1 ? (
                        <BsArrowUpRight
                          size="2.5em"
                          color="#0FC155"
                          className={el.trend}
                        />
                      ) : (
                        <BsArrowDownRight size="2.5em" color="#E50E33" />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};
export default PerformanceTable;
