import https from "../../init/https"

// RA-REPO-00: Generic Request
export async function fetchDropdownData(request) {

    try {
        const resultArray = await https.get(`${request}`)
        // console.log("resultArray in postgreSql query", resultArray)
        return resultArray?.data?.data
    } catch (err) {
        console.log("err", err)
    }
}

//RA-REPO-01: Get the List of the report for search title
export async function getReportsList(search_text) {
    const reportsList = await https.get(`/report/ra_query?search_text=${search_text}`)
    // console.log("resportsList in postgreSql query", reportsList)
    return reportsList?.data?.data
}

// RA-REPO-02: Get cube_query for given id
export const fetchReportFormatData = async (report_id) => {
    try {
        const reportFormat = await https.get(`/report/${report_id}/cube_query`)
        // console.log("reportFormat", reportFormat)
        return reportFormat?.data?.data
    } catch (err) {
        console.log("error in fetching reportFormat", err)
        throw err
    }
}

// RA-REPO-03: Get the list of bookmarked cube query
export const fetchBookmarkedReportList = async (search_text) => {
    try {
        const bookmarkedReports = await https.get(`/report/bookmarked_query?search_text=${search_text}`)
        // console.log("bookmarkedReports", bookmarkedReports)
        return bookmarkedReports?.data?.data
    } catch (err) {
        console.log("error in fetching bookmarked reports", err)
        throw err
    }
}

// RA-REPO-05: Get the list of recent updated cube query
export const fetchRecentReports = async (search_text) => {
    try {
        const recentReports = await https.get(`/report/recent_query?search_text=${search_text}`)
        // console.log("recentReports", recentReports)
        return recentReports?.data?.data
    } catch (err) {
        console.log("error in fetching recent reports", err)
        throw err
    }
}

// RA-REPO-04: Update the bookmark/unbookmark status in the cube query
export const updateReportBookmarkStatus = async (report_id, bookmark_status) => {
    // console.log("report_id, bookmark_status", report_id, bookmark_status)
    try {
        const updateBookmarkStatus = await https.put(`/report/${report_id}/set_bookmark_status`, bookmark_status)
        // console.log("update bookmark status", updateBookmarkStatus)
        return updateBookmarkStatus.data.status
    } catch (err) {
        console.log("error in updating bookmark status", err)
        throw err
    }
}

// RA-REPO-06: Update the last_used time of the cube query
export const updateLastUsedTime = async (id, time) => {
    try {
        const updatedTime = await https.put(`report/${id}/set_lastused_time`, time)
        console.log("updatedTime", updatedTime)
        return updatedTime?.data?.status
    } catch (err) {
        console.log("error in updating used time", err)
        throw err
    }
}