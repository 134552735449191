import React from "react"
import attendanceNormal from "../../assets/images/attendance-normal.png";
import { handRaise } from "../../pages/Students/Images/Images";

const StudentHandRaiseSection = (props) => {
    const { styles, studentDetails } = props

    return (
        <div className={styles.last_div_right}>
            <div className={styles.gauseUpper}>
                <div className={styles.gauseImg}>
                    <img src={attendanceNormal} alt="gause" height="36px" />
                </div>
                <div className={styles.gauseTxt}>Attendance</div>
                <div className={styles.gauseTxt}>
                    {studentDetails.attendance.present}/{studentDetails.attendance.total}
                </div>
            </div>
            <div className={styles.gause}>
                <div className={styles.gauseImg}>
                    <img src={handRaise} alt="gause" height="36px" />
                </div>
                <div className={styles.gauseTxt}>HAND RAISE</div>
                <div className={styles.gauseTxt}>{studentDetails.handRaise}</div>
            </div>
        </div>
    )
}

export default StudentHandRaiseSection