import React from "react"
import { handRaise } from "../../pages/Performance/Images/Images"
import chartStyle from "../../pages/Performance/PerformanceSubjectDetails/Charts/HandRaiseChart.module.css";

const PerformanceHandRiseSection = (props) => {
    const { styles, performanceHandriseValues } = props

    return (
        <div className={styles.last_div_right}>
            <div className={styles.gause}>
                <div className={styles.gauseImg}>
                    <img src={handRaise} alt="gause" height="36px" />
                </div>
                <div className={styles.gauseTxt}>HAND RAISE</div>
            </div>
            <div className={styles.rightChart}>
                <div style={{ padding: "10px" }}>
                    <div className={chartStyle.main}>
                        <div className={chartStyle.left}>
                            <div className={chartStyle.top}>
                                {performanceHandriseValues?.totalHandrise}
                            </div>
                            <div className={chartStyle.bottom}>Total Hand Raise</div>
                        </div>
                        <div className={chartStyle.mid}>
                            <div className={chartStyle.top}>
                                {performanceHandriseValues?.uniqueStudent}
                            </div>
                            <div className={chartStyle.bottom}>Unique Students</div>
                        </div>
                        <div className={chartStyle.right}>
                            <div className={chartStyle.top}>
                                {performanceHandriseValues?.avgHandRaisePerMin}
                            </div>
                            <div className={chartStyle.bottom_end}>
                                Avg. Hand Raise per min.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PerformanceHandRiseSection