import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Divider, Box, Icon } from "@material-ui/core";
import * as Yup from "yup";
import TextError from "./TextError";
import "react-toastify/dist/ReactToastify.css";
import { useStyles } from "../../assets/styles/components/searchBoxStyle";
import styles from "./Login.module.css";
import Axios from "axios";
import { BASE_URL } from "../../config/Api";
import DialogBox from "../../components/dialog/DialogBox";
import SearchComponent from "../../components/searchBox/searchBox";
import ReactLoading from "react-loading";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required("username is Required")
    .min(5, "userid too short"),
  password: Yup.string()
    .required("password is Required")
    .min(5, "password too short"),
});

const Login = () => {
  const classes = useStyles();
  let loggedIn = false;
  const [LogedIn, setLogedIn] = useState(loggedIn);
  const [openModal, setOpenModal] = useState(false);
  const [userSchools, setUserSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  let schoolhead = ["school Code", "school Name"];

  const onSubmit = (values, submitProps) => {
    submitProps.setSubmitting(false);
    setLoading(true);
    submitProps.resetForm();
    const formData = {
      // email: values.email,
      email_id: values.email,
      password: values.password,
    };
    // Axios.post(`${BASE_URL}/user/signin`,formData)
    Axios.post(`${BASE_URL}/login/signin`, formData).then(
      (response) => {
        setLoading(false);
        if (response.data.status === true) {
          console.log(response.data.data);
          // setSchoolId(response.data.data.school_id);

          window.localStorage.setItem("token", response.data.data.token);
          window.localStorage.setItem("sessionExp", response.data.data.expiry);
          window.localStorage.setItem(
            "schoolEmblem",
            response.data.data.emblem_file_path
          );
          window.localStorage.setItem("role", response.data.data.role);
          window.localStorage.setItem(
            "schoolName",
            response.data.data.institution_name
          );
          window.localStorage.setItem(
            "schoolLocation",
            response.data.data.place
          );
          // window.localStorage.setItem("cmn_school_id", response.data.data.result[0].institution_id);
          window.localStorage.setItem(
            "cmn_school_id",
            response.data.data.school_id
          );
          window.localStorage.setItem(
            "cmn_user_email",
            response.data.data.email
          );
          // window.localStorage.setItem( "cmn_user_full_name", response.data.data.result[0].full_name);
          window.localStorage.setItem(
            "cmn_user_full_name",
            response.data.data.full_name
          );
          // // window.localStorage.setItem("cmn_school_name", response.data.data.result.school_name);
          window.localStorage.setItem(
            "cmn_cubeJs_token",
            response.data.data.cubejsToken
          );
          // window.localStorage.setItem("cmn_user_id", response.data.data.result[0].user_id);
          window.localStorage.setItem("cmn_user_id", response.data.data.id);
          if (response.data.data) {
            const fetchData = async () => {
              try {
                const result = await (
                  await Axios.get(
                    `${BASE_URL}/login/${response.data.data.id}/institutions`,
                    {
                      headers: {
                        Authorization: "Bearer " + response.data.data.token,
                      },
                    }
                  )
                ).data;
                if (result.data.length === 0) {
                  setOpenModal(false);
                  setLogedIn(true);
                  toast.success("login successful !");
                } else if (result.data.length === 1) {
                  setUserSchools(result.data);
                  // setOpenModal(true);
                  setLogedIn(true);
                  toast.success("login successful !");
                } else if (result.data.length > 0) {
                  setUserSchools(result.data);
                  setOpenModal(true);
                }
              } catch (err) {
                toast.error(err.response.data.message);
                setLoading(false);
              }
            };
            fetchData();
          } else {
            setLogedIn(true);
            setOpenModal(false);
          }
        }
      },
      (error) => {
        toast.error(error.response.data.message);
        setLoading(false);
        setLogedIn(false);
        // setTimeout(() => {
        // window.location.reload(false);
        // }, 3000);
      }
      // const institutionId = window.localStorage.setItem("institutionId",1);
      // console.log("Form data", values);
      // console.log("submitProps", submitProps);
      // submitProps.setSubmitting(false);
      // submitProps.resetForm();
      // if(values.userId===userId && values.password === password){
      //   toast.success("login successful !");
      //   setLogedIn(true)
      // }else{
      //   toast.error("oops login failed !");
      //   setLogedIn(false)
      // }
    );
  };

  const handleClose = () => {
    setOpenModal(false);
    window.location.reload(false);
  };

  const getUserInfo = (school) => {
    toast.success(`selected School : ${school.institution_name}`);
    toast.success("login successful !");
    setTimeout(() => {
      window.localStorage.setItem("cmn_school_id", school.institution_id);
      window.localStorage.setItem("schoolName", school.institution_name);
      window.localStorage.setItem("schoolLocation", school.place);
      setLogedIn(true);
      // setOpenModal(false);
    }, 3000);
  };

  function showPassword() {
    var temp = document.getElementById("password");
    if (temp.type === "password") {
      temp.type = "text";
    } else {
      temp.type = "password";
    }
  }

  const changedBoard = async (e) => {
    setLoading(true);
    try {
      const getTableData = await (
        await Axios.get(
          `${BASE_URL}/login/${window.localStorage.getItem(
            "cmn_user_id"
          )}/institutions?search_text=${e.target.value}`,
          {
            headers: {
              Authorization: "Bearer " + window.localStorage.getItem("token"),
            },
          }
        )
      ).data;
      setUserSchools(getTableData.data);
      setLoading(false);
    } catch (err) {
      console.log(err.response, "error response");
      setLoading(false);
    }
  };

  if (LogedIn) {
    return <Redirect from="/" to="/admin" />;
  }
  const forget = () => {
    return <Redirect to="/forgot-password" />;
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading_animation}>
          <ReactLoading
            type="bars"
            color="#FC8802"
            height="10%"
            width="10%"
            className={styles.reactLoadingBars}
          />
        </div>
      ) : null}
      <Box className={styles.header}>
        <div className={styles.header_body}>
          <img
            src={require("../../assets/images/read-analytics-logo.png")}
            alt="header-logo"
          />
        </div>
      </Box>
      <Divider
        style={{ background: "#A6C8A6", height: "1px", width: "100%" }}
      />
      <main className={styles.body}>
        <div className={styles.left}>
          <Box component="div" className={styles.reset_box}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
              className={styles.formik}
            >
              {(formik) => {
                return (
                  <div className={styles.form_div}>
                    <Form className={styles.form}>
                      <Box className={styles.username} component="div">
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Username"
                          className={styles.username_field}
                        />
                      </Box>
                      <ErrorMessage name="email" component={TextError}>
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                      <Box className={styles.password} component="div">
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Password"
                          className={styles.password_field}
                        />
                        <Box
                          component="span"
                          className={styles.show_password}
                          onClick={() => showPassword()}
                        >
                          show password
                        </Box>
                      </Box>
                      <ErrorMessage name="password" component={TextError}>
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                      <button
                        type="submit"
                        className={styles.Button}
                        disabled={!formik.isValid || formik.isSubmitting}
                      >
                        <div>Sign in</div>
                        <div style={{ display: "flex" }}>
                          <img
                            src={require("../../assets/images/signIn-icon.png")}
                            alt="header-logo"
                          />
                        </div>
                      </button>
                      <Box component="div" className={styles.forget}>
                        <Box className={styles.sign}>
                          <input type="checkbox" name="check" id="check" />
                          <span className={styles.keep}>Keep me signed in</span>
                        </Box>
                        <Box
                          className={styles.forget_password}
                          onClick={() => forget()}
                        >
                          <Link to="/forgot-password">Forgot password ?</Link>
                        </Box>
                      </Box>
                      <Box className={styles.knowMore} component="div">
                        <Box component="div" className={styles.not_register}>
                          Not Registred Yet?
                        </Box>
                        <Box component="div" className={styles.know_more}>
                          Know More
                          <Icon className={styles.know_more_icon}>
                            <ArrowForwardIosIcon style={{ height: "15px" }} />
                          </Icon>
                        </Box>
                      </Box>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </div>
        <div className={styles.right}>
          <img
            src={require("../../assets/images/login-right-logo.png")}
            alt="login-right-logo"
          />
        </div>
      </main>
      <footer className={styles.footer}>
        &copy; {new Date().getFullYear()} Apps'n'Devices Technologies Pvt Ltd.
        All rights reserved.
      </footer>
      {openModal ? (
        <DialogBox
          open={openModal}
          close={handleClose}
          style={{ overflow: "hidden", width: "100%", height: "100%" }}
        >
          <Box className={styles.dailog_box}>
            <Box
              component="div"
              className={styles.dailogTop}
              p={5}
              style={{ padding: "0px" }}
            >
              <div className={styles.userInfo}>User School List</div>
              <Box component="div" className={styles.search_box}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <img
                      src={require("../../assets/images/search-icon.png")}
                      alt="search"
                    />
                  </div>
                  <SearchComponent
                    id="searchBox"
                    name="searchBox"
                    onChange={(e) => {
                      changedBoard(e);
                    }}
                    classess={classes}
                  />
                </div>
              </Box>
              <div className={styles.close} onClick={handleClose}>
                <img
                  src={require("../../assets/images/close-sign.png")}
                  alt="close"
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                    border: "none",
                    outline: "none",
                  }}
                />
              </div>
            </Box>
            <Box
              component="div"
              p={5}
              className={styles.dailog_content}
              style={{ paddingTop: "20px" }}
            >
              <div className={styles.schoolList_Table}>
                <div className={styles.schoolList_Table_head}>
                  {schoolhead.map((elm, key) => (
                    <div
                      key={key}
                      className={styles.schoolList_Table_head_data}
                    >
                      {elm}
                    </div>
                  ))}
                </div>
                <div className={styles.schoolList_Table_body}>
                  {userSchools.map((elm, key) => (
                    <div
                      key={key}
                      onClick={() => getUserInfo(elm)}
                      className={styles.schoolList_Table_body_row}
                    >
                      <div className={styles.schoolList_Table_body_row_td2}>
                        {elm.institution_code}
                      </div>
                      <div className={styles.schoolList_Table_body_row_td3}>
                        {elm.institution_name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Box>
          </Box>
        </DialogBox>
      ) : null}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        progressClassName={styles.toastProgress}
      />
    </div>
  );
};

export default Login;
