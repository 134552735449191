import { CUBEJS_API } from "../../config/Api";
import cubejs from "@cubejs-client/core";
import { toast } from "react-toastify";

function cubeFunction() {
    var cubeJsToken = window.localStorage.getItem("cmn_cubeJs_token");
    var cubejsAPIFunction = cubejs(cubeJsToken, {
        apiUrl: CUBEJS_API,
    });
    return cubejsAPIFunction;
}

function handleError(error) {
    console.log(error);
    if (error.response.error) {
        console.log(error.response.error);
        toast.error(error.response.error);
    }
    return error;
}

// Hand Raise details for Given Study Circle (RA-HANDR-01)
export async function fetchPerformanceHandriseData(studyCircleId) {
    const cubejsApi = cubeFunction();
    const qData = {
        order: {
            "HandRaise.count": "desc"
        },
        measures: [
            "HandRaise.count"
        ],
        timeDimensions: [
            {
                "dimension": "HandRaise.assessmentDate"
            }
        ],
        filters: [
            {
                "member": "HandRaise.studyCircleId",
                "operator": "equals",
                "values": [
                    studyCircleId
                ]
            }
        ],
        dimensions: [
            "GroupSession.code",
            "HandRaise.assessmentId",
            "HandRaise.assessmentDate",
            "Topic.name"
        ]
    }
    try {
        const result = await cubejsApi.load(qData);
        // console.log("RA-HANDR-01", result);
        return result?.loadResponses[0]?.data;
    } catch (error) {
        console.log("error RA-HANDR-01", error);
        handleError(error);
    }
}

// Hand Raise details for Student (RA-HANDR-02)
export async function fetchStudentHandriseData(student_id) {
    const cubejsApi = cubeFunction();
    const qStudentData = {
        measures: [
            "HandRaise.count"
        ],
        timeDimensions: [
            {
                "dimension": "HandRaise.assessmentDate"
            }
        ],
        order: {
            "HandRaise.count": "desc"
        },
        dimensions: [
            "GroupSession.code",
            "HandRaise.assessmentId",
            "HandRaise.assessmentDate",
            "Topic.name"
        ],
        filters: [
            {
                "member": "HandRaise.studentId",
                "operator": "equals",
                "values": [
                    student_id
                ]
            }
        ]
    }
    try {
        const result = await cubejsApi.load(qStudentData);
        // console.log("RA-HANDR-02", result);
        return result?.loadResponses[0]?.data;
    } catch (error) {
        console.log("error RA-HANDR-02", error);
        handleError(error);
    }
}

// Session wise Hand Raise Summary (RA-HANDR-03)
export async function sessionwiseHandriseSummary(studyCircleId) {
    const cubejsApi = cubeFunction();
    const qGrpSpeed = {
        "measures": [
            "HandRaise.count",
            "HandRaise.uniqueStudentcount",
            "HandRaise.avgHandRaisePerMin"
        ],
        "timeDimensions": [
            {
                "dimension": "HandRaise.assessmentDate"
            }
        ],
        "order": {
            "HandRaise.assessmentDate": "asc"
        },
        "filters": [
            {
                "member": "HandRaise.studyCircleId",
                "operator": "equals",
                "values": [
                    studyCircleId
                ]
            }
        ]
    };
    try {
        // const result = await cubejsApi.load(qGrpSpeed);
        // console.log("result", result)
        // return result?.loadResponses[0]?.data;
        const startTime = Date.now()
        const result = await cubejsApi.load(qGrpSpeed);
        const endTime = Date.now()
        const timeTaken = endTime - startTime
        console.log("Time taken for sessionwiseHandriseSummary", timeTaken)
        return result;
    } catch (error) {
        console.log(error);
        if (error?.response?.error) {
            console.log(error.response.error);
            toast.error(error.response.error);
        }
        return error;
    }
}
