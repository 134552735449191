export default function sortFunction(
  data,
  setData,
  subEntry,
  condition,
  number
) {
  if (condition) {
    if (number) {
      setData([...data.sort((a, b) => (a[subEntry] > b[subEntry] ? 1 : -1))]);
    } else {
      setData([
        ...data.sort((a, b) =>
          a[subEntry].toUpperCase() > b[subEntry].toUpperCase() ? 1 : -1
        ),
      ]);
    }
  } else {
    if (number) {
      setData(
        [...data.sort((a, b) => (a[subEntry] > b[subEntry] ? 1 : -1))].reverse()
      );
    } else {
      setData(
        [
          ...data.sort((a, b) =>
            a[subEntry].toUpperCase() > b[subEntry].toUpperCase() ? 1 : -1
          ),
        ].reverse()
      );
    }
  }
}
