import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/index";
import { Menu, ChevronRight } from "@material-ui/icons";
import styles from "./NavBarStyle";
// import search_icon from '../../assets/images/search-icon.png'
import emblem0 from "../../assets/images/emblem0.jpeg";
const useStyles = makeStyles(styles);

const NavBar = (props) => {
  const classes = useStyles();

  const instImage =
    props.dashboardheader && props.dashboardheader !== "null"
      ? props.dashboardheader
      : emblem0;
  console.log("instImage", instImage);

  //passing the pathname to the content
  function makeBrand() {
    var name;
    props.routes.map((prop) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = prop.name === "Assessments" ? "Session" : prop.name;
      }
      return null;
    });
    return name;
  }
  return (
    <AppBar className={classes.appBar} elevation={0} position="fixed">
      <Toolbar className={classes.container}>
        <div className={classes.logoImg}>
          <span className={classes.logo}>
            <img src={props.Logo} alt="logo img" className={classes.img} />
          </span>
          <span className={classes.text}>{props.logoText}</span>
        </div>
        <Typography variant="h6" className={classes.title}>
          {makeBrand() === "Dashboard" ? false : makeBrand()}
        </Typography>
        <IconButton color="inherit">
          {makeBrand() === "Dashboard" ? false : <ChevronRight />}
        </IconButton>
        {makeBrand() === "Students" && (
          <div className={classes.title} style={{ marginLeft: "-30px" }}>
            {props.displayText}
          </div>
        )}
        <div className={classes.flex}>
          {makeBrand() === "Dashboard" ? (
            <div className={classes.centerText}>{props.reportNumber}</div>
          ) : (
            false
          )}
          <div className={classes.dashboardheaderText}>
            <div className={classes.toptext}>
              {props.dashboardheaderTextTop}
            </div>
            <div className={classes.toptext}>
              {props.dashboardheaderTextBottom}
            </div>
          </div>
        </div>
        <img
          className={classes.dashboardheaderImg}
          src={instImage}
          alt="dashboard header"
        />
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
NavBar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
export default NavBar;
